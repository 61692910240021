import { SET_COUNTRIES, UPDATE_FOREX_RATES } from './actionTypes';
import authActionTypes from '../auth/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
  countries: null,
  forexRates: null
};

const setCountries = (state, countries) => updateObject(state, { countries });
const clearCountriesState = (state) => updateObject(state, initialState);

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_COUNTRIES:
      return setCountries(state, action.payload);
    case authActionTypes.CLEAR_STATE:
      return clearCountriesState(state);
    case UPDATE_FOREX_RATES:
      return { ... state, forexRates: action.payload}  
    default:
      return state;
  }
};

export default reducer;
