const theme = {
    palette: {
        primary: {
            blue: "#1160F7",
            green: "#2EBC31",
            navy: "#31517A"
        },
        secondary: {
            darkBlue: "#164B9F",
            accentBlue: "#D8E7FF",
            linkBlock: "#F4F8FF"
        },
        background: {
            white: "#FFFFFF",
            lightGray: "#F8F8F8",
            gray: "#F1F1F1",
            darkGray: "#9C9DA4",
            lightBlue: "#F0F6FF"
        },
        border: {
            light: "#E8E8E8",
            regular: "#CACCD5",
            dark: "#9799A1",
            blue: "#D8E7FF"
        },
        input: {
            heading: "#31517A",
            body: "#626680",
            placeholder: "#9D9C9C",
            disabled: "#C4C4C4"
        },
        success: {
            muted: "#EEFFEB",
            light: "#CAFFC1",
            regular: "#2EBC31",
            dark : "#008000"
        },
        warning: {
            muted: "#FFFAEB",
            light: "#FEEEBF",
            regular: "#FAC948",
            dark : "#CC8A11"
        },
        error: {
            muted: "#FCEDED",
            light: "#FFDCDA",
            regular: "#FF4B55",
            dark : "#AD1206"
        }
    },
    spacing: (size: number) => {
        return `${size * 4}px`; // 4px based
    },
    breakpoints: {
        sm: 576,
        md: 768,
        lg: 959,
        xl: 1200
    }
}

export default theme;