import { bool, number, string } from 'prop-types';
import React from 'react';

const SuccessAlertIcon = ({
  width,
  height,
  padding,
  opacity,
}) => {

  return (
    <svg xmlns="https://www.w3.org/2000/svg" width={width} height={height} style={{ margin: '0 10px 0 0' }} padding={padding} opacity={opacity} viewBox="0 0 22.587 22.587">
      <g id="Group_3286" data-name="Group 3286" transform="translate(-126 -123)">
        <circle id="Ellipse_364" data-name="Ellipse 364" cx="11.294" cy="11.294" r="11.294" transform="translate(126 123)" fill="rgba(75,197,78,0.42)" />
        <circle id="Ellipse_339" data-name="Ellipse 339" cx="8.092" cy="8.092" r="8.092" transform="translate(129.202 126.202)" fill="#2EBC31" />
        <g id="verify-sign_1_" data-name="verify-sign (1)" transform="translate(132.651 130.922)">
          <g id="tick" transform="translate(0 0)">
            <g id="Group_278" data-name="Group 278">
              <path id="Path_950" data-name="Path 950" d="M4.926,71a.905.905,0,0,0-1.28,0L-.47,75.37l-2.2-1.955a.893.893,0,0,0-1.263,0,.909.909,0,0,0-.107,1.134.757.757,0,0,0,.107.129l2.9,2.562a.893.893,0,0,0,1.263,0,.9.9,0,0,0,.092-.113l.016-.013,4.581-4.831A.905.905,0,0,0,4.926,71Z" transform="translate(4.187 -70.738)" fill="#fff" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

SuccessAlertIcon.propTypes = {
  width: number,
  height: number,
  warning: bool,
  padding: string,
  opacity: string,
};

SuccessAlertIcon.defaultProps = {
  width: 22,
  height: 22,
  padding: '0',
  opacity: '1',
};

export default SuccessAlertIcon;