/**
 * User actions definition
 */
export const ACTIONS = {
    PAGE_VIEW: 'PAGE_VIEW',
    IDENTIFY: 'IDENTIFY',
    LOGIN: 'LOGIN',
    LOGOUT: 'LOGOUT',

    SIGNUP_INITIATED: 'SIGNUP_INITIATED',
    SIGNUP_COMPLETED: 'SIGNUP_COMPLETED',
    SIGNUP_PHONE_NUMBER_SUBMITTED: 'SIGNUP_PHONE_NUMBER_SUBMITTED',
    SIGNUP_REFERRAL_CODE: 'SIGNUP_REFERRAL_CODE',
    SIGNUP_ACCOUNT_TYPE_SELECTED: 'SIGNUP_ACCOUNT_TYPE_SELECTED',
    SIGNUP_PREFERRED_SERVICE_SELECTED: 'SIGNUP_PREFERRED_SERVICE_SELECTED',
    SIGNUP_INT_BILL_TYPE_SELECTED: 'SIGNUP_INT_BILL_TYPE_SELECTED',
    FORGOT_PASSWORD: 'FORGOT_PASSWORD',

    ESCALATION_CREATE: 'ESCALATION_CREATE',
    DOWNLOAD_RECEIPT: 'DOWNLOAD_RECEIPT',
    DOWNLOAD_HISTORY: 'DOWNLOAD_HISTORY',

    // send money
    SEND_MONEY_INITIATED: 'SEND_MONEY_INITIATED',
    SEND_MONEY_COUNTRY_SELECTED: 'SEND_MONEY_COUNTRY_SELECTED',
    SEND_MONEY_AMOUNT_CONFIRMED: 'SEND_MONEY_AMOUNT_CONFIRMED',
    SEND_MONEY_RECIPIENT_SELECTED: 'SEND_MONEY_RECIPIENT_SELECTED',
    SEND_MONEY_RECEIVING_METHOD_CONFIRMED: 'SEND_MONEY_RECEIVING_METHOD_CONFIRMED',
    SEND_MONEY_RECIPIENT_DETAILS_CONFIRMED: 'SEND_MONEY_RECIPIENT_DETAILS_CONFIRMED',
    
    // send money level 1
    SEND_MONEY_PERSONA_INITIATED: 'SEND_MONEY_PERSONA_INITIATED',
    SEND_MONEY_PERSONA_COMPLETED: 'SEND_MONEY_PERSONA_COMPLETED',
    SEND_MONEY_PERSONA_CANCELLED: 'SEND_MONEY_PERSONA_CANCELLED',
    SEND_MONEY_PERSONA_ERROR: 'SEND_MONEY_PERSONA_ERROR',
    SEND_MONEY_CUSTOMER_DETAILS_CONFIRMED: 'SEND_MONEY_CUSTOMER_DETAILS_CONFIRMED',
    SEND_MONEY_CUSTOMER_ADDRESS_CONFIRMED: 'SEND_MONEY_CUSTOMER_ADDRESS_CONFIRMED',
    SEND_MONEY_CUSTOMER_OCCUPATION_CONFIRMED: 'SEND_MONEY_CUSTOMER_OCCUPATION_CONFIRMED',
    SEND_MONEY_VERIFICATION_COMPLETED: 'SEND_MONEY_VERIFICATION_COMPLETED',

    SEND_MONEY_PURPOSE_CONFIRMED: 'SEND_MONEY_PURPOSE_CONFIRMED',
    SEND_MONEY_PREFERRED_BANK_SELECTED: 'SEND_MONEY_PREFERRED_BANK_SELECTED',
    SEND_MONEY_PAYMENT_METHOD_SELECTED: 'SEND_MONEY_PAYMENT_METHOD_SELECTED',
    SEND_MONEY_PAYMENT_DETAILS_CONFIRMED: 'SEND_MONEY_PAYMENT_DETAILS_CONFIRMED',
    SEND_MONEY_TRANSFER_OVERVIEW_CONFIRMED: 'SEND_MONEY_TRANSFER_OVERVIEW_CONFIRMED',
    SEND_MONEY_REWARDS_APPLIED: 'SEND_MONEY_REWARDS_APPLIED',
    SEND_MONEY_PROMOCODE_APPLIED: 'SEND_MONEY_PROMOCODE_APPLIED',
    SEND_MONEY_TRANSACTION_COMPLETED: 'SEND_MONEY_TRANSACTION_COMPLETED',

    // rewards
    PROMO_CODE_APPLIED: 'PROMO_CODE_APPLIED', 

    // wallet deposit || remove all and replace with balance
    WALLET_DEPOSIT_INITIATED: 'WALLET_DEPOSIT_INITIATED',
    WALLET_DEPOSIT_AMOUNT_CONFIRMED: 'WALLET_DEPOSIT_AMOUNT_CONFIRMED',
    WALLET_DEPOSIT_METHOD_SELECTED: 'WALLET_DEPOSIT_METHOD_SELECTED',
    WALLET_DEPOSIT_PAYMENT_DETAILS_CONFIRMED: 'WALLET_DEPOSIT_PAYMENT_DETAILS_CONFIRMED',
    WALLET_DEPOSIT_OVERVIEW_CONFIRMED: 'WALLET_DEPOSIT_OVERVIEW_CONFIRMED',

    // balance deposit
    BALANCE_DEPOSIT_INITIATED: 'BALANCE_DEPOSIT_INITIATED',
    BALANCE_DEPOSIT_AMOUNT_CONFIRMED: 'BALANCE_DEPOSIT_AMOUNT_CONFIRMED',
    BALANCE_DEPOSIT_PAYMENT_METHOD_SELECTED: 'BALANCE_DEPOSIT_PAYMENT_METHOD_SELECTED',
    BALANCE_DEPOSIT_PAYMENT_DETAILS_CONFIRMED: 'BALANCE_DEPOSIT_PAYMENT_DETAILS_CONFIRMED',
    BALANCE_DEPOSIT_OVERVIEW_CONFIRMED: 'BALANCE_DEPOSIT_OVERVIEW_CONFIRMED',
    BALANCE_DEPOSIT_COMPLETED: 'BALANCE_DEPOSIT_COMPLETE',

    // balance withdrawal
    BALANCE_WITHDRAWAL_INITIATED: 'BALANCE_WITHDRAWAL_INITIATED',
    BALANCE_WITHDRAWAL_AMOUNT_CONFIRMED: 'BALANCE_WITHDRAWAL_AMOUNT_CONFIRMED',
    BALANCE_WITHDRAWAL_METHOD_SELECTED: 'BALANCE_WITHDRAWAL_METHOD_SELECTED',
    BALANCE_WITHDRAWAL_DETAILS_CONFIRMED: 'BALANCE_WITHDRAWAL_DETAILS_CONFIRMED',
    BALANCE_WITHDRAWAL_OVERVIEW_CONFIRMED: 'BALANCE_WITHDRAWAL_OVERVIEW_CONFIRMED',
    BALANCE_WITHDRAWAL_COMPLETED: 'BALANCE_WITHDRAWAL_COMPLETED',

    // add new recipient
    ADD_NEW_RECIPIENT_STARTED: 'ADD_NEW_RECIPIENT_STARTED',
    ADD_NEW_RECIPIENT_COUNTRY_SELECTED: 'ADD_NEW_RECIPIENT_COUNTRY_SELECTED',
    ADD_NEW_RECIPIENT_RECEIVING_METHOD_SELECTED: 'ADD_NEW_RECIPIENT_RECEIVING_METHOD_SELECTED',
    ADD_NEW_RECIPIENT_PERSONAL_DETAILS_ENTERED: 'ADD_NEW_RECIPIENT_PERSONAL_DETAILS_ENTERED',
    ADD_NEW_RECIPIENT_COUNTRY_RECEIVING_METHOD_SELECTED: 'ADD_NEW_RECIPIENT_COUNTRY_RECEIVING_METHOD_SELECTED',
    ADD_NEW_RECIPIENT_CREATED: 'ADD_NEW_RECIPIENT_CREATED',

    // Level 1 verification
    LEVEL1_VERIFICATION_STARTED: 'LEVEL1_VERIFICATION_STARTED',
    LEVEL1_PERSONA_INITIATED: 'LEVEL1_PERSONA_INITIATED',
    LEVEL1_PERSONA_COMPLETED: 'LEVEL1_PERSONA_COMPLETED',
    LEVEL1_PERSONA_CANCELLED: 'LEVEL1_PERSONA_CANCELLED',
    LEVEL1_PERSONA_ERROR: 'LEVEL1_PERSONA_ERROR',
    LEVEL1_VERIFICATION_CUSTOMER_DETAILS_CONFIRMED: 'LEVEL1_VERIFICATION_CUSTOMER_DETAILS_CONFIRMED',
    LEVEL1_VERIFICATION_CUSTOMER_ADDRESS_CONFIRMED: 'LEVEL1_VERIFICATION_CUSTOMER_ADDRESS_CONFIRMED',
    LEVEL1_VERIFICATION_CUSTOMER_OCCUPATION_CONFIRMED: 'LEVEL1_VERIFICATION_CUSTOMER_OCCUPATION_CONFIRMED',
    LEVEL1_VERIFICATION_COMPLETED: 'LEVEL1_VERIFICATION_COMPLETED',

    // Level 2 verification
    LEVEL2_VERIFICATION_STARTED: 'LEVEL2_VERIFICATION_STARTED',
    LEVEL2_VERIFICATION_DOCUMENT_SELECTED: 'LEVEL2_VERIFICATION_DOCUMENT_SELECTED',
    LEVEL2_VERIFICATION_DOCUMENT_UPLOADED: 'LEVEL2_VERIFICATION_DOCUMENT_UPLOADED',
    LEVEL2_DOCUMENT_UPLOADED: 'LEVEL2_DOCUMENT_UPLOADED',
    LEVEL2_DOCUMENT_SUBMITTED: 'LEVEL2_DOCUMENT_SUBMITTED',
    LEVEL2_VERIFICATION_COMPLETED: 'LEVEL2_VERIFICATION_COMPLETED',

    // level 3 verification
    LEVEL3_VERIFICATION_INITIATED: 'LEVEL3_VERIFICATION_INITIATED',
    LEVEL3_VERIFICATION_VIDEO_STARTED: 'LEVEL3_VERIFICATION_VIDEO_STARTED',
    LEVEL3_VERIFICATION_VIDEO_RECORDED: 'LEVEL3_VERIFICATION_VIDEO_RECORDED',
    LEVEL3_VERIFICATION_VIDEO_UPLOADED: 'LEVEL3_VERIFICATION_VIDEO_UPLOADED',
    LEVEL3_VERIFICATION_VIDEO_SUBMITTED: 'LEVEL3_VERIFICATION_VIDEO_SUBMITTED',

    REFERRAL_LINK_SHARED: 'REFERRAL_LINK_SHARED',
    REFERAL_EMAIL_INVITE_SENT: 'REFERAL_EMAIL_INVITE_SENT',
    REFERAL_GMAIL_CONTACT_IMPORTED: 'REFERAL_GMAIL_CONTACT_IMPORTED',
    EXCHANGE_RATE_ALERT_ACTIVATED: 'EXCHANGE_RATE_ALERT_ACTIVATED',

    EDIT_RECIPIENT: 'EDIT_RECIPIENT',
    DELETE_RECIPIENT: 'DELETE_RECIPIENT',
    ACCOUNT_DOB_CHANGED: 'ACCOUNT_DOB_CHANGED',
    ACCOUNT_ADDRESS_CHANGED: 'ACCOUNT_ADDRESS_CHANGED',
    
    LANGUAGE_CHANGED: 'BROWSER_LANGUAGE_CHANGED',

    CURRENCY_EXCHANGE_INITIATED: 'CURRENCY_EXCHANGE_INITIATED',
    CURRENCY_EXCHANGE_AMOUNT_CONFIRMED: 'CURRENCY_EXCHANGE_AMOUNT_CONFIRMED',
    CURRENCY_EXCHANGE_PURPOSE_CONFIRMED: 'CURRENCY_EXCHANGE_PURPOSE_CONFIRMED',
    CURRENCY_EXCHANGE_SELECT_ACCOUNT_CONNECTION_TYPE: 'CURRENCY_EXCHANGE_SELECT_ACCOUNT_CONNECTION_TYPE',
    CURRENCY_EXCHANGE_MANUAL_PAYMENT_ACCOUNT_CONNECTION: 'CURRENCY_EXCHANGE_MANUAL_PAYMENT_ACCOUNT_CONNECTION',
    CURRENCY_EXCHANGE_MANUAL_DEPOSIT_ACCOUNT_CONNECTION: 'CURRENCY_EXCHANGE_MANUAL_DEPOSIT_ACCOUNT_CONNECTION',
    CURRENCY_EXCHANGE_SELECT_PREFERRED_BANK: 'CURRENCY_EXCHANGE_SELECT_PREFERRED_BANK',

    CURRENCY_EXCHANGE_PERSONA_INITIATED: 'CURRENCY_EXCHANGE_PERSONA_INITIATED',
    CURRENCY_EXCHANGE_PERSONA_COMPLETED: 'CURRENCY_EXCHANGE_PERSONA_COMPLETED',
    CURRENCY_EXCHANGE_PERSONA_CANCELLED: 'CURRENCY_EXCHANGE_PERSONA_CANCELLED',
    CURRENCY_EXCHANGE_PERSONA_ERROR: 'CURRENCY_EXCHANGE_PERSONA_ERROR',
    CURRENCY_EXCHANGE_CUSTOMER_DETAILS_CONFIRMED: 'CURRENCY_EXCHANGE_CUSTOMER_DETAILS_CONFIRMED',
    CURRENCY_EXCHANGE_CUSTOMER_ADDRESS_CONFIRMED: 'CURRENCY_EXCHANGE_CUSTOMER_ADDRESS_CONFIRMED',
    CURRENCY_EXCHANGE_CUSTOMER_OCCUPATION_CONFIRMED: 'CURRENCY_EXCHANGE_CUSTOMER_OCCUPATION_CONFIRMED',
    CURRENCY_EXCHANGE_VERIFICATION_COMPLETED: 'CURRENCY_EXCHANGE_VERIFICATION_COMPLETED',

    CURRENCY_EXCHANGE_PAYMENT_BANK_ACCOUNT_SELECTED: 'CURRENCY_EXCHANGE_PAYMENT_BANK_ACCOUNT_SELECTED',
    CURRENCY_EXCHANGE_DEPOSIT_BANK_ACCOUNT_SELECTED: 'CURRENCY_EXCHANGE_DEPOSIT_BANK_ACCOUNT_SELECTED',

    CURRENCY_EXCHANGE_OVERVIEW_CONFIRMED: 'CURRENCY_EXCHANGE_OVERVIEW_CONFIRMED',
    CURRENCY_EXCHANGE_REWARDS_APPLIED: 'CURRENCY_EXCHANGE_REWARDS_APPLIED',
    CURRENCY_EXCHANGE_PROMOCODE_APPLIED: 'CURRENCY_EXCHANGE_PROMOCODE_APPLIED',
    CURRENCY_EXCHANGE_TRANSFER_OVERVIEW_CONFIRMED: 'CURRENCY_EXCHANGE_TRANSFER_OVERVIEW_CONFIRMED',

    FLINKS_IFRAME_AUTHENTICATION_STARTED: 'FLINKS_IFRAME_AUTHENTICATION_STARTED',
    FLINKS_IFRAME_AUTHENTICATION_ENDED: 'FLINKS_IFRAME_AUTHENTICATION_ENDED',
    FLINKS_API_INTEGRATION_SUCCESS: 'FLINKS_API_INTEGRATION_SUCCESS',
    FLINKS_API_INTEGRATION_FAILED: 'FLINKS_API_INTEGRATION_FAILED',
    FLINKS_API_INTEGRATION_RETRY: 'FLINKS_API_INTEGRATION_RETRY',
    FLINKS_API_MANUAL: 'FLINKS_API_MANUAL',

    OPEN_PERSONAL_ACCOUNT: 'OPEN_PERSONAL_ACCOUNT',
    OPEN_BUSINESS_ACCOUNT: 'OPEN_BUSINESS_ACCOUNT',
    SWITCH_TO_BUSINESS_ACCOUNT: 'SWITCH_TO_BUSINESS_ACCOUNT',
    SWITCH_TO_PERSONAL_ACCOUNT: 'SWITCH_TO_PERSONAL_ACCOUNT',

    MOBILE_TOP_UP_INITIATED: 'MOBILE_TOP_UP_INITIATED',
    MOBILE_TOP_UP_COUNTRY_SELECTED: 'MOBILE_TOP_UP_COUNTRY_SELECTED',
    MOBILE_TOP_UP_PHONE_NUMBER_SUBMITTED: 'MOBILE_TOP_UP_PHONE_NUMBER_SUBMITTED',
    MOBILE_TOP_UP_CARRIER_CHANGED: 'MOBILE_TOP_UP_CARRIER_CHANGED',
    MOBILE_TOP_UP_VALUE_SELECTED: 'MOBILE_TOP_UP_VALUE_SELECTED',
    MOBILE_TOP_UP_EXTRA_INFORMATION_SUBMITTED: 'MOBILE_TOP_UP_EXTRA_INFORMATION_SUBMITTED',
    MOBILE_TOP_UP_PAYMENT_SELECTED: 'MOBILE_TOP_UP_PAYMENT_SELECTED',
    MOBILE_TOP_UP_PAYMENT_DETAILS_ENTERED: 'MOBILE_TOP_UP_PAYMENT_DETAILS_ENTERED',
    MOBILE_TOP_UP_OVERVIEW_CONFIRMED: 'MOBILE_TOP_UP_OVERVIEW_CONFIRMED',
    MOBILE_TOP_UP_TRANSACTION_COMPLETED: 'MOBILE_TOP_UP_TRANSACTION_COMPLETED',

    GIFT_CARD_INITIATED: 'GIFT_CARD_INITIATED',
    GIFT_CARD_COUNTRY_SELECTED: 'GIFT_CARD_COUNTRY_SELECTED',
    GIFT_CARD_PROVIDER_SELECTED: 'GIFT_CARD_PROVIDER_SELECTED',
    GIFT_CARD_VALUE_SELECTED: 'GIFT_CARD_VALUE_SELECTED',
    GIFT_CARD_PAYMENT_SELECTED: 'GIFT_CARD_PAYMENT_SELECTED',
    GIFT_CARD_PAYMENT_DETAILS_ENTERED: 'GIFT_CARD_PAYMENT_DETAILS_ENTERED',
    GIFT_CARD_EXTRA_INFORMATION_SUBMITTED: 'GIFT_CARD_EXTRA_INFORMATION_SUBMITTED',
    GIFT_CARD_OVERVIEW_CONFIRMED: 'GIFT_CARD_OVERVIEW_CONFIRMED',
    GIFT_CARD_TRANSACTION_COMPLETED: 'GIFT_CARD_TRANSACTION_COMPLETED',

    BILL_PAYMENT_INITIATED: 'BILL_PAYMENT_INITIATED',
    BILL_PAYMENT_COUNTRY_SELECTED: 'BILL_PAYMENT_COUNTRY_SELECTED',
    BILL_PAYMENT_BILL_TYPE_SELECTED: 'BILL_PAYMENT_BILL_TYPE_SELECTED',
    BILL_PAYMENT_PROVIDER_AND_ACCOUNT_NUMBER_SELECTED: 'BILL_PAYMENT_PROVIDER_AND_ACCOUNT_NUMBER_SELECTED',
    BILL_PAYMENT_VALUE_SELECTED: 'BILL_PAYMENT_VALUE_SELECTED',
    BILL_PAYMENT_EXTRA_INFORMATION_SUBMITTED: 'BILL_PAYMENT_EXTRA_INFORMATION_SUBMITTED',
    BILL_PAYMENT_PAYMENT_SELECTED: 'BILL_PAYMENT_PAYMENT_SELECTED',
    BILL_PAYMENT_PAYMENT_DETAILS_ENTERED: 'BILL_PAYMENT_PAYMENT_DETAILS_ENTERED',
    BILL_PAYMENT_OVERVIEW_CONFIRMED: 'BILL_PAYMENT_OVERVIEW_CONFIRMED',
    BILL_PAYMENT_TRANSACTION_COMPLETED: 'BILL_PAYMENT_TRANSACTION_COMPLETED',


    // Business Verification Trackers
    // Note: Since we add 'BA_' prefix to business trackers, we dont have it here
    // On Mixpanel it will appear as 'BA_VERIFICATION_STARTED' instead of 'VERIFICATION_STARTED'

    VERIFICATION_STARTED: 'VERIFICATION_STARTED',
    VERIFICATION_OWNER_CONFIRMED: 'VERIFICATION_OWNER_CONFIRMED',
    VERIFICATION_NON_MSB_CONFIRMED: 'VERIFICATION_NON_MSB_CONFIRMED',
    VERIFICATION_CAD_COMPANY_CONFIRMED: 'VERIFICATION_CAD_COMPANY_CONFIRMED',
    VERIFICATION_BUSINESS_INFORMATION_SUBMITTED: 'VERIFICATION_BUSINESS_INFORMATION_SUBMITTED',
    VERIFICATION_BUSINESS_ADDRESS_INFORMATION_SUBMITTED: 'VERIFICATION_BUSINESS_ADDRESS_INFORMATION_SUBMITTED',
    VERIFICATION_BUSINESS_ADDRESS_DOCUMENT_SELECTED: 'VERIFICATION_BUSINESS_ADDRESS_DOCUMENT_SELECTED',
    VERIFICATION_BUSINESS_ADDRESS_DOCUMENT_UPLOADED: 'VERIFICATION_BUSINESS_ADDRESS_DOCUMENT_UPLOADED',
    VERIFICATION_BUSINESS_ADDRESS_DOCUMENT_SUBMITTED: 'VERIFICATION_BUSINESS_ADDRESS_DOCUMENT_SUBMITTED',
    VERIFICATION_BUSINESS_REGISTRATION_DOCUMENT_SELECTED: 'VERIFICATION_BUSINESS_ADDRESS_DOCUMENT_SELECTED',
    VERIFICATION_BUSINESS_REGISTRATION_DOCUMENT_UPLOADED: 'VERIFICATION_BUSINESS_REGISTRATION_DOCUMENT_UPLOADED',
    VERIFICATION_BUSINESS_REGISTRATION_DOCUMENT_SUBMITTED: 'VERIFICATION_BUSINESS_REGISTRATION_DOCUMENT_SUBMITTED',
    VERIFICATION_OWNERSHIP_SELECTED: 'VERIFICATION_OWNERSHIP_SELECTED',
    VERIFICATION_ADDITIONAL_OWNER_PERSONAL_INFORMATION_SUBMITTED: 'VERIFICATION_ADDITIONAL_OWNER_PERSONAL_INFORMATION_SUBMITTED',
    VERIFICATION_ADDITIONAL_OWNER_ADDRESS_INFORMATION_SUBMITTED: 'VERIFICATION_ADDITIONAL_OWNER_ADDRESS_INFORMATION_SUBMITTED',
    VERIFICATION_ADDITIONAL_OWNERS_ADDED: 'VERIFICATION_ADDITIONAL_OWNERS_ADDED',
    VERIFICATION_DIRECTORS_SELECTED: 'VERIFICATION_DIRECTORS_SELECTED',
    VERIFICATION_ADDITIONAL_DIRECTOR_PERSONAL_INFORMATION_SUBMITTED: 'VERIFICATION_ADDITIONAL_DIRECTOR_PERSONAL_INFORMATION_SUBMITTED',
    VERIFICATION_ADDITIONAL_DIRECTOR_ADDRESS_INFORMATION_SUBMITTED: 'VERIFICATION_ADDITIONAL_DIRECTOR_ADDRESS_INFORMATION_SUBMITTED',
    VERIFICATION_ADDITIONAL_DIRECTOR_ID_DOCUMENT_SELECTED: 'VERIFICATION_ADDITIONAL_DIRECTOR_ID_DOCUMENT_SELECTED',
    VERIFICATION_ADDITIONAL_DIRECTOR_ID_DOCUMENT_UPLOADED: 'VERIFICATION_ADDITIONAL_DIRECTOR_ID_DOCUMENT_UPLOADED',
    VERIFICATION_ADDITIONAL_DIRECTOR_ID_DOCUMENT_SUBMITTED: 'VERIFICATION_ADDITIONAL_DIRECTOR_ID_DOCUMENT_SUBMITTED',
    VERIFICATION_ADDITIONAL_DIRECTORS_ADDED: 'VERIFICATION_ADDITIONAL_DIRECTORS_ADDED',
    PERSONA_INITIATED: 'PERSONA_INITIATED',
    PERSONA_COMPLETED: 'PERSONA_COMPLETED',
    VERIFICATION_CUSTOMER_DETAILS_CONFIRMED: 'VERIFICATION_CUSTOMER_DETAILS_CONFIRMED',
    VERIFICATION_CUSTOMER_ADDRESS_CONFIRMED: 'VERIFICATION_CUSTOMER_ADDRESS_CONFIRMED',
    VERIFICATION_CUSTOMER_OCCUPATION_CONFIRMED : 'VERIFICATION_CUSTOMER_OCCUPATION_CONFIRMED ',
    VERIFICATION_COMPLETED: 'VERIFICATION_COMPLETED',

    ESIM_INITIATED: 'ESIM_INITIATED',
    ESIM_COMPATIBILITY_CONFIRMED: 'ESIM_COMPATIBILITY_CONFIRMED',
    ESIM_DESTINATION_SELECTED: 'ESIM_DESTINATION_SELECTED',
    ESIM_PLAN_SELECTED: 'ESIM_PLAN_SELECTED',
    ESIM_RECEIVING_EMAIL_SUBMITTED: 'ESIM_RECEIVING_EMAIL_SUBMITTED',
    ESIM_PAYMENT_METHOD_SELECTED: 'ESIM_PAYMENT_METHOD_SELECTED',
    ESIM_PAYMENT_DETAILS_ENTERED: 'ESIM_PAYMENT_DETAILS_ENTERED',
    ESIM_PAYMENT_OVERVIEW_CONFIRMED: 'ESIM_PAYMENT_OVERVIEW_CONFIRMED',
    ESIM_PAYMENT_TRANSACTION_COMPLETED: 'ESIM_PAYMENT_TRANSACTION_COMPLETED',
}