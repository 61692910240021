import transactionProcessingStatuses from '../../shared/const/transactionProcessingStatuses';
import {
    SET_WALLET_WITHDRAWAL_TRANSACTION_UNIQUE_ID,
    SET_WALLET_WITHDRAWAL_AMOUNT,
    SET_WALLET_WITHDRAWAL_PAYMENT_TYPE,
    SET_WALLET_WITHDRAWAL_EFT_INFO,
    SET_WALLET_WITHDRAWAL_TRANSACTION_PROCESSING_INFO,
    CLEAR_WALLET_WITHDRAWAL_TRAN_STATE,
    CLEAR_WALLET_WITHDRAWAL_PAYMENT_OPTION,
    CLEAR_WALLET_WITHDRAWAL_STATE,
    SET_WALLET_WITHDRAWAL_TRANSACTION_VERIFICATION_CODE
} from './actionTypes';

const initialState = {
    wallet_withdrawal_transaction_unique_id: '',
    wallet_withdrawal_amount: '',
    wallet_withdrawal_payment_type: '',
    wallet_withdrawal_transaction_processing_info: {
        status: transactionProcessingStatuses.NOT_INITIATED,
        success_dialog_open: false,
        failed_dialog_open: false,
        required_level_to_upgrade: null,
        payment_type: null,
    },
};

const walletWithdrawalTransaction = (state = initialState, action) => {
    switch (action.type) {
        case SET_WALLET_WITHDRAWAL_TRANSACTION_UNIQUE_ID:
            return { ...state, wallet_withdrawal_transaction_unique_id: action.payload };
        case SET_WALLET_WITHDRAWAL_AMOUNT:
            return { ...state, wallet_withdrawal_amount: action.payload };
        case SET_WALLET_WITHDRAWAL_PAYMENT_TYPE:
            return { ...state, wallet_withdrawal_payment_type: action.payload };
        case SET_WALLET_WITHDRAWAL_TRANSACTION_VERIFICATION_CODE:
            return { ...state, wallet_withdrawal_verification_code : action.payload }
        case SET_WALLET_WITHDRAWAL_EFT_INFO:
            return { ...state, wallet_withdrawal_eft: action.payload };
        case SET_WALLET_WITHDRAWAL_TRANSACTION_PROCESSING_INFO:
            return {
                ...state,
                wallet_withdrawal_transaction_processing_info: { ...state.wallet_withdrawal_transaction_processing_info, ...action.payload }
            };
        case CLEAR_WALLET_WITHDRAWAL_TRAN_STATE:
            return { ...initialState, wallet_withdrawal_transaction_processing_info: { ...state.wallet_withdrawal_transaction_processing_info}  };
        case CLEAR_WALLET_WITHDRAWAL_PAYMENT_OPTION:
            return { ...state, payment_type: '' };
        case CLEAR_WALLET_WITHDRAWAL_STATE:
            return initialState;
        default: return state
    }
}

export default walletWithdrawalTransaction;