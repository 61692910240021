import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import promise from 'redux-promise';
import persistState from 'redux-localstorage';
import reducers from './reducers';

let store = null;
const isClient = typeof window !== 'undefined';
const middlewares = [thunk, promise];
const statesToPersist = [
  'auth',
  'customer',
  'exchange',
  'transaction',
  'cancelTransaction',
  'recipient',
  'system',
  'flinks',
  'company',
  'business',
  'walletDepositTransaction',
  'walletWithdrawalTransaction',
  'questionnaire',
  'paymentStatus',
  'moneyTransfer',
  'mobileTopUp',
  'internationalBills',
  'giftCards',
  'dt_transaction',
  'escalation',
  'balance',
  'currencyExchange',
  'verification',
  'esim'
];

/** Redux tools extension for you to be able to see the state on the browser (Chrome).
 * It should be available only on the dev environment. */
let reduxDevToolsCompose =
  process.browser && window.__REDUX_DEVTOOLS_EXTENSION__ && process.env.REACT_APP_ENV === 'dev'
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : null;
const composeEnhancers = reduxDevToolsCompose || compose;

const makeStore = (initialState) => {
  const enhancers = [applyMiddleware(...middlewares)];
  if (isClient) enhancers.push(persistState(statesToPersist)); // Only use localstorage persist state on client

  store = createStore(
    combineReducers({ ...reducers }),
    initialState,
    composeEnhancers(...enhancers)
  );
  return store;
};

export { store, makeStore };
