import { 
    SET_BALANCE_FLOW_TYPE,
    SET_BAL_COMPLIANCE,
    SET_BAL_WITHDRAWAL_AMOUNT,
    SET_BAL_WITHDRAWAL_AVAIL_PAY_TYPE,
    SET_BAL_WITHDRAWAL_SELECTED_PAY_TYPE,
    SET_BAL_WITHDRAWAL_TRANS_INFO,
    SET_BAL_DEPOSIT_AMOUNT,
    SET_BAL_DEPOSIT_AVAIL_PAY_TYPE,
    SET_BAL_DEPOSIT_SELECTED_PAY_TYPE,
    SET_BAL_DEPOSIT_TRANS_INFO,
    SET_BAL_DEPOSIT_CUS_EFT_BANK,
    SET_BAL_DEPSOT_AVAIL_EFT_BANKS,
    SET_BAL_DEPOSIT_FLINKS,
    CLEAR_DEPOSIT_FLINKS,
    SET_BAL_DEPOSIT_PREFERRED_BANK,
    CLEAR_BAL_DEPOSIT_STATE,
    SET_BAL_WITHDRAW_PREFERRED_BANK,
    SET_BAL_WITHDRAW_VERIFICATION_CODE,
    SET_WALLET_BALANCE,
 } from './actionTypes';

const initialState = {
    type: null,
    balance: null,
    withdrawal_amount: null,
    withdrawal_trans_unique_id: null,
    withdrawal_payment_type: null,
    withdrawal_payment_types: [],
    withdrawal_trans_info: null,
    withdrawal_preferred_bank: null,
    deposit_amount: null,
    deposit_payment_types: [],
    deposit_payment_type: null,
    deposit_trans_info: null,
    deposit_selected_eft_bank: null,
    deposit_avail_eft_banks: [],
    deposit_flinks: null,
    deposit_preferred_bank: null,
    withdrawal_verification_code: null,
    wallet_balance: null
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_BALANCE_FLOW_TYPE:
            return { ...state, type: action.payload };
        case SET_BAL_COMPLIANCE:
            return { ...state, compliance: action.payload };
        case SET_BAL_WITHDRAWAL_AMOUNT:
            return { ...state, withdrawal_amount: action.payload };
        case SET_BAL_WITHDRAWAL_AVAIL_PAY_TYPE:
            return { ...state, withdrawal_payment_types: action.payload };
        case SET_BAL_WITHDRAWAL_SELECTED_PAY_TYPE:
            return { ...state, withdrawal_payment_type: action.payload };
        case SET_BAL_WITHDRAWAL_TRANS_INFO:
            return { ...state, withdrawal_trans_info: action.payload };
        case SET_BAL_DEPOSIT_AMOUNT:
            return { ...state, deposit_amount: action.payload };
        case SET_BAL_DEPOSIT_AVAIL_PAY_TYPE:
            return { ...state, deposit_payment_types: action.payload };
        case SET_BAL_DEPOSIT_SELECTED_PAY_TYPE:
            return { ...state, deposit_payment_type: action.payload };
        case SET_BAL_DEPOSIT_TRANS_INFO:
            return { ...state, deposit_trans_info: action.payload };  
        case SET_BAL_DEPOSIT_CUS_EFT_BANK:
            return { ...state, deposit_selected_eft_bank: action.payload };
        case SET_BAL_DEPSOT_AVAIL_EFT_BANKS:
            return { ...state, deposit_avail_eft_banks: action.payload };  
        case SET_BAL_DEPOSIT_FLINKS:
            return { ...state, deposit_flinks: action.payload };
        case SET_BAL_DEPOSIT_PREFERRED_BANK:
            return { ...state, deposit_preferred_bank: action.payload }; 
        case SET_BAL_WITHDRAW_PREFERRED_BANK:
            return { ...state, withdrawal_preferred_bank: action.payload };  
        case SET_BAL_WITHDRAW_VERIFICATION_CODE:
            return { ...state, withdrawal_verification_code: action.payload };  
        case SET_WALLET_BALANCE:
            return { ...state, wallet_balance: action.payload };           
        case CLEAR_DEPOSIT_FLINKS:
            return { ...state, deposit_flinks: null };   
        case CLEAR_BAL_DEPOSIT_STATE:
            return initialState;                        
        default:
            return state;    
    }        

}

export default reducer;