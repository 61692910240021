import {
  SET_COUNTRY_ID_INT,
  SET_SERVICE_ID_INT,
  SET_SUB_SERVICE_ID_INIT,
  SET_PROVIDER_ID,
  SET_LIST_OF_PRODUCTS_INT,
  SET_REQ_FIELDS_INT,
  SET_SELECTED_PLAN_INT,
  SET_REQ_FIELD_INFO_INT,
  SET_CUSTOM_AMNT_INT,
  CLEAR_SUB_SERVICE_INT,
  CLEAR_PRODUCTS_INFO_INT,
  CLEAR_OPERATOR_INFO_INT,
  CLEAR_ENTIRE_STATE_INT,
  SET_DEFAULT_COUNTRY_INT
} from './actionTypes';

const initialState = {
  countryId: null,
  serviceId:3,
  subServiceId: null,
  subServiceName:'',
  operator:null,
  products:null,
  requiredFields:[],
  selectedPlan:null,
  reqFieldInfo:null,
  customAmount:'',
  defaultCountry: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_COUNTRY_ID_INT:
      return { ...state, countryId: action.payload };
    case SET_SERVICE_ID_INT:
      return { ...state, serviceId: action.payload };
    case SET_SUB_SERVICE_ID_INIT:
      return { ...state, subServiceId: action.payload.id,subServiceName:action.payload.label }; 
    case SET_PROVIDER_ID:
      return {...state,operator:action.payload}  
    case SET_LIST_OF_PRODUCTS_INT:
      return {...state,products:action.payload}  
    case SET_REQ_FIELDS_INT:
      return {...state,requiredFields:action.payload}    
    case SET_SELECTED_PLAN_INT:
      return {...state,selectedPlan:action.payload}
    case SET_REQ_FIELD_INFO_INT:
      return {...state,reqFieldInfo:action.payload} 
    case SET_CUSTOM_AMNT_INT:
      return {...state,customAmount:action.payload}  
    case SET_DEFAULT_COUNTRY_INT:
      return {...state, defaultCountry:action.payload}  
    case CLEAR_SUB_SERVICE_INT:
      return {...state,subServiceId:null} 
    case CLEAR_OPERATOR_INFO_INT:
      return {...state,operator:null} 
    case CLEAR_PRODUCTS_INFO_INT:
      return {...state,products:null,requiredFields:[],selectedPlan:null,reqFieldInfo:null,customAmount:null} 
    case CLEAR_ENTIRE_STATE_INT:
      return {...initialState}        
    default:
      return state;
  }
};

export default reducer;