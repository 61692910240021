import auth from './auth/reducers';
import customer from './customer/reducers';
import recipient from './recipient/reducers';
import exchange from './exchange/reducers';
import transaction from './transaction/reducers';
import system from './system/reducers';
import flinks from './flinks/reducers';
import company from './company/reducers';
import walletDepositTransaction from './wallet-deposit-transaction/reducers';
import walletWithdrawalTransaction from './wallet-withdrawal-transaction/reducers'
import business from './business/reducers';
import questionnaire from './questionnaire/reducers';
import mobileTopUp from './mobileTopUp/reducers';
import game from './game/reducers';
import internationalBills from './internationalBills/reducers'
import giftCards from './giftCards/reducers'
import paymentStatus from './paymentStatus/reducers';
import dt_transaction from './services/reducers';
import escalation from './escalation/reducers';
import moneyTransfer from './moneyTransfer/reducers';
import cancelTransaction from './cancelTransaction/reducers';
import balance from './balance/reducers';
import currencyExchange from './currencyExchange/reducers';
import verification from './verification/reducers';
import esim from './esim/reducers';

/** Reducers keys that are acessible all over the application. */
export default {
  auth,
  customer,
  recipient,
  exchange,
  transaction,
  system,
  flinks,
  walletDepositTransaction,
  walletWithdrawalTransaction,
  company,
  business,
  questionnaire,
  game,
  paymentStatus,
  mobileTopUp,
  internationalBills,
  giftCards,
  dt_transaction,
  escalation,
  moneyTransfer,
  cancelTransaction,
  balance,
  currencyExchange,
  verification,
  esim
};
