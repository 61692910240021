import React, { useEffect, useRef, useState } from 'react'
import classes from './Alert.module.scss'
import NeutralAlertIcon from '../../../../public/design/alert-neutral.svg'
import SuccessAlertIcon from '../../../../public/design/alert-success.svg'
import WarningAlertIcon from '../../../../public/design/alert-negative.svg'
import ErrorAlertIcon from '../../../../public/design/alert-warning.svg'
import CloseIcon from '../../icons/CloseIcon';

enum Variant {
  neutral = 'neutral',
  success = 'success',
  warning = 'warning',
  negative = 'negative',
  /* For Message variants */
  completed = 'completed',
  inProgress = 'inProgress',
  cancelled = 'cancelled',
  custom = 'custom',
}

enum Type {
  message = 'message',
  alert = 'alert'
}

type AlertProps = {
  /**
   * id should be a `string`
   */
  id: string;
  /**
   * if `type` is message then variant should be `completed`, `inProgress`, `cancelled`, `custom`. or else from `Variant`
   */
  variant: Variant | string;
  /**
   * Pass an object for custom css
   */
  style?: object;
  /**
   * type should be either `message` or `alert`. Default value is `alert`
   */
  type?: Type | string;
  /**
   * message should be `string`, required.
   */
  message: string | React.ReactNode;
  /**
   * should be number. If `type` is alert, duration for making it disappear. Defalut value 6000ms
   */
  hideOnDuration?: number;
  /**
   * should be a boolean, default value is `true`. 
   */
  showStatusIcon?: boolean;
  /**
   * should be a boolean, default value is `true`. 
   */
  showCloseIcon?: boolean;
  /**
   * Positioning of the alert popper
   */
  anchorStyle?: object;
  /**
   * boolean expected. should be true, if anchorStyle to be used.
   */
  absolutePosition?: boolean;
  closeIconColor?: string;
  testId?: string;
  className?: any,
}

const variantIcon = {
  success: SuccessAlertIcon,
  warning: NeutralAlertIcon,
  negative: WarningAlertIcon,
  neutral: ErrorAlertIcon,
};

const Alert: React.FC<AlertProps> = ({ id, variant = 'success', type = 'alert', message, hideOnDuration = 6000, showStatusIcon = true, showCloseIcon = true, absolutePosition, anchorStyle = { bottom: '20px', left: '20px', right: '20px' }, testId, closeIconColor, className, ...rest }) => {
  const [open, setOpen] = useState(true)
  const Icon = variantIcon[variant]
  const divRef = useRef<HTMLDivElement | null>(null);
  const [closeIconStyle, setCloseIconStyle] = useState('')

  useEffect(() => {
    const computedStyle = window.getComputedStyle(divRef.current!);
    const fontSize = parseFloat(computedStyle.fontSize);
    const fontColor = computedStyle.color;

    setCloseIconStyle(closeIconColor || fontColor)

    if (showStatusIcon) {
      const imgElement = divRef.current!.querySelector('img');
      if (imgElement) {
        imgElement.style.width = `${fontSize + 8}px`;
        imgElement.style.height = `${fontSize + 8}px`;
      }
    }

  }, []);

  useEffect(() => {
    if (type === 'alert' && absolutePosition) {
      const timer = setTimeout(() => {
        handleClose();
      }, hideOnDuration);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [hideOnDuration]);

  const handleClose = () => {
    setOpen(false);
  };


  return (
    <div
      id={id}
      ref={divRef}
      className={`
       ${className || ''}
       ${classes[`rb-${type}`]}
       ${(absolutePosition && type === 'alert') ? classes[`rb-alert-fixed`] : ''}
       ${classes[`rb-${type}-${variant}`]}
       ${!open ? classes[`rb-${type}-close`] : ''}
     `}
      style={absolutePosition ? { ...anchorStyle, width: 'fit-content' } : {}}
      {...rest}
    >
      {(type === 'alert' && showStatusIcon) ? <img src={Icon} alt='icon' /> : null}
      <span className={classes['rb-text']} id='message'>{message}</span>
      {(type === 'alert' && showCloseIcon) ? <span data-testid={testId} className={classes['rb-icon']} onClick={handleClose}><CloseIcon color={closeIconStyle} /></span> : null}
    </div>
  )
}


export default Alert