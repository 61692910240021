const moengage_events = {
    // signup
    SIGNUP_INITIATED: 'signup_initiated',
    SIGNUP_PHONE_NUMBER_SUBMITTED: 'signup_phone_number_submitted',
    SIGNUP_COMPLETED: 'signup_completed',
    SIGNUP_REFERRAL_CODE: 'signup_referral_code',
    SIGNUP_ACCOUNT_TYPE_SELECTED: 'signup_account_type_selected',
    SIGNUP_PREFERRED_SERVICE_SELECTED: 'signup_preferred_service_selected',
    SIGNUP_INT_BILL_TYPE_SELECTED: 'signup_int_bill_type_selected',
    OPEN_BUSINESS_ACCOUNT: 'open_business_account',

    // send money
    SEND_MONEY_INITIATED: 'send_money_initiated',
    SEND_MONEY_COUNTRY_SELECTED: 'send_money_country_selected',
    SEND_MONEY_AMOUNT_CONFIRMED: 'send_money_amount_confirmed',
    SEND_MONEY_RECEIVING_METHOD_CONFIRMED: 'send_money_receiving_method_confirmed',
    SEND_MONEY_RECIPIENT_DETAILS_CONFIRMED: 'send_money_recipient_details_confirmed',
    SEND_MONEY_PERSONA_INITIATED: 'send_money_persona_initiated',
    SEND_MONEY_PERSONA_COMPLETED: 'send_money_persona_completed',
    SEND_MONEY_CUSTOMER_DETAILS_CONFIRMED: 'send_money_customer_details_confirmed',
    SEND_MONEY_CUSTOMER_ADDRESS_CONFIRMED: 'send_money_customer_address_confirmed',
    SEND_MONEY_CUSTOMER_OCCUPATION_CONFIRMED: 'send_money_customer_occupation_confirmed',
    SEND_MONEY_PURPOSE_CONFIRMED: 'send_money_purpose_confirmed',
    SEND_MONEY_PREFERRED_BANK_SELECTED: 'send_money_preferred_bank_selected',
    SEND_MONEY_PAYMENT_METHOD_SELECTED: 'send_money_payment_method_selected',
    SEND_MONEY_PAYMENT_DETAILS_CONFIRMED: 'send_money_payment_details_confirmed',
    SEND_MONEY_TRANSFER_OVERVIEW_CONFIRMED: 'send_money_overview_confirmed',
    SEND_MONEY_TRANSACTION_COMPLETED: 'send_money_transaction_completed',
    SEND_MONEY_TRANSACTION_CANCELLED: 'send_money_transaction_cancelled',
    SEND_MONEY_TRANSACTION_FAILED: 'send_money_payment_failed',
    SEND_MONEY_REQUESTED_REFUND: 'send_money_requested_refund',
    FLINKS_IFRAME_AUTHENTICATION_STARTED: 'flinks_iframe_authentication_started',
    FLINKS_IFRAME_AUTHENTICATION_ENDED: 'flinks_iframe_authentication_ended',
    FLINKS_API_INTEGRATION_FAILED: 'flinks_api_integration_failed',
    SEND_MONEY_REWARDS_APPLIED: 'send_money_rewards_applied',
    SEND_MONEY_PROMOCODE_APPLIED: 'send_money_promocode_applied',

    // currency exchange
    CURRENCY_EXCHANGE_INITIATED: 'currency_exchange_initiated',
    CURRENCY_EXCHANGE_AMOUNT_CONFIRMED: 'currency_exchange_amount_confirmed',
    CURRENCY_EXCHANGE_PURPOSE_CONFIRMED: 'currency_exchange_purpose_confirmed',
    CURRENCY_EXCHANGE_SELECT_ACCOUNT_CONNECTION_TYPE: 'currency_exchange_select_account_connection_type',
    CURRENCY_EXCHANGE_SELECT_PREFERRED_BANK: 'currency_exchange_select_preferred_bank',
    CURRENCY_EXCHANGE_PERSONA_INITIATED: 'currency_exchange_persona_initiated',
    CURRENCY_EXCHANGE_PERSONA_COMPLETED: 'currency_exchange_persona_completed',
    CURRENCY_EXCHANGE_CUSTOMER_DETAILS_CONFIRMED: 'currency_exchange_customer_details_confirmed',
    CURRENCY_EXCHANGE_CUSTOMER_ADDRESS_CONFIRMED: 'currency_exchange_customer_address_confirmed',
    CURRENCY_EXCHANGE_CUSTOMER_OCCUPATION_CONFIRMED: 'currency_exchange_customer_occupation_confirmed',
    CURRENCY_EXCHANGE_PAYMENT_BANK_ACCOUNT_SELECTED: 'currency_exchange_payment_bank_account_selected',
    CURRENCY_EXCHANGE_DEPOSIT_BANK_ACCOUNT_SELECTED: 'currency_exchange_deposit_bank_account_selected',
    CURRENCY_EXCHANGE_REWARDS_APPLIED: 'currency_exchange_rewards_applied',
    CURRENCY_EXCHANGE_PROMOCODE_APPLIED: 'currency_exchange_promocode_applied',
    PROMO_CODE_APPLIED: 'promo_code_applied',
    CURRENCY_EXCHANGE_MANUAL_PAYMENT_CONNECTION : 'currency_exchange_manual_payment_account_connection',
    CURRENCY_EXCHANGE_MANUAL_DEPOSIT_CONNECTION : 'currency_exchange_manual_deposit_account_connection',
    CURRENCY_EXCHANGE_OVERVIEW_CONFIRMED: 'currency_exchange_overview_confirmed',
    CURRENCY_EXCHANGE_COMPLETED: 'currency_exchange_completed',
    CURRENCY_EXCHANGE_CANCELLED: 'currency_exchange_cancelled',

    // add new recipient
    ADD_NEW_RECIPIENT_STARTED: 'add_new_recipient_started',
    ADD_NEW_RECIPIENT_COUNTRY_SELECTED: 'add_new_recipient_country_selected',
    ADD_NEW_RECIPIENT_RECEIVING_METHOD_SELECTED: 'add_new_recipient_receiving_method_selected',
    ADD_NEW_RECIPIENT_PERSONAL_DETAILS_ENTERED: 'add_new_recipient_personal_details_entered',
    ADD_NEW_RECIPIENT_BANK_DETAILS_ENTERED: 'add_new_recipient_bank_details_entered',
    ADD_NEW_RECIPIENT_CREATED: 'add_new_recipient_created',

    // balance deposit
    BALANCE_DEPOSIT_INITIATED: 'balance_deposit_initiated',
    BALANCE_DEPOSIT_AMOUNT_CONFIRMED: 'balance_deposit_amount_confirmed',
    BALANCE_DEPOSIT_PAYMENT_METHOD_SELECTED: 'balance_deposit_payment_method_selected',
    BALANCE_DEPOSIT_PAYMENT_DETAILS_CONFIRMED: 'balance_deposit_payment_details_confirmed',
    BALANCE_DEPOSIT_OVERVIEW_CONFIRMED: 'balance_deposit_overview_confirmed',
    BALANCE_DEPOSIT_COMPLETED: 'balance_deposit_completed',

    // balance withdrawal
    BALANCE_WITHDRAWAL_INITIATED: 'balance_withdrawal_initiated',
    BALANCE_WITHDRAWAL_AMOUNT_CONFIRMED: 'balance_withdrawal_amount_confirmed',
    BALANCE_WITHDRAWAL_METHOD_SELECTED: 'balance_withdrawal_method_selected',
    BALANCE_WITHDRAWAL_DETAILS_CONFIRMED: 'balance_withdrawal_details_confirmed',
    BALANCE_WITHDRAWAL_OVERVIEW_CONFIRMED: 'balance_withdrawal_overview_confirmed',
    BALANCE_WITHDRAWAL_COMPLETED: 'balance_withdrawal_completed',

    // Level 1 verification
    LEVEL1_VERIFICATION_STARTED: 'level1_verification_started',
    LEVEL1_PERSONA_INITIATED: 'level1_persona_initiated',
    LEVEL1_PERSONA_COMPLETED: 'level1_persona_completed',
    LEVEL1_VERIFICATION_CUSTOMER_DETAILS_CONFIRMED: 'level1_verification_customer_details_confirmed',
    LEVEL1_VERIFICATION_CUSTOMER_ADDRESS_CONFIRMED: 'level1_verification_customer_address_confirmed',
    LEVEL1_VERIFICATION_CUSTOMER_OCCUPATION_CONFIRMED: 'level1_verification_customer_occupation_confirmed',
    LEVEL1_VERIFICATION_COMPLETED: 'level1_verification_completed',

    // Level 2 verification
    LEVEL2_VERIFICATION_STARTED: 'level2_verification_started',
    LEVEL2_VERIFICATION_DOCUMENT_SELECTED: 'level2_verification_document_selected',
    LEVEL2_DOCUMENT_UPLOADED: 'level2_document_uploaded',
    LEVEL2_DOCUMENT_SUBMITTED: 'level2_document_submitted',
    LEVEL_2_VERIFICATION_COMPLETED: 'level2_verification_completed',

    // level 3 verification
    LEVEL3_VERIFICATION_INITIATED: 'level3_verification_initiated',
    LEVEL3_VERIFICATION_VIDEO_STARTED: 'level3_verification_video_started',
    LEVEL3_VERIFICATION_VIDEO_RECORDED: 'level3_verification_video_recorded',
    LEVEL3_VERIFICATION_VIDEO_UPLOADED: 'level3_verification_video_uploaded',
    LEVEL3_VERIFICATION_VIDEO_SUBMITTED: 'level3_verification_video_submitted',
    LEVEL3_VERIFICATION_COMPLETED: 'level3_verification_completed',

    // level 4 verification
    LEVEL4_VERIFICATION_COMPLETED: 'level4_verification_completed',

    // gift card
    GIFT_CARD_INITIATED: 'gift_card_initiated',
    GIFT_CARD_VALUE_SELECTED: 'gift_card_value_selected',
    GIFT_CARD_COUNTRY_SELECTED: 'gift_card_country_selected',
    GIFT_CARD_PROVIDER_SELECTED: 'gift_card_provider_selected',
    GIFT_CARD_PAYMENT_SELECTED: 'gift_card_payment_selected',
    GIFT_CARD_PAYMENT_DETAILS_ENTERED: 'gift_card_payment_details_entered',
    GIFT_CARD_OVERVIEW_CONFIRMED: 'gift_card_overview_confirmed',

    // mobile topup
    MOBILE_TOP_UP_INITIATED: 'mobile_top_up_initiated',
    MOBILE_TOP_UP_COUNTRY_SELECTED: 'mobile_top_up_country_selected',
    MOBILE_TOP_UP_PHONE_NUMBER_SUBMITTED: 'mobile_top_up_phone_number_submitted',
    MOBILE_TOP_UP_VALUE_SELECTED: 'mobile_top_up_value_selected',
    MOBILE_TOP_UP_PAYMENT_METHOD_SELECTED: 'mobile_top_up_payment_method_selected',
    MOBILE_TOP_UP_PAYMENT_DETAILS_ENTERED: 'mobile_top_up_payment_details_entered',
    MOBILE_TOP_UP_OVERVIEW_CONFIRMED: 'mobile_top_up_overview_confirmed',

    // bill payment
    BILL_PAYMENT_INITIATED: 'bill_payment_initiated',
    BILL_PAYMENT_COUNTRY_SELECTED: 'bill_payment_country_selected',
    BILL_PAYMENT_BILL_TYPE_SELECTED: 'bill_payment_bill_type_selected',
    BILL_PAYMENT_PROVIDER_AND_ACCOUNT_NUMBER_SELECTED: 'bill_payment_provider_and_account_number_selected',
    BILL_PAYMENT_VALUE_SELECTED: 'bill_payment_value_selected',
    BILL_PAYMENT_PAYMENT_SELECTED: 'bill_payment_payment_selected',
    BILL_PAYMENT_PAYMENT_DETAILS_ENTERED: 'bill_payment_payment_details_entered',
    BILL_PAYMENT_OVERVIEW_CONFIRMED: 'bill_payment_overview_confirmed',

    // Business Verification
    BA_VERIFICATION_STARTED: 'ba_verification_started',
    BA_VERIFICATION_BUSINESS_ADDRESS_DOCUMENT_SELECTED: 'ba_verification_business_address_document_selected',
    BA_VERIFICATION_BUSINESS_ADDRESS_DOCUMENT_UPLOADED: 'ba_verification_business_address_document_uploaded',
    BA_VERIFICATION_BUSINESS_REGISTRATION_DOCUMENT_UPLOADED: 'ba_verification_business_registration_document_uploaded',
    BA_VERIFICATION_BUSINESS_REGISTRATION_DOCUMENT_SUBMITTED: 'ba_verification_business_registration_document_submitted',
    BA_VERIFICATION_OWNERSHIP_SELECTED: 'ba_verification_ownership_selected',
    BA_VERIFICATION_ADDITIONAL_OWNER_PERSONAL_INFORMATION_SUBMITTED: 'ba_verification_additional_owner_personal_information_submitted',
    BA_VERIFICATION_ADDITIONAL_OWNER_ADDRESS_INFORMATION_SUBMITTED: 'ba_verification_additional_owner_address_information_submitted',
    BA_VERIFICATION_ADDITIONAL_OWNERS_ADDED: 'ba_verification_additional_owners_added',
    BA_VERIFICATION_OWNER_CONFIRMED: 'ba_verification_owner_confirmed',
    BA_VERIFICATION_NON_MSB_CONFIRMED: 'ba_verification_non_msb_confirmed',
    BA_VERIFICATION_CAD_COMPANY_CONFIRMED: 'ba_verification_cad_company_confirmed',
    BA_VERIFICATION_BUSINESS_INFORMATION_SUBMITTED: 'ba_verification_business_information_submitted',
    BA_VERIFICATION_BUSINESS_ADDRESS_INFORMATION_SUBMITTED: 'ba_verification_business_address_information_submitted',
    BA_VERIFICATION_DIRECTORS_SELECTED: "ba_verification_directors_selected",
    BA_VERIFICATION_ADDITIONAL_DIRECTOR_PERSONAL_INFORMATION_SUBMITTED: "ba_verification_additional_director_personal_information_submitted",
    BA_VERIFICATION_ADDITIONAL_DIRECTOR_ADDRESS_INFORMATION_SUBMITTED: "ba_verification_additional_director_address_information_submitted",
    BA_VERIFICATION_ADDITIONAL_DIRECTOR_ID_DOCUMENT_SELECTED: "ba_verification_additional_director_id_document_selected",
    BA_VERIFICATION_ADDITIONAL_DIRECTOR_ID_DOCUMENT_UPLOADED: "ba_verification_additional_director_id_document_uploaded",
    BA_VERIFICATION_ADDITIONAL_DIRECTOR_ID_DOCUMENT_SUBMITTED: "ba_verification_additional_director_id_document_submitted",
    BA_VERIFICATION_ADDITIONAL_DIRECTORS_ADDED: "ba_verification_additional_directors_added",
    BA_PERSONA_INITIATED: "ba_persona_initiated",
    BA_PERSONA_COMPLETED: "ba_persona_completed",
    BA_VERIFICATION_CUSTOMER_DETAILS_CONFIRMED: "ba_verification_customer_details_confirmed",
    BA_VERIFICATION_CUSTOMER_ADDRESS_CONFIRMED: "ba_verification_customer_address_confirmed",
    BA_VERIFICATION_CUSTOMER_OCCUPATION_CONFIRMED: "ba_verification_customer_occupation_confirmed",

    // esim
    ESIM_INITIATED: 'esim_initiated',
    ESIM_COMPATIBILITY_CONFIRMED: 'esim_compatibility_confirmed',
    ESIM_DESTINATION_SELECTED: 'esim_destination_selected',
    ESIM_PLAN_SELECTED: 'esim_plan_selected',
    ESIM_RECEIVING_EMAIL_SUBMITTED: 'esim_receiving_email_submitted',
    ESIM_PAYMENT_METHOD_SELECTED: 'esim_payment_method_selected',
    ESIM_PAYMENT_DETAILS_ENTERED: 'esim_payment_details_entered',
    ESIM_PAYMENT_OVERVIEW_CONFIRMED: 'esim_payment_overview_confirmed',

    // Referral
    REFERAL_EMAIL_INVITE_SENT: 'sent_referral_email_invitation',
    RECEIVED_EMAIL_INVITATION: 'received_email_invitation',
};


export default moengage_events;
