import { removeEmptyFieldsFromObject } from '../shared/utility';
const logger = {};

const logging = (logLevel = '', message, attributes = {}) => {
  try {
    attributes.service_name = 'remitbee-cp';
    if (window.DD_LOGS && process.env.REACT_APP_ENV !== 'dev') {
      let meta = {};
      attributes = removeEmptyFieldsFromObject(attributes);
      if (attributes) {
        meta = { ...attributes };
      }
      window.DD_LOGS.logger[logLevel](message, { meta });
    } else {
      switch (logLevel) {
        case 'error':
          console.error({ message, meta: { ...attributes } });
          break;
        case 'warn':
          console.warn({ message, meta: { ...attributes } });
          break;
        default:
          console.log({ message, meta: { ...attributes } });
      }
    }
  } catch (error) {
    console.error(error);
  }
};

logger.error = (message, attributes = {}) => {
  logging('error', message, attributes);
};

logger.info = (message, attributes) => {
  logging('info', message, attributes);
};

logger.warn = (message, attributes) => {
  logging('warn', message, attributes);
};

export default logger;
