import {
    SET_BUSINESS_ACC,
    SET_PERSONAL_ACC,
    SET_BUSINESS_COMPLIANCE,
    SET_OWNER_DIRECTOR_INFO,
    TOGGLE_BUSINESS_VERIFICATION,
    SET_EDIT_ID,
    SET_OWNER_DIRECTOR,
    SET_IS_MSB,
    SET_BUSINESS_ID, 
    SET_PROMO_STATUS,
    CLEAR_STATE,
    SET_CURRENT_FLOW,
    SET_BUSINESS_UNIQUE_ID,
    SET_BUSINESS_INFO,
    SET_BUSINESS_REDIRECT_ROUTE,
    SET_OWNER_DIRECTOR_FORM_DATA,
    SET_OWNER_DIRECTOR_FORM_STATUS,
    SET_OWNERSHIP_INFO,
    SET_DIRECTORSHIP_INFO,
    SET_OWNER_LIST_TYPE,
    SET_DIRECTOR_LIST_TYPE,
    SET_OWNER_LIST,
    SET_DIRECTOR_LIST,
    SET_DIRECTOR_FORM_STATUS,
    SET_DIRECTOR_FORM_DATA,
    SET_DIRECTOR_UPLOAD_METHOD,
} from './actionTypes';

const initialState = {
  account_type: 'personal',
  business_compliance: {},
  is_business_verification: true,
  is_msb: null,
  business_id: null,
  owner_director: null, 
  edit_id: null,  
  flow: null,
  business_unique_id: null,
  redirectRoute: null,
  promo_status: null,
  ownerDirectorFormData: null,
  ownerDirectorFormStatus: null,
  ownerDirectorInfo: null,
  ownerShipInfo: null,
  directorShipInfo: null,
  ownerListType: null,
  directorListType: null,
  ownerList: null,
  directorList: null,
  directorFormStatus: null,
  directorFormData: null,
  directorUploadMethod: null,
};

const businessReducers = (state = initialState, action) => {
  switch (action.type) {
    case SET_BUSINESS_ACC:
      return { ...state, account_type: 'business' };
    case SET_PERSONAL_ACC:
      return { ...state, account_type: 'personal' };
    case SET_BUSINESS_COMPLIANCE:
      return { ...state, business_compliance: action.payload };
    case SET_EDIT_ID: 
      return {...state, edit_id: action.payload}; 
    case SET_OWNER_DIRECTOR:
      return {...state, owner_director: action.payload}
    case SET_IS_MSB: 
      return {...state, is_msb: action.payload }
    case SET_BUSINESS_ID: 
      return {...state, business_id: action.payload}  
    case SET_PROMO_STATUS: 
      return {...state, promo_status: action.payload}
    case CLEAR_STATE:
      return initialState;
    case TOGGLE_BUSINESS_VERIFICATION:
      return {...state, is_business_verification: !state.is_business_verification };
    case SET_CURRENT_FLOW:
      return {...state, flow: action.payload };
    case SET_BUSINESS_UNIQUE_ID:
      return { ...state, business_unique_id: action.payload }
    case SET_OWNER_DIRECTOR_FORM_DATA:
      return { ...state, ownerDirectorFormData: action.payload }
    case SET_OWNER_DIRECTOR_FORM_STATUS:
      return { ...state, ownerDirectorFormStatus: action.payload }
    case SET_BUSINESS_INFO: {
      return { ...state, ...action.payload }
    }
    case SET_BUSINESS_REDIRECT_ROUTE: {
      return { ...state, redirectRoute: action.payload }
    }
    case SET_OWNER_DIRECTOR_INFO: {
      return { ...state, ownerDirectorInfo: action.payload }
    }
    case SET_OWNERSHIP_INFO: {
      return { ...state, ownerShipInfo: action.payload }
    }
    case SET_DIRECTORSHIP_INFO: {
      return { ...state, directorShipInfo: action.payload }
    }
    case SET_OWNER_LIST:
      return { ...state, ownerList: action.payload }
    case SET_DIRECTOR_LIST:
      return { ...state, directorList: action.payload }  
    case SET_OWNER_LIST_TYPE: 
      return { ...state, ownerListType: action.payload }
    case SET_DIRECTOR_LIST_TYPE:
      return { ...state, directorListType: action.payload }  
    case SET_DIRECTOR_FORM_STATUS:
      return { ...state, directorFormStatus: action.payload }
    case SET_DIRECTOR_FORM_DATA:
      return { ...state, directorFormData: action.payload }  
    case SET_DIRECTOR_UPLOAD_METHOD:
      return { ...state, directorUploadMethod: action.payload }    
    default:
      return state;
  }
};

export default businessReducers;