import { CLEAR_ESIM_STATE, SET_SELECTED_DESTINATION_TYPE, SET_PLANS_DETAILS, SET_COUNTRIES_DETAILS, SET_SELECTED_DESTINATION_REGION, SET_SELECTED_PLAN_ESIM, SET_RECEIVING_EMAIL_ESIM, SET_COMPATIBILITY_DEVICES_ESIM } from "./actionTypes";


const initialState = {
    destination_type: null,
    selected_destination_region: null,
    countries_details: null,
    plans_list: null,
    selectedPlan: null,
    receiving_email: null,  
    compatability_devices: null    
};

  const reducer = (state = initialState, action) => {
    switch (action.type) {  
      case SET_SELECTED_DESTINATION_TYPE:
        return { ...state, destination_type: action.payload }; 
      case SET_COUNTRIES_DETAILS:
        return { ...state, countries_details: action.payload }; 
      case SET_SELECTED_DESTINATION_REGION:
        return { ...state, selected_destination_region: action.payload };   
      case SET_PLANS_DETAILS:
        return { ...state, plans_list: action.payload };  
      case SET_SELECTED_PLAN_ESIM:
        return { ...state, selectedPlan: action.payload }; 
      case SET_RECEIVING_EMAIL_ESIM:
        return { ...state, receiving_email: action.payload }; 
      case SET_COMPATIBILITY_DEVICES_ESIM:
        return { ...state, compatability_devices: action.payload };         
      case CLEAR_ESIM_STATE:
        return initialState  
      default:
        return state;
    }
  };
  
  export default reducer;