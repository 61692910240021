import {
    SET_TRANSACTION_DETAILS_DT,
    SET_PAYMENT_TYPE_DT,
    SET_CARD_DETAIL_DT,
    SET_TRANSFER_AMNT_DT,
    SET_FINAL_TRANSFER_AMNT_DT,
    SET_REWARDS_AMNT_DT,
    SET_WALLET_BAL_DT,
    SET_TRANSACTION_STATUS_DT,
    SET_ADDITIONAL_RESP_DT,
    SET_TRANSACTION_PAGE_VISITED,
    SET_DT_TRANS_FLOW,
    CLEAR_TRANS_STATE_DT,
    SET_CHALLENGE_PARAMS,
    SET_TRANSACTION_UNIQUE_ID_DT,
    SET_REDIRECT_ROUTE,
    SET_WALLET_BALANCE,
    SET_PAYMENT_ERROR
} from './actionTypes';

const generateUUID = () => {
    return self?.crypto?.randomUUID();
};

const initialState = {
    transfer_amount: '',
    dt_flow: '',
    payment_type: '',
    wallet_balance: '',
    wallet_balance_applied: '',
    destination_country: '',
    service_type: '',
    sub_service_type: '',
    service_provider: '',
    recipient_details: {},
    product: '',
    product_value: '',
    final_transfer_amount: '',
    card: '',
    rewards_amount: '',
    status: '',
    transaction_page_visited: false,
    additional_response: '',
    transactionUniqueId: '',
    challengeParams: {},
    redirectRoute: '',
    error: false,
    payment_error: '',
    payment_card_error: '',
    idempotencyKey: '',
};

const reducer = (state = initialState, action) => {
    let newState;

    switch (action.type) {
        case SET_TRANSACTION_DETAILS_DT:
            newState = { ...state, ...action.payload };
            break;
        case SET_TRANSACTION_PAGE_VISITED:
            if (state.transaction_page_visited === action.payload) return state;
            newState = { ...state, transaction_page_visited: action.payload };
            break;
        case SET_PAYMENT_TYPE_DT:
            if (state.payment_type === action.payload) return state;
            newState = { ...state, payment_type: action.payload };
            break;
        case SET_CARD_DETAIL_DT:
            if (state.card === action.payload) return state;
            newState = { ...state, card: action.payload, idempotencyKey: generateUUID(newState) };
            break;
        case SET_TRANSFER_AMNT_DT:
            if (state.transfer_amount === action.payload) return state;
            newState = { ...state, transfer_amount: action.payload, idempotencyKey: generateUUID(newState) };
            break;
        case SET_FINAL_TRANSFER_AMNT_DT:
            if (state.final_transfer_amount === action.payload) return state;
            newState = { ...state, final_transfer_amount: action.payload, idempotencyKey: generateUUID(newState) };
            break;
        case SET_REWARDS_AMNT_DT:
            if (state.rewards_amount === action.payload) return state;
            newState = { ...state, rewards_amount: action.payload };
            break;
        case SET_WALLET_BAL_DT:
            if (state.wallet_balance_applied === action.payload) return state;
            newState = { ...state, wallet_balance_applied: action.payload };
            break;
        case SET_TRANSACTION_STATUS_DT:
            if (state.status === action.payload) return state;
            newState = { ...state, status: action.payload };
            break;
        case SET_ADDITIONAL_RESP_DT:
            if (state.additional_response === action.payload) return state;
            newState = { ...state, additional_response: action.payload };
            break;
        case SET_DT_TRANS_FLOW:
            if (state.dt_flow === action.payload) return state;
            newState = { ...state, dt_flow: action.payload };
            break;
        case SET_TRANSACTION_UNIQUE_ID_DT:
            if (state.transactionUniqueId === action.payload) return state;
            newState = { ...state, transactionUniqueId: action.payload };
            break;
        case SET_CHALLENGE_PARAMS:
            if (state.challengeParams === action.payload) return state;
            newState = { ...state, challengeParams: action.payload };
            break;
        case SET_REDIRECT_ROUTE:
            if (state.redirectRoute === action.payload) return state;
            newState = { ...state, redirectRoute: action.payload };
            break;
        case SET_WALLET_BALANCE:
            if (state.wallet_balance === action.payload) return state;
            newState = { ...state, wallet_balance: action.payload };
            break;
        case CLEAR_TRANS_STATE_DT:
            newState = initialState;
            break;
        case SET_PAYMENT_ERROR:
            if (state.error === action.payload) return state;
            newState = { ...state, error: action.payload };
            break;
        default:
            return state;
    }

    return { ...newState };
};


export default reducer;
