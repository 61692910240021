import transform from 'lodash/transform';
import isEqual from 'lodash/isEqual';
import isObject from 'lodash/isObject';
import difference from 'lodash/difference';
import pick from 'lodash/pick';
import { specialCharactersRegex } from './regex';
import browserLogger from '../settings/browser-logger';
import { store } from '../redux/store'
import * as CryptoJS from 'crypto-js';
import axios from 'axios';
import dayjs from 'dayjs';
import crypto from 'crypto';
// import { PhoneNumberUtil } from 'google-libphonenumber';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

/**
 * @function updateObject
 * @description It is used to update easily the objects instead of spread
 * operators around all the code.
 * @param {Object} actualState
 * @param {Object} updatedValues
 */
export const updateObject = (actualState, updatedValues) => ({
  ...actualState,
  ...updatedValues
});

/**
 * Gets the message returned if it's a custom error
 * @function
 * @param {error} jsError Original error returned from the GraphQL request
 * @returns {string}
 */
export const graphqlError = jsError => {
  try {
    browserLogger.error('graphqlError Layer', {
      jsError,
      action_from: 'graphqlError'
    });
    const jsonString = JSON.stringify(jsError);
    const error = jsonString && JSON.parse(jsonString);
    const noInternetError = error?.message?.includes('GraphQL error: getaddrinfo ENOTFOUND');
    const apiError = error?.message?.includes('Network error: Failed to fetch');
    if (error && error?.graphQLErrors && error?.graphQLErrors?.length) {
      const { extensions, message } = error?.graphQLErrors[0];
      return error?.message && noInternetError
        ? 'Please check your network and try again'
        : extensions || message
          ? message
          : 'Something went wrong';
    }
    return apiError ? 'Please check your network and try again' : 'Something went wrong';
  } catch (error) {
    browserLogger.error(error.message, {
      error,
      action_from: 'graphqlError'
    });
  }
};

/**
 * Get the keys that changed between two different objects
 * @param {object} object Update object
 * @param {object} base Original object
 */
export const objectDiff = (object, base) => {
  return transform(object, (result, value, key) => {
    if (!isEqual(value, base[key])) {
      result[key] = isObject(value) && isObject(base[key]) ? difference(value, base[key]) : value;
    }
  });
};

/**
 * @function capitalize
 * @description It will be used to capitalize the first letter from a word.
 * @param {String} string - words.
 */
export const capitalize = string => string && string[0].toUpperCase() + string.slice(1);

/**
 * @function titleCase
 * @description It will be used to titleCase the first letter from a word.
 * @param {String} string - eg canada / CANADA
 * @return {String} string - eg Canada
 */
export const titleCase = string => string && string[0].toUpperCase() + string.slice(1).toLowerCase();

export const base64PDFTransformData = base64String => `data:application/pdf;base64,${base64String}`;

/**
 * @function removePhoneMask
 * @description It will remove the special characters from a phone number and a Mask.
 * @param {String} phoneNumber.
 * @returns {String}
 */
export const removePhoneMask = phoneNumber =>
  phoneNumber
    ? phoneNumber
      .replace(/-/g, '')
      .replace(/\(/g, '')
      .replace(/\)/g, '')
      .replace(/ /g, '')
      .replace(specialCharactersRegex, '')
    : null;

/**
 * @function formatPhoneNumber
 * @description It will format a number to +1 (647) 555-555.
 * @param {String} phoneNumber.
 * @returns {String}
 */
export function formatPhoneNumber(phoneNumberString) {
  var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    var intlCode = match[1] ? '+1 ' : '';
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
  }
  return null;
}

/**
 * 
 * @param {string} input - 1234567890
 * @returns {String} - format to 123-456-7890
 */
export function formatPhoneNumberV2(input) {
  if (typeof input === 'string') {
    const part1 = input.substring(0, 3);
    const part2 = input.substring(3, 6);
    const part3 = input.substring(6, 10);

    return `${part1}-${part2}-${part3}`;
  } else {
    return 'Invalid input';
  }
}

/**
 * @function removeSpecialCharacters
 * @description Remove special characters from strings.
 * @param {String} string
 * @returns {String}
 */
export const removeSpecialCharacters = string => {
  if (string && typeof key === 'string') {
    string.replace(specialCharactersRegex, '');
  }
  return string;
};

/**
 * @function removeEmptyFieldsFromObject
 * @description It will remove the empty string/null/undefined fields from object.
 * @param {Object} element
 */
export const removeEmptyFieldsFromObject = element => {
  Object.keys(element).forEach(key => {
    if (element[key] === undefined) delete element[key];
    else if (element[key] === null) delete element[key];
    else if (element[key] === '') delete element[key];
  });

  return element;
};

/**
 * @function removeEmptyFieldsFromObject
 * @description It will remove the special characters from all fields in an object.
 * @param {Object} element
 */
export const removeSpecialCharactersFromObject = element => {
  Object.keys(element).forEach(key => {
    if (typeof key === 'object') removeSpecialCharactersFromObject(key);
    if (typeof key === 'string') element[key] = removeSpecialCharacters(element[key]);
  });

  return element;
};

export const removeMask = value =>
  value
    ? value
      .replace(/-/g, '')
      .replace(/\(/g, '')
      .replace(/\)/g, '')
      .replace(/ /g, '')
      .replace(/\//g, '')
      .replace(/\\/g, '')
      .replace(/\./g, '')
    : value;

export const parseValueToTwoDecimalsWithoutRound = value => {
  if (value) {
    value = (+value).toFixed(2);
    const valueSplitted = value.split('.');
    if (valueSplitted.length > 0) {
      const decimals = valueSplitted[1] ? `.${valueSplitted[1].substring(0, 2)}` : '';
      const strValue = `${valueSplitted[0]}${decimals}`;
      return new Intl.NumberFormat('en', { minimumFractionDigits: 2 }).format(+strValue);
    }
  }
  return value;
};

/**
 * Get bank icons based on the bank details and the translation function
 * @param {object} bankDetails Bank details
 * @param {Function} t Transalation function
 * @return {object} Icon details
 */
export const fetchBankIcon = (bankDetails, t) => {
  let id = null;
  let logo = null;
  let bank_name = null;
  let institution_no = null;
  let flinks_bank_name = null;

  if (bankDetails) {
    id = bankDetails?.id;
    logo = bankDetails?.logo;
    bank_name = bankDetails?.bank_name;
    institution_no = bankDetails?.institution_no;
    flinks_bank_name = bankDetails?.flinks_bank_name;
  }
  let iconDetails = null;
  switch (logo) {
    case 'bmo':
      iconDetails = {
        id,
        bank_name,
        institution_no,
        flinks_bank_name,
        src: '/icons/banks/bmo.svg',
        title: t('bmo_title'),
        alt: t('bmo_alt'),
        bank_portal_link: 'https://www.bmo.com'
      };
      break;
    case 'scotiabank':
      iconDetails = {
        id,
        bank_name,
        institution_no,
        flinks_bank_name,
        src: '/icons/banks/scotiabank.svg',
        title: t('scotiabank_title'),
        alt: t('scotiabank_alt'),
        bank_portal_link: 'https://www.scotiabank.com'
      };
      break;
    case 'cibc':
      iconDetails = {
        id,
        bank_name,
        institution_no,
        flinks_bank_name,
        src: '/icons/banks/cibc.svg',
        title: t('cibc_title'),
        alt: t('cibc_alt'),
        squareImage: true,
        bank_portal_link: 'https://www.cibc.com'
      };
      break;
    case 'rbc':
      iconDetails = {
        id,
        bank_name,
        institution_no,
        flinks_bank_name,
        src: '/icons/banks/rbc.svg',
        title: t('rbc_title'),
        alt: t('rbc_alt'),
        squareImage: true,
        bank_portal_link: 'https://www.rbc.com'
      };
      break;
    case 'td':
      iconDetails = {
        id,
        bank_name,
        institution_no,
        flinks_bank_name,
        src: '/icons/banks/td.svg',
        title: t('td_title'),
        alt: t('td_alt'),
        squareImage: true,
        bank_portal_link: 'https://www.td.com'
      };
      break;
    case 'tangerine':
      iconDetails = {
        id,
        bank_name,
        institution_no,
        flinks_bank_name,
        src: '/icons/banks/tangerine.svg',
        title: t('tangerine_title'),
        alt: t('tangerine_alt'),
        bank_portal_link: 'https://www.tangerine.ca'
      };
      break;
    case 'meridian':
      iconDetails = {
        id,
        bank_name,
        institution_no,
        flinks_bank_name,
        src: '/icons/banks/meridian.svg',
        title: t('meridian_title'),
        alt: t('meridian_alt'),
        bank_portal_link: 'https://www.meridiancu.ca'
      };
      break;
    case 'national_bank':
      iconDetails = {
        id,
        bank_name,
        institution_no,
        flinks_bank_name,
        src: '/icons/banks/national_bank.svg',
        title: t('national_bank_of_canada_title'),
        alt: t('national_bank_of_canada_alt'),
        bank_portal_link: 'https://www.nbc.ca'
      };
      break;
    case 'desjardins':
      iconDetails = {
        id,
        bank_name,
        institution_no,
        flinks_bank_name,
        src: '/icons/banks/desjardins.svg',
        title: t('desjardins_title'),
        alt: t('desjardins_alt'),
        bank_portal_link: 'https://www.desjardins.com'
      };
      break;
    case 'hsbc':
      iconDetails = {
        id,
        bank_name,
        institution_no,
        flinks_bank_name,
        src: '/icons/banks/hsbc.svg',
        title: t('hsbc_title'),
        alt: t('hsbc_alt'),
        bank_portal_link: 'https://www.hsbc.ca'
      };
      break;
    case 'laurentian_bank':
      iconDetails = {
        id,
        bank_name,
        institution_no,
        flinks_bank_name,
        src: '/icons/banks/laurentian_bank.svg',
        title: t('laurentian_bank_title'),
        alt: t('laurentian_bank_alt'),
        bank_portal_link: 'https://www.laurentianbank.ca'
      };
      break;
    case 'manulife_bank':
      iconDetails = {
        id,
        bank_name,
        institution_no,
        flinks_bank_name,
        src: '/icons/banks/manulife_bank.svg',
        title: t('manulife_bank_title'),
        alt: t('manulife_bank_alt'),
        bank_portal_link: 'https://www.manulife.ca'
      };
      break;
    case 'financial':
      iconDetails = {
        id,
        bank_name,
        institution_no,
        flinks_bank_name,
        src: '/icons/banks/financial.svg',
        title: t('financial_title'),
        alt: t('financial_alt'),
        bank_portal_link: 'https://www.pcfinancial.ca'
      };
      break;
    case 'vancity':
      iconDetails = {
        id,
        bank_name,
        institution_no,
        flinks_bank_name,
        src: '/icons/banks/vancity.svg',
        title: t('vancity'),
        alt: t('vancity_alt'),
        bank_portal_link: 'https://www.vancity.com'
      };
      break;
    case 'atb':
      iconDetails = {
        id,
        bank_name,
        institution_no,
        flinks_bank_name,
        src: '/icons/banks/atb.svg',
        title: t('atb'),
        alt: t('atb_alt'),
        bank_portal_link: 'https://www.atb.com'
      };
      break;
    case 'simplii':
      iconDetails = {
        id,
        bank_name,
        institution_no,
        flinks_bank_name,
        src: '/icons/banks/simplii.svg',
        title: t('simplii'),
        alt: t('simplii_alt'),
        squareImage: true,
        bank_portal_link: 'https://www.simplii.com'
      };
      break;
    case 'eqbank':
      iconDetails = {
        id,
        bank_name,
        institution_no,
        flinks_bank_name,
        src: '/icons/banks/eqbank.svg',
        title: t('eqbank'),
        alt: t('eqbank_alt'),
        customStyle: {
          marginTop: 0,
        },
        bank_portal_link: 'https://www.eqbank.ca'
      };
      break;
    case 'other':
      iconDetails = {
        id,
        bank_name,
        institution_no,
        flinks_bank_name,
        src: '/icons/banks/other_bank.svg',
        title: t('other_bank_title'),
        alt: t('other_bank_alt'),
        bank_portal_link: 'https://www.bmo.com'
      };
      break;
    default:
      iconDetails = {
        id,
        bank_name,
        institution_no,
        flinks_bank_name,
        src: '/icons/banks/other_bank.svg',
        title: t('other_bank_title'),
        alt: t('other_bank_alt'),
        bank_portal_link: 'https://www.bmo.com'
      };
  }

  return iconDetails;
};

/**
 * Parse a string to hyphen separated. E.g.: "Send money to India" -> "send-money-to-india"
 * @param {string} input "String in this format."
 * @return {string} "string-in-this-format"
 */
export const formatToHyphenDivider = input =>
  (input || '')
    .replace(/[^\w\s]/gi, '')
    .toLowerCase()
    .split(' ')
    .join('-');

/**
 * Determine the mobile operating system.
 * This function returns one of 'iOS', 'Android', 'Windows Phone', or 'unknown'.
 *
 * @returns {String}
 */
export function getMobileOperatingSystem() {
  if (typeof window !== 'undefined') {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (userAgent && /windows phone/i.test(userAgent)) {
      return 'Windows Phone';
    }

    if (userAgent && /android/i.test(userAgent)) {
      return 'Android';
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (userAgent && /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return 'iOS';
    }
  }

  return 'unknown';
}

/**
* @function interpolate
* @description Interpolates a string with variables. e.g interpolate("I'm {age} years old.", {age: 20}) -> "I'm 20 years old"
* @param {String} s The string to be interpolated. "I'm {age} years old."
* @param {String} o An object with the variables. {age: 20}
* @returns {Object|null} Returns the string with variables in correct places
*/
export function interpolate(s, o) {
  if (!s || typeof s !== 'string') return "";
  if (!o || Object.entries(o).length === 0) return s;
  return s.replace(/{([^{}]*)}/g, function (a, b) {
    let key = b.includes('||') ? b.split('||').map(e => e.trim()) : b
    var r = Array.isArray(key) ? (o[key[0]] || o[key[1]]) : o[key];
    return typeof r === "string" || typeof r === "number" ? r : a;
  });
}

/**
* @function getImageAlt
* @description will get last part of image url to display as the alt
* @param {String} imageURL pass in the image URL 
* @returns {String} this will be the ending of the image url which can be used as the alt
*/
export function getImageAlt(imageURL) {
  if (imageURL != undefined) {
    const alt = imageURL.substring(imageURL.lastIndexOf("/") + 1, imageURL.length - 4) //this works for local images refactor for strapi images
    return alt;
  }
}

/**
 * @function isObjectEmpty
 * @description It will check if the object it has some param on it and return if is empty.
 * @param {Object} element - Object to be checked.
 */
export const isObjectEmpty = (element) => {
  if (element && Object.values(element).length > 0) {
    return false;
  }
  return true;
}

/**
 * @function getLanguagePaths
 * @description It will get the correct paths for pages that may have translations
 * @param path - path of the page that is being checked
 */
export const getLanguagePaths = (path) => { //just made this function test if it works
  const basePath = 'https://www.remitbee.com';
  const languageSubpaths = [
    // {code: 'fr-CA', subpath: 'fr/'},
    { code: 'ta', subpath: 'ta' },
    { code: 'si', subpath: 'si' },
    { code: 'es', subpath: 'es' },
  ];
  const Alternates = languageSubpaths.map(lang => ({
    hrefLang: lang.code,
    href: `${basePath}/${lang.subpath}/${path}`,
  }));
  Alternates.push({
    hrefLang: 'x-default',
    href: `${basePath}/${path}`,
  });
  return Alternates;
}

/**
 * @function getCardType
 * @description It will get the credit card type
 * @param string - credit card number
 */

export const getCardType = (cardNumber) => {
  const visaRegex = /^(?:4[0-9]{12}(?:[0-9]{3})?)$/;
  const masterRegex = /^(?:5[1-5][0-9]{14})$/;
  let cardType = null;
  cardNumber = removeMask(cardNumber)

  if (visaRegex.test(cardNumber)) {
    cardType = 'visa'
  } else if (masterRegex.test(cardNumber)) {
    cardType = 'mastercard'
  }

  return cardType;
}
/*
 * @function createHash
 * @description It will create hash of the provided string
 * @param str
 */
export const createHash = (str) => {
  const secret = process.env.AES_KEY;
  const encrypt = CryptoJS.AES.encrypt(str, secret);
  return encrypt.toString();
}

export const formatExpiryDate = (str) => {
  return `${str[0]}${str[1]}/${str[2]}${str[3]}`
}


export const validateExpiryDate = (expiry_date) => {
  const regex = /^(0[1-9]|1[0-2])([0-9]{2})$/;
  let isValid = regex.test(expiry_date);

  if (!isValid) {
    return false;
  }

  const currentMonth = +dayjs().format('MM');
  const currentYear = +dayjs().format('YY');
  const cardMonth = Number(`${expiry_date[0]}${expiry_date[1]}`)
  const cardYear = Number(`${expiry_date[2]}${expiry_date[3]}`)

  if (cardYear < currentYear) {
    isValid = false;
  }
  if (cardYear === currentYear && cardMonth < currentMonth) {
    isValid = false;
  }

  return isValid;
}

/**
 * @function getLanguageRoute
 * @description It will get a url route with correct translation tag
 * @param path - path of the page that is being checked
 */
export const getLanguageRoute = (path1, path2) => {
  let translation = false;
  let fullpath = '';
  if (typeof window !== 'undefined') {
    const defaultLanguage = localStorage.getItem('i18nextLng');

    if (defaultLanguage && defaultLanguage !== 'en') {
      translation = true;
      path2 ? fullpath = `/${defaultLanguage}/${path1}/${path2}` : fullpath = `/${defaultLanguage}/${path1}`;
    } else {
      translation = false;
      path2 ? fullpath = `/${path1}/${path2}` : fullpath = `/${path1}`;
    }
  }
  let languageRoute = {
    isTranslated: translation,
    route: fullpath
  }
  return languageRoute;
}

export const convertHeicToJpeg = async (file) => {
  // eslint-disable-next-line no-undef
  return heic2any ? await heic2any({
    blob: file,
    toType: "image/jpeg"
  }) : file;
}

export const getReferralAmount = () => {
  const company = store ? store.getState().company : {};
  return company;
}

export const getReferralProgram = async () => {
  const result = await axios.get(`${process.env.REACT_APP_API}/public-services/rc-referrals-info`);
  const data = result?.data;
  return data;
}


export const documentVerificationStatus = ({ documentType, requestedTier, levels }) => {
  let isUploadRequired = false;
  levels.filter(({ tier }) => tier === requestedTier).reduce((acc, level) => {
    let entity = {};
    level.groups.forEach(group => {
      const { key, fields } = group;
      if (key === 'AML_DOCUMENTS_PROOF') entity = fields;
    })
    return Object.keys(entity).length ? [...acc, ...entity] : [...acc];
  }, []).forEach(({ name, status }) => {
    if (name === documentType) {
      if (status === 'NOT_UPLOADED') isUploadRequired = true;
    }
  });
  return isUploadRequired;
}

export const getIpAddress = async () => {
  let ip_address;
  try {
    let ipInfo = await fetch('https://ipv4.jsonip.com', { mode: 'cors' });
    if (ipInfo) {
      ipInfo = await ipInfo.json();
      ip_address = ipInfo.ip;
    }
    return ip_address;
  } catch (err) {
    browserLogger.error('Third party error', {
      jsError: err,
      action_from: 'getIpAddress'
    });
    return ip_address;
  }
}

export const parseArrFromString = (str) => {
  const items = [];
  if (str) {
    const arr = str.split(',,,,');
    if (arr) {
      for (const item of arr) {
        const objs = item.split('____');
        const newObj = {};
        for (const obj of objs) {
          const vals = obj.split('::::');
          newObj[vals[0]] = vals[1]
        }
        items.push(newObj)
      }
    }
  }
  return items;
}


export function calculateDateWith48Hours() {

  let currentDate = dayjs();

  let nextDate = currentDate.add(48, 'hour');

  if (nextDate.month() !== currentDate.month()) { // If it's end of month reset date count 
    nextDate.date(1);
    nextDate.add(1, 'months');
    nextDate = nextDate.date(1).add(1, 'month');
  }

  if (currentDate.date() === currentDate.clone().endOf('month').date()) { // If it's the last date of the month add 1 day count
    nextDate = nextDate.add(1, 'day');
  }

  let formattedDate = nextDate.format('MMM DD, YYYY');

  return formattedDate;
}

export function calculateDateWith24Hours() {

  let currentDate = dayjs();

  let nextDate = currentDate.add(24, 'hour');

  if (nextDate.month() !== currentDate.month()) { // If it's end of month reset date count 
    nextDate = nextDate.date(1).add(1, 'month');
  }

  if (currentDate.date() === currentDate.clone().endOf('month').date()) { // If it's the last date of the month add 1 day count
    nextDate = nextDate.add(1, 'day');
  }

  let formattedDate = nextDate.format('MMM DD, YYYY');

  return formattedDate;
}

export const getNameInitials = (name) => {
  let initials = '';
  if (name) {
    initials = name.match(/\b\w/g) || [];
    initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
  }
  return initials;
};

export function hideNumber(number) {
  const numberString = String(number);
  const hiddenPart = numberString.slice(0, -4).replace(/\d/g, '*');
  const visiblePart = numberString.slice(-4);
  return hiddenPart + visiblePart;
}

export const getPaymentErrorType = (errorMsg) => {
  if (!errorMsg) return null;

  if (errorMsg.includes('Declined by your bank')) return 'debit_declined_error_msg';
  if (errorMsg.includes('Moneris 3DS')) return 'debit_moneris_error_msg';
  if (errorMsg === 'debit_moneris_error_msg' || errorMsg === 'debit_declined_error_msg') return errorMsg;
  return null;
}

export const calculateSRIHash = async (fileUrl) => {
  const response = await fetch(fileUrl);
  const scriptContent = await response.text();
  const hash = crypto.createHash('sha384');
  hash.update(scriptContent);
  return `sha384-${hash.digest('base64')}`;
}


export const isValidPhoneNumber = (phoneNumber, region) => {
  try {
    if (!phoneNumber) return false;
    // const phoneUtil = PhoneNumberUtil.getInstance();
    // const rawNumber = phoneUtil.parseAndKeepRawInput(phoneNumber);
    // return region ? phoneUtil.isValidNumberForRegion(rawNumber, region) : phoneUtil.isValidNumber(rawNumber);
    const phoneNumberObj = parsePhoneNumberFromString(phoneNumber, region);
    return phoneNumberObj ? phoneNumberObj.isValid() : false;
  } catch {
    return false;
  }
}

export const sanitizePath = (redirectTo, defaultRedirect) => {
  let sanitizedPath;
  const path = `https://${process.env.CP_URL}`;
  const wwwPath = `https://www.${process.env.CP_URL}`

  try {
    const url = new URL(redirectTo, path);
    sanitizedPath = (url.origin !== path && url.origin !== wwwPath) ? defaultRedirect : url.pathname;
  } catch {
    sanitizedPath = defaultRedirect;
  }
  return sanitizedPath;
};

/**
 * @function getDtoneTxRequiredFields
 * @description It will return transaction object as string that includes only required fields, 
 * if any of this required fields changes it will consider as new transaction
 * @param dtTx - dtone transaction which has all the attributes
 */
export const getDtoneTxRequiredFields = (dtTx) => {
  if (!dtTx) return null;

  try {
    const requiredValues = pick(dtTx, ['service_type', 'sub_service_type', 'product.id', 'payment_type', 'card', 'recipient_details']);
    return JSON.stringify(requiredValues);
  } catch {
    return null;
  }
}