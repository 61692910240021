import React from 'react'

const CloseIcon = ({ height=20, width=20, color="#1160F7"}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="https://www.w3.org/2000/svg">
            <path d="M6.33327 6.33337L16.0011 16.0012M16.0011 16.0012L25.669 25.6691M16.0011 16.0012L25.669 6.33337M16.0011 16.0012L6.33325 25.6691" stroke={color} strokeWidth="2.66667" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default CloseIcon