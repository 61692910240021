/* eslint-disable no-undef */
export const GA_TRACKING_ID = 'GTM-WP9Q2P'
export const GADS_TRACKING_ID = 'AW-944151606'

// const actions = {
//   'signup': 'TJMbCMzGiZIBELa4msID',
//   'transaction': '_zQICOHnk5IBELa4msID',
// }

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = url => {
  window.gtag('config', GA_TRACKING_ID, {
    page_path: url,
  })
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = ({ action, category, label, value }) => {
  window.gtag('event', action, {
    event_category: category,
    event_label: label,
    value: value,
  })
}

// export const gtmTracker = <>
//   <script async src="https://www.googletagmanager.com/gtag/js?id=AW-944151606"></script>
//   <script dangerouslySetInnerHTML={{
//     __html: `function gtag(){dataLayer.push(arguments)}window.dataLayer=window.dataLayer||[],gtag("js",new Date),gtag("config","AW-944151606",{page_path:window.location.pathname})`,
//   }}/>
// </>

// // Signup conversion
// export const gtagReportSignup = function() {
//   return new Promise(resolve => {
//     if(gtag) {
//       gtag('event', 'conversion', {
//           'send_to': 'AW-944151606/TJMbCMzGiZIBELa4msID',
//           'event_callback': resolve
//       });
//     } else resolve();
//   })
// }
// // Transaction conversion
// export const gtagReportTransaction = function() {
//   return new Promise(resolve => {
//     if(gtag) {
//       gtag('event', 'conversion', {
//           'send_to': 'AW-944151606/_zQICOHnk5IBELa4msID',
//           'transaction_id': '',
//           'event_callback': resolve
//       });
//     } else resolve();
//   })
// }

