export const FB_PIXEL_ID = process.env.FB_ACCOUNT_ID;

export const pageview = () => {
  if(!(window && window.fbq)) return;
  window.fbq("track", "PageView");
};

// https://developers.facebook.com/docs/facebook-pixel/advanced/
export const event = (name, options = {}) => {
  if(!(window && window.fbq)) return;
   window.fbq("track", name, options);
};