const https = require('https');

const fetchDataFromApiServer = async (urlPath) => {
    const isStagingEnv = process.env.REACT_APP_ENV === 'staging';
    let stagingProps = {};
    if (isStagingEnv) {
        stagingProps = {
            agent: new https.Agent({
                rejectUnauthorized: false,
            }),
        };
    }

    let url = `${process.env.REACT_APP_API}${urlPath}`;
    const config = {
        method: 'GET',
        ...stagingProps,
    };
    const res = await fetch(url, config);
    const data = await res.json();
    return data;
};

module.exports = {
    fetchDataFromApiServer
}