import { Hidden, IconButton } from '@mui/material';
import Box from '@mui/material/Box';
import SnackbarContent from '@mui/material/SnackbarContent';
// import Icon from '@mui/material/Icon';
import CloseIcon from '@mui/icons-material/Close';
import clsx from 'clsx';
import {
    arrayOf, bool, func, node, number, oneOf, oneOfType, shape, string
} from 'prop-types';
import React from 'react';
import ErrorAlertIcon from './Icons/ErrorAlertIcon';
import InfoAlertIcon from './Icons/InfoAlertIcon';
import SuccessAlertIcon from './Icons/SuccessAlertIcon';
import WarningAlertIcon from './Icons/WarningAlertIcon';

const variantIcon = {
  success: SuccessAlertIcon,
  warning: WarningAlertIcon,
  error: ErrorAlertIcon,
  info: InfoAlertIcon,
};

const classes = {
  success: {
    backgroundColor: '#DAF4DB',
    border: '1px solid #2EBC31',
    color: '#318133',
    boxShadow: 'none',
  },
  error: {
    backgroundColor: '#F7E3E4',
    border: '1px solid #FF4B55',
    color: '#D52C36',
    boxShadow: 'none',
  },
  info: {
    backgroundColor: '#EEEFF4',
    border: '1px solid #CACCD5',
    color: '#6F7289',
    boxShadow: 'none',
  },
  warning: {
    backgroundColor: '#FBF3DC',
    border: '1px solid #FAC948',
    color: '#AB8D3C',
    boxShadow: 'none',
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
};

const Notification = ({
  onSnackbarClose,
  variant,
  message,
  hiddenActionIcon,
  contentGrid,
  snackbarStyles,
  icon,
  showIcon,
  hiddenGridActionIcon,
}) => {
  const Icon = variantIcon[variant];
  let actionIcon = (
    <Box item >
      <IconButton key="close" aria-label="close" color="inherit" onClick={onSnackbarClose}>
        <CloseIcon className={clsx(classes.icon)} />
      </IconButton>
    </Box>
  );
  if (hiddenGridActionIcon) actionIcon = null;

  let messageContent = (
    <Box 
      display="flex" 
      flexDirection="row"
      justifyContent="center"
      alignItems="center" >
      <Hidden smDown={!showIcon} >
        <Box item>       
          <IconButton
            disableRipple
            color="inherit"
            edge="start"
            disableFocusRipple
            disabled
          >
            {icon ? 
            <img src={icon} alt={icon} width='24px' height='24px'></img>
            :
            <Icon />}
          </IconButton>
        </Box>
      </Hidden>
      <Box item flexGrow={1}>
        <span id="snackbar-message-id">
          {message}
        </span>
      </Box>
      {actionIcon}
    </Box>
  );

  if (hiddenActionIcon) actionIcon = null;

  if (!contentGrid) {
    messageContent = (
      <Box
        container
        id="client-snackbar"
        className={clsx(classes.message)}
        direction="row"
        justify="center"
        alignItems="center"
      >
        <Icon styles={{ ...classes.icon, ...classes.iconVariant }} />
        {message}
      </Box>
    );
  }

  const messageContainer = (<span>{messageContent}</span>);

  return (
    <SnackbarContent
      style={{ ...classes[variant], ...snackbarStyles }}
      aria-describedby="client-snackbar"
      message={messageContainer}
      action={[actionIcon]}
    />
  );
};

Notification.propTypes = {
  variant: oneOf(['error', 'info', 'success', 'warning']),
  hiddenActionIcon: bool,
  message: oneOfType([
    arrayOf(node),
    node,
    string,
    number,
  ]),
  snackbarStyles: shape({}),
  onSnackbarClose: func,
  contentGrid: bool,
  hiddenGridActionIcon: bool,
};

Notification.defaultProps = {
  variant: 'info',
  hiddenActionIcon: true,
  message: '',
  onSnackbarClose: null,
  contentGrid: true,
  snackbarStyles: {},
  hiddenGridActionIcon: false,
};

export default Notification;
