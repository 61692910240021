import React from "react";
import { Snackbar } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const CustomSnackbar = ({ message, handleClose, position }) => {
    return (<Snackbar
        anchorOrigin={position}
        message={message}
        open
        style={{
            marginTop: '5px',
            position: 'absolute'
        }}
        ContentProps={{
            style: {
                background: '#000000',
                fontSize: '12px'
            }
        }}
        action={
            <>
                <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                    <CloseIcon fontSize="small" />
                </IconButton>
            </>
        }
    />)
}

export default CustomSnackbar;