var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"rb-build"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from "@sentry/nextjs";

Sentry.init({
    dsn: "https://d6a5df7638e2498a9f3eda5db06af737@o4504927835455488.ingest.sentry.io/4504927839125504",
    // Set tracesSampleRate to 1.0 to capture 100% 
    // of transactions for performance monitoring. 
    // We recommend adjusting this value in production 
    tracesSampleRate: process.env.REACT_APP_ENV !== "prod" ? 0 : 0.1,
    // replaysSessionSampleRate: 0.1,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 0.25,
    environment: process.env.REACT_APP_ENV,
    // filter unhandled rejection from 3rd parties
    ignoreErrors: [
        "Non-Error exception captured",
        "Non-Error promise rejection captured",
        "Failed to read the 'contentDocument' property from",
        `Blocked a frame with origin "https://www.remitbee.com" from accessing a cross-origin frame`, //Opera issue
        "UET is not defined", //Bing blocked by user
        "Failed to execute 'decodeAudioData' on 'BaseAudioContext'", // Crisp audio decoding issue
    ],
    denyUrls: [
        'recaptcha',
        'gtag/js',
        'clarity',
        'maps-api-v3',
        'fbevents',
        'googlesyndication' //requests failing for users with active ad blocker
    ],
    integrations: [
        new Sentry.Replay({
            networkDetailAllowUrls: [
                window.location.origin,
                'api.remitbee.com',
                'googlesyndication.com'
            ],
        }),
    ],
    beforeSend(event, hint) {
        const error = hint.originalException;
        if (
            error &&
            error.message &&
            error.message.includes('Minified React error')
        ) {
            return null;
        }
        return event;
    },
});