export const SET_COUNTRY_ID = 'SET_COUNTRY_ID';
export const SET_COUNTRY_CODE = 'SET_COUNTRY_CODE';
export const SET_COUNTRY_CALLING_CODE = 'SET_COUNTRY_CALLING_CODE';
export const SET_RECIPIENT_PHONE_NUMBER = 'SET_RECIPIENT_PHONE_NUMBER';
export const SET_SELECTED_OPERATOR = 'SET_SELECTED_OPERATOR';
export const SET_AVAILABLE_OPERATORS = 'SET_AVAILABLE_OPERATORS';
export const SET_PRODUCTS = 'SET_PRODUCTS';
export const SET_SELECTED_PRODUCT = 'SET_SELECTED_PRODUCT';
export const SET_PRODUCT_AMOUNT = 'SET_PRODUCT_AMOUNT';
export const SET_REQUIRED_FIELDS = 'SET_REQUIRED_FIELDS';
export const SET_REQ_FIELD_INFOS = 'SET_REQ_FIELD_INFOS';
export const SET_ACTIVE_TAB = 'SET_ACTIVE_TAB';
export const SET_NOT_IN_RANGE_MSG = 'SET_NOT_IN_RANGE_MSG';
export const SET_CONVERSION_LOADING = 'SET_CONVERSION_LOADING'
export const CLEAR_MOBILE_RC_STATE_DT = 'CLEAR_MOBILE_RC_STATE_DT';
export const SET_DEFAULT_COUNTRY_MOB = 'SET_DEFAULT_COUNTRY_MOB'