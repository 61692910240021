import transactionProcessingStatuses from '../../shared/const/transactionProcessingStatuses';
import {
  SET_TRANSFER_PURPOSE,
  SET_TRANSFER_AMOUNT,
  SET_RECEIVING_AMOUNT,
  SET_PAYMENT_TYPE,
  SET_SETTLEMENT_TYPE,
  SET_FUNDING_TIME,
  SET_SPECIAL_RATE_TYPE,
  SET_TRANSFER_RATE,
  SET_TRANSFER_RATE_SPECIAL,
  SET_TRANSACTION_UNIQUE_ID,
  SET_TRANSACTION_PROCESSING_INFO,
  SET_DESTINATION_COUNTRY_CURRENCY_CODE,
  SET_TRANSFER_COMMISSION,
  CLEAR_TRAN_STATE,
  CLEAR_STATE,
  CLEAR_PAYMENT_OPTION,
  SET_CARD_INFO,
  SET_REWARDS_AMOUNT,
  SET_ADD_RECIPIENT_FLOW,
  SET_WALLET_BALANCE_APPLIED,
  SET_EFT_INFO,
  SET_INSTANT_SPEED,
  SET_TRANSFER_RATE_ID,
  SET_FLINKS_LOGS_ID,
  SET_FLINKS_LOGIN_ID,
  SET_TRACK_TRANSACTION,
  SET_LOADING,
  SET_ERROR,
  SET_TRACK_TOKEN,
  SET_TRACK_URL,
  SET_MONERIS_CHALLANGE_PARAMS,
  SET_CARD_TYPE,
  SET_CUSTOM_FILTER
} from './actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
  transaction_unique_id: '',
  transaction_purpose: '',
  transfer_amount: '',
  final_transfer_rate: '',
  special_rate: null,
  special_rate_transfer_amount_limit: null,
  final_transfer_commission: '',
  receiving_amount: '',
  payment_type: '',
  settlement_time: '',
  funding_time: '',
  special_rate_type: '',
  destination_country_currency_code: '',
  rewards_amount: '',
  wallet_balance_applied: null,
  card: {},
  eft: null,
  add_recipient_flow: false,
  is_instant_speed: false,
  rate_id: null,
  transaction_processing_info: {
    status: transactionProcessingStatuses.NOT_INITIATED,
    success_dialog_open: false,
    failed_dialog_open: false,
    exchange_success_dialog_open: false,
    required_level_to_upgrade: null,
    debit_card_error: null,
  },
  loading: false,
  errorMessage: null,
  trackToken: null,
  trackUrl: null,
  track: {},
  transactionCustomFilters: null
};

const setInstantSpeed = (state, { is_instant_speed }) => updateObject(state, { is_instant_speed });
const setTransferRateId = (state, { rate_id }) => updateObject(state, { rate_id });

const transactionReducers = (state = initialState, action) => {
  switch (action.type) {
    case SET_TRANSACTION_UNIQUE_ID:
      return { ...state, transaction_unique_id: action.payload };
    case SET_TRANSFER_PURPOSE:
      return { ...state, transaction_purpose: action.payload };
    case SET_TRANSFER_AMOUNT:
      return { ...state, transfer_amount: action.payload };
    case SET_PAYMENT_TYPE:
      return { ...state, payment_type: action.payload };
    case SET_SETTLEMENT_TYPE:
      return { ...state, settlement_time: action.payload };
    case SET_FUNDING_TIME:
      return { ...state, funding_time: action.payload };
    case SET_SPECIAL_RATE_TYPE:
      return { ...state, special_rate_type: action.payload };
    case SET_TRANSFER_RATE:
      return { ...state, final_transfer_rate: action.payload };
    case SET_TRANSFER_RATE_SPECIAL:
      return { ...state, ...action.payload };
    case SET_RECEIVING_AMOUNT:
      return { ...state, receiving_amount: action.payload };
    case SET_TRANSFER_COMMISSION:
      return { ...state, final_transfer_commission: action.payload || '0' };
    case SET_DESTINATION_COUNTRY_CURRENCY_CODE:
      return { ...state, destination_country_currency_code: action.payload };
    case SET_CARD_INFO:
      return { ...state, card: action.payload };
    case SET_EFT_INFO:
      return { ...state, eft: action.payload };
    case SET_REWARDS_AMOUNT:
      return { ...state, rewards_amount: action.payload };
    case SET_ADD_RECIPIENT_FLOW:
      return { ...state, add_recipient_flow: action.payload };
    case SET_TRANSACTION_PROCESSING_INFO:
      return {
        ...state,
        transaction_processing_info: { ...state.transaction_processing_info, ...action.payload }
      };
    case SET_WALLET_BALANCE_APPLIED:
      return { ...state, wallet_balance_applied: action.payload };
    case CLEAR_TRAN_STATE:
    case CLEAR_STATE:
      return initialState;
    case CLEAR_PAYMENT_OPTION:
      return { ...state, final_transfer_commission: '', payment_type: '', settlement_time: '' };
    case SET_INSTANT_SPEED:
      return setInstantSpeed(state, action.payload);
    case SET_TRANSFER_RATE_ID:
      return setTransferRateId(state, action.payload);
    case SET_FLINKS_LOGS_ID:
      return { ...state, flinks_logs_id: action.payload };
    case SET_FLINKS_LOGIN_ID:
      return { ...state, flinks_login_id: action.payload };
    case SET_MONERIS_CHALLANGE_PARAMS:
      return { ...state, challenge_params: action.payload };
    case SET_TRACK_TRANSACTION:
      return { ...state, track: action.payload, loading: false };
    case SET_LOADING:
      return { ...state, loading: action.payload };
    case SET_TRACK_TOKEN:
      return { ...state, trackToken: action.payload };
    case SET_TRACK_URL:
      return { ...state, trackUrl: action.payload, loading: false };
    case SET_ERROR:
      return { ...state, errorMessage: action.payload, loading: false };
    case SET_CARD_TYPE:
      return { ...state, cardType: action.payload}
    case SET_CUSTOM_FILTER:
      return { ...state, transactionCustomFilters: action.payload }
    default:
      return state;
  }
};

export default transactionReducers;