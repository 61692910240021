import {
  SET_ORIGINAL_COUNTRY_ID,
  SET_CONVERTED_COUNTRY_ID,
  SET_EXCHANGE_AMOUNT,
  SET_CONVERTED_AMOUNT,
  SET_EXCHANGE_RATE,
  SET_PROMO_RATE,
  SET_SPECIAL_RATE,
  SET_ORIGINAL_CURRENCY_EFT_INFO_ID,
  SET_CONVERTED_CURRENCY_EFT_INFO_ID,
  SET_ORIGINAL_CURRENCY_FLINKS_LOGIN_ID,
  SET_CONVERTED_CURRENCY_FLINKS_LOGIN_ID,
  SET_ORIGINAL_CURRENCY_FLINKS_LOGS_ID,
  SET_CONVERTED_CURRENCY_FLINKS_LOGS_ID,
  SET_ORIGINAL_CURRENCY_EFT_INFO,
  SET_CONVERTED_CURRENCY_EFT_INFO,
  CLEAR_EXCHANGE_STATE,
  SET_CURRENCY_EXCHANGE_PROCESSING_INFO,
  SET_PAYMENT_BANK_INFO,
  SET_DEPOSIT_BANK_INFO,
  SET_EXCHANGE_TRANSFER_PURPOSE,
  SET_EXCHANGE_FLINKS_DEPOSIT_REQUEST,
  SET_EXCHANGE_FLINKS_PAYMENT_REQUEST,
  SET_CURRENCY_EXCHANGE_MANUAL_PAYMENT_FLOW,
  SET_DEPOSIT_DATE
} from './actionTypes';

const initialState = {
  originalCountryId: 40,
  convertedCountryId: 236,
  exchangeAmount: 0,
  convertedAmount: null,
  exchangeRate: null,
  promoRate: null,
  specialRate: null,
  specialRateTransferAmountLimit: null,
  originalCurrencyEftInfoId: null,
  convertedCurrencyEftInfoId: null,
  originalCurrencyFlinksLoginId: null,
  convertedCurrencyFlinksLoginId: null,
  originalCurrencyFlinksLogsId: null,
  convertedCurrencyFlinksLogsId: null,
  originalCurrencyEftInfo: null,
  convertedCurrencyEftInfo: null,
  paymentBankInfo: {},
  depositBankInfo: {},
  currencyExchangeProcessingInfo: {},
  totalFlinksDepositRequests: 0,
  totalFlinksPaymentRequests: 0,
  transferPurpose: '',
  includeManualEftPaymentFlow: false,
  deposit_date: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ORIGINAL_COUNTRY_ID:
      return { ...state, originalCountryId: action.payload };
    case SET_CONVERTED_COUNTRY_ID:
      return { ...state, convertedCountryId: action.payload };
    case SET_EXCHANGE_AMOUNT:
      return { ...state, exchangeAmount: action.payload };
    case SET_CONVERTED_AMOUNT:
      return { ...state, convertedAmount: action.payload };
    case SET_EXCHANGE_RATE:
      return { ...state, exchangeRate: action.payload };
    case SET_PROMO_RATE:
      return { ...state, promoRate: action.payload };
    case SET_SPECIAL_RATE:
      return { ...state, specialRate: action.payload.specialRate, specialRateTransferAmountLimit: action.payload.specialRateTransferAmountLimit };
    case SET_ORIGINAL_CURRENCY_EFT_INFO_ID:
      return { ...state, originalCurrencyEftInfoId: action.payload };
    case SET_CONVERTED_CURRENCY_EFT_INFO_ID:
      return { ...state, convertedCurrencyEftInfoId: action.payload };
    case SET_ORIGINAL_CURRENCY_FLINKS_LOGIN_ID:
      return { ...state, originalCurrencyFlinksLoginId: action.payload };
    case SET_CONVERTED_CURRENCY_FLINKS_LOGIN_ID:
      return { ...state, convertedCurrencyFlinksLoginId: action.payload };
    case SET_ORIGINAL_CURRENCY_FLINKS_LOGS_ID:
      return { ...state, originalCurrencyFlinksLogsId: action.payload };
    case SET_CONVERTED_CURRENCY_FLINKS_LOGS_ID:
      return { ...state, convertedCurrencyFlinksLogsId: action.payload };  
    case SET_ORIGINAL_CURRENCY_EFT_INFO:
      return { ...state, originalCurrencyEftInfo: action.payload };
    case SET_CONVERTED_CURRENCY_EFT_INFO:
      return { ...state, convertedCurrencyEftInfo: action.payload };    
    case CLEAR_EXCHANGE_STATE:
      return { ...state, ...initialState };
    case SET_CURRENCY_EXCHANGE_PROCESSING_INFO:
      return { ...state, currencyExchangeProcessingInfo: action.payload };
    case SET_PAYMENT_BANK_INFO:
      return { ...state, paymentBankInfo: action.payload };
    case SET_DEPOSIT_BANK_INFO:
      return { ...state, depositBankInfo: action.payload };
    case SET_EXCHANGE_TRANSFER_PURPOSE:
      return { ...state, transferPurpose: action.payload }
    case SET_EXCHANGE_FLINKS_DEPOSIT_REQUEST:
      return { ...state, totalFlinksDepositRequests: state ? state.totalFlinksDepositRequests + 1 : 1 };
    case SET_EXCHANGE_FLINKS_PAYMENT_REQUEST:
      return { ...state, totalFlinksPaymentRequests: state ? state.totalFlinksPaymentRequests + 1 : 1 };
    case SET_CURRENCY_EXCHANGE_MANUAL_PAYMENT_FLOW:
      return { ...state, includeManualEftPaymentFlow: action.payload }
    case SET_DEPOSIT_DATE: {
      return { ...state, deposit_date: action.payload }
    }
    default:
      return state;
  }
};

export default reducer;