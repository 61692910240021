export const SET_BUSINESS_ACC = 'SET_BUSINESS_ACC';
export const SET_PERSONAL_ACC = 'SET_PERSONAL_ACC';
export const SET_BUSINESS_COMPLIANCE = 'SET_BUSINESS_COMPLIANCE';
export const TOGGLE_BUSINESS_VERIFICATION = 'TOGGLE_BUSINESS_VERIFICATION';
export const SET_EDIT_ID = 'SET_EDIT_ID';
export const SET_OWNER_DIRECTOR = 'SET_OWNER_DIRECTOR';
export const SET_BUSINESS_ID = 'SET_BUSINESS_ID';
export const SET_PROMO_STATUS = 'SET_PROMO_STATUS';
export const SET_IS_MSB = 'SET_IS_MSB';
export const SET_FLOW = 'SET_FLOW';
export const CLEAR_STATE = 'CLEAR_STATE';
export const SET_CURRENT_FLOW = 'SET_CURRENT_FLOW';
export const SET_BUSINESS_UNIQUE_ID = 'SET_BUSINESS_UNIQUE_ID';
export const SET_BUSINESS_INFO = 'SET_BUSINESS_INFO';
export const SET_BUSINESS_REDIRECT_ROUTE = 'SET_BUSINESS_REDIRECT_ROUTE';
export const SET_OWNER_DIRECTOR_INFO = 'SET_OWNER_DIRECTOR_INFO';
export const SET_OWNER_DIRECTOR_FORM_DATA = 'SET_OWNER_DIRECTOR_FORM_DATA';
export const SET_OWNER_DIRECTOR_FORM_STATUS = 'SET_OWNER_DIRECTOR_FORM_STATUS';
export const SET_OWNERSHIP_INFO = 'SET_OWNERSHIP_INFO';
export const SET_DIRECTORSHIP_INFO = 'SET_DIRECTORSHIP_INFO';
export const SET_DIRECTOR_LIST_TYPE = 'SET_OWNER_DIRECTOR_LIST_TYPE';
export const SET_OWNER_LIST_TYPE = 'SET_OWNER_LIST_TYPE';
export const SET_OWNER_LIST = 'SET_OWNER_LIST';
export const SET_DIRECTOR_LIST = 'SET_DIRECTOR_LIST';
export const SET_DIRECTOR_FORM_STATUS = 'SET_DIRECTOR_FORM_STATUS';
export const SET_DIRECTOR_FORM_DATA = 'SET_DIRECTOR_FORM_DATA';
export const SET_DIRECTOR_UPLOAD_METHOD = 'SET_DIRECTOR_UPLOAD_METHOD';

