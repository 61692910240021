import transactionProcessingStatuses from '../../shared/const/transactionProcessingStatuses';
import {
    SET_WALLET_DEPOSIT_TRANSACTION_UNIQUE_ID,
    SET_WALLET_DEPOSIT_AMOUNT,
    SET_WALLET_DEPOSIT_PAYMENT_TYPE,
    SET_WALLET_DEPOSIT_EFT_INFO,
    SET_WALLET_DEPOSIT_TRANSACTION_PROCESSING_INFO,
    CLEAR_WALLET_DEPOSIT_TRAN_STATE,
    CLEAR_WALLET_DEPOSIT_PAYMENT_OPTION,
    CLEAR_WALLET_DEPOSIT_STATE,
    SET_WALLET_DEPOSIT_FLINKS_LOGS_ID,
    SET_WALLET_DEPOSIT_FLINKS_LOGIN_ID
} from './actionTypes'

const initialState = {
    wallet_deposit_transaction_unique_id: '',
    wallet_deposit_amount: '',
    wallet_deposit_payment_type: '',
    wallet_deposit_eft: null,
    wallet_deposit_transaction_processing_info: {
        status: transactionProcessingStatuses.NOT_INITIATED,
        success_dialog_open: false,
        failed_dialog_open: false,
        required_level_to_upgrade: null,
        payment_type: null,
        bank_portal_link: 'https://www1.bmo.com/onlinebanking/cgi-bin/netbnx/NBmain?product=5'
    },
};

const walletDepositTransaction = (state = initialState, action) => {
    switch (action.type) {
        case SET_WALLET_DEPOSIT_TRANSACTION_UNIQUE_ID:
            return { ...state, wallet_deposit_transaction_unique_id: action.payload };
        case SET_WALLET_DEPOSIT_AMOUNT:
            return { ...state, wallet_deposit_amount: action.payload };
        case SET_WALLET_DEPOSIT_PAYMENT_TYPE:
            return { ...state, wallet_deposit_payment_type: action.payload };
        case SET_WALLET_DEPOSIT_EFT_INFO:
            return { ...state, wallet_deposit_eft: action.payload };
        case SET_WALLET_DEPOSIT_TRANSACTION_PROCESSING_INFO:
            return {
                ...state,
                wallet_deposit_transaction_processing_info: { ...state.wallet_deposit_transaction_processing_info, ...action.payload }
            };
        case CLEAR_WALLET_DEPOSIT_TRAN_STATE:
            return { ...initialState, wallet_deposit_transaction_processing_info: { ...state.wallet_deposit_transaction_processing_info}  };
        case CLEAR_WALLET_DEPOSIT_PAYMENT_OPTION:
            return { ...state, payment_type: '' };
        case CLEAR_WALLET_DEPOSIT_STATE:
            return initialState;
        case SET_WALLET_DEPOSIT_FLINKS_LOGS_ID:
            return { ...state, wallet_deposit_flinks_logs_id: action.payload };
        case SET_WALLET_DEPOSIT_FLINKS_LOGIN_ID:
            return { ...state, wallet_deposit_flinks_login_id: action.payload };
        default: return state
    }
}

export default walletDepositTransaction;