const braze_property = [
    'account_type',
    'service_selected',
    'country',
    'currency',
    'amount',
    'receiving_method',
    'first_name',
    'last_name',
    'dob',
    'address',
    'city',
    'province',
    'occupation',
    'purpose',
    'preferred_bank',
    'payment_method',
    'tran_id',
    'rewards_amount',
    'rewards_promocode',
    'connection_type',
    'ce_id',
    'value',
    'phone_number',
    'bill_type',
    'utility_provider',
    'proof_of_address',
    'proof_of_identity',
    'solo_owner',
    'solo_director',
    'is_compatiable',
    'destination',
]

export default braze_property;