export default {
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    SIGN_UP_SUCCESS: 'SIGN_UP_SUCCESS',
    CUSTOMER_UPDATED: 'CUSTOMER_UPDATED',
    CUSTOMER_PHONE_UPDATED: 'CUSTOMER_PHONE_UPDATED',
    CUSTOMER_EMPLOYMENT_UPDATED: 'CUSTOMER_EMPLOYMENT_UPDATED',
    SET_COMPLIANCE: 'SET_COMPLIANCE',
    PROMO_REDIRECT: 'PROMO_REDIRECT',
    IS_BRAZE_ENABLED: 'IS_BRAZE_ENABLED',
    SET_BRAZE_CONTENT_CARDS: 'SET_BRAZE_CONTENT_CARDS',
    CLEAR_STATE: 'CLEAR_STATE' ,
    SET_CUSTOMER_UNIQ_ID: 'SET_CUSTOMER_UNIQUE_ID',
}