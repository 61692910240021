import actionTypes from './actionTypes'
const { fetchDataFromApiServer }  = require('../../shared/apiServerRequestHandler')

export const getRewardsAmount =  async (dispatch) => {
    const result = await fetchDataFromApiServer('/public-services/rc-rewards-amount');
    dispatch({
        type: actionTypes.RC_REWARDS_SUCCESS,
        payload: result
    });

    return result;
};
