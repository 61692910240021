import React, { createContext, useEffect, useState } from 'react';

const ReferralStoreContext = createContext();

const ReferralProvider = ({ initialState, children }) => {
  let [referralState, setReferral] = useState();

  const setReferralState = (element) => {
    setReferral(element);
  };

  useEffect(() => {
    if (typeof localStorage.getItem('refer') === 'string') {
      setReferral(localStorage.getItem('refer'));
    }
    if (initialState) {
      setReferral(initialState);
    }
  }, []);
  
  useEffect(() => {
    if (referralState) {
      localStorage.setItem('refer', referralState);
    }
  }, [referralState]);

  const cleanReferralLocalStorage = () => {
    localStorage.removeItem('refer');
  }


  return (
    <ReferralStoreContext.Provider value={{ referralState, setReferralState, cleanReferralLocalStorage }}>
      {children}
    </ReferralStoreContext.Provider>
  )
};

export { ReferralStoreContext, ReferralProvider };
