export const SET_COUNTRY_ID_INT = 'SET_COUNTRY_ID_INT';
export const SET_SUB_SERVICE_ID_INIT = 'SET_SUB_SERVICE_ID_INIT';
export const SET_PROVIDER_ID = 'SET_PROVIDER_ID';
export const SET_LIST_OF_PRODUCTS_INT = 'SET_LIST_OF_PRODUCTS_INT';
export const SET_REQ_FIELDS_INT = 'SET_REQ_FIELDS_INT';
export const SET_SELECTED_PLAN_INT = 'SET_SELECTED_PLAN_INT';
export const SET_SERVICE_ID_INT = 'SET_SERVICE_ID_INT';
export const SET_REQ_FIELD_INFO_INT = 'SET_REQ_FIELD_INFO_INT';
export const SET_CUSTOM_AMNT_INT = 'SET_CUSTOM_AMNT_INT'
export const CLEAR_SUB_SERVICE_INT = 'CLEAR_SUB_SERVICE_INT';
export const CLEAR_PRODUCTS_INFO_INT = 'CLEAR_PRODUCTS_INFO_INT';
export const CLEAR_OPERATOR_INFO_INT = 'CLEAR_OPERATOR_INFO_INT';
export const CLEAR_ENTIRE_STATE_INT = 'CLEAR_ENTIRE_STATE_INT';
export const SET_DEFAULT_COUNTRY_INT = 'SET_DEFAULT_COUNTRY_INT'
