import { bool, number, string } from 'prop-types';
import React from 'react';

const WarningAlertIcon = ({
  width,
  height,
  padding,
  opacity,
}) => {

  return (
    <svg id="Group_3281" data-name="Group 3281" xmlns="https://www.w3.org/2000/svg" width={width} height={height} style={{ margin: '0 10px 0 0' }} padding={padding} opacity={opacity} viewBox="0 0 22 22">
      <circle id="Ellipse_390" data-name="Ellipse 390" cx="11" cy="11" r="11" fill="rgba(250,201,72,0.42)" />
      <g id="warning" transform="translate(3.206 3.206)">
        <g id="Group_527" data-name="Group 527" transform="translate(0 0)">
          <path id="Path_1323" data-name="Path 1323" d="M7.794,0a7.794,7.794,0,1,0,7.794,7.794A7.8,7.8,0,0,0,7.794,0Zm0,0" fill="#fac948" />
          <path id="Path_1324" data-name="Path 1324" d="M235.967,363.317a.649.649,0,1,1-.649-.649A.649.649,0,0,1,235.967,363.317Zm0,0" transform="translate(-227.523 -351.626)" fill="#eceff1" />
          <path id="Path_1325" data-name="Path 1325" d="M235.967,112.514a.649.649,0,0,1-1.3,0v-5.2a.649.649,0,0,1,1.3,0Zm0,0" transform="translate(-227.523 -103.42)" fill="#fafafa" />
        </g>
      </g>
    </svg>
  )
}

WarningAlertIcon.propTypes = {
  width: number,
  height: number,
  warning: bool,
  padding: string,
  opacity: string,
};

WarningAlertIcon.defaultProps = {
  width: 22,
  height: 22,
  padding: '0',
  opacity: '1',
};

export default WarningAlertIcon;