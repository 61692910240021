import {
  SET_RECEIVING_METHOD,
  SET_BEN_PAYMENT_METHOD,
  SET_BEN_PAYMENT_METHOD_OPTION,
  SET_BEN_PAYMENT_METHOD_DETAILS,
  SET_BEN_PAYMENT_OPTION_GROUP,
  CLEAR_BEN_STATE,
  CLEAR_RECEIVING_METHOD,
  SET_BEN_COUNTRY_ID,
  SET_BEN_CURRENCY,
  SET_OPEN_REQUEST_DIALOG,
  FETCH_BENEFICIARY,
  UPDATE_HAS_ONLY_PRIORITY,
  UPDATE_BENEFICIARY_PAYMENT_INFOS_LIST,
  ADD_ADDITIONAL_FIELDS_CHILD,
  SET_RECIPIENT_STATUS,
  SET_BENEFICIARY_GROUP,
  SET_ADD_NEW_RECIPIENT_FORM,
  SET_RECIPIENT_SUCCESS_DIALOG,
  SET_RECIPIENT_CURRENT_FLOW,
  SET_RECIPIENT_DEL_SUCCESS_DIALOG
} from './actionTypes';
import authActionTypes from '../auth/actionTypes';

import { updateObject } from '../../shared/utility';


const initialState = {
  benCountryId: null,
  benCurrency: null,
  hasOnlyPriority: false,
  loading: false,
  receivingMethod: {},
  benCountryPlatform: null,
  paymentOptionGroup: 'FIRST',
  beneficiaryGroup: 'FIRST',
  beneficiary: {},
  fees: 0.00,
  beneficiary_payment_info: {
    ben_payment_method: null,
    ben_location_id: null,
    ben_bank_id: null,
    ben_bank_processing_method: 'REGULAR',
    ben_brc: null,
    ben_bsb: null,
    ben_bank_account_number: null,
    ben_bank_code_manual: null,
    ben_bank_name_manual: null,
    ben_bank_branch_code_manual: null,
  },
  additionalFieldChildren: [],
  recipientStatus: '',
  requestDialog: false,
  addNewRecipientForm : {},
  recipientSuccessDialog : false,
  currentFlow: null,
  deletedRecipient: null
};

const setBenPaymentMethodOption = (state, { paymentMethodOption }) => {
  return updateObject(state, { paymentMethodOption })
}

const setBenPaymentOptionGroup = (state, { paymentOptionGroup }) => {  
  return updateObject(state, { paymentOptionGroup })
}

const setBeneficiaryGroup = (state, { beneficiaryGroup }) => {  
  return updateObject(state, { beneficiaryGroup })
}

const setBenPaymentMethodDetails = (state, { paymentMethodDetails }) => {
  return updateObject(state, { paymentMethodDetails })
}

const setReceivingMethod = (state, { receivingMethod }) => {
  const receivingMethodKey = `${Object.keys(receivingMethod)[0]}`;
  return updateObject(state, {
    receivingMethod,
    beneficiary_payment_info: updateObject(state.beneficiary_payment_info, {
      ben_payment_method: receivingMethodKey,
    }),
  });
};

const setBenPaymentMethod = (state, { beneficiary_payment_info }) => {
  return updateObject(state, {
    beneficiary_payment_info: updateObject(state.beneficiary_payment_info, beneficiary_payment_info),
  })
};

const setBenCountryId = (state, { benCountryId, platform }) => {
  let benPaymentInfo=initialState.beneficiary_payment_info;
  let receivingMethod=initialState.receivingMethod;
  if(state && state.receivingMethod && state.beneficiary_payment_info && Object.keys(state.beneficiary_payment_info).length && Object.keys(state.receivingMethod).length ){
    benPaymentInfo=state.beneficiary_payment_info;
    receivingMethod=state.receivingMethod;
  }
 return  updateObject(state, {
  benCountryId,
  benCountryPlatform: platform,
  beneficiary_payment_info: benPaymentInfo,
  receivingMethod: receivingMethod,
});
}

const setBenCurrency = (state, { benCurrency }) => updateObject(state, {
  benCurrency
});

const setRecipientStatus = (state, { recipientStatus }) => updateObject(state, {
  recipientStatus
});

const setOpenRequestDialog = (state, { requestDialog }) => updateObject(state, {
  requestDialog
});

const clearState = (state) => updateObject(state, initialState);

const fetchBeneficiary = (state, { beneficiary }) => {
  return updateObject(state, {
    beneficiary,
    benCountryId: beneficiary.ben_country,
  });
}


const updateBeneficiaryPaymentInfosList = (state, { beneficiary_payment_infos }) => updateObject(state, {
  beneficiary: updateObject(state.beneficiary, { beneficiary_payment_infos }),
});

const updateHasOnlyPriority = (state, { hasOnlyPriority }) => updateObject(state, { hasOnlyPriority });

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_RECEIVING_METHOD:
      return setReceivingMethod(state, action.payload);
    case SET_BEN_PAYMENT_METHOD:
      return setBenPaymentMethod(state, action.payload);
    case SET_BEN_PAYMENT_METHOD_OPTION:
      return setBenPaymentMethodOption(state, action.payload);
    case SET_BEN_PAYMENT_OPTION_GROUP:
      return setBenPaymentOptionGroup(state, action.payload);
    case SET_BENEFICIARY_GROUP:
      return setBeneficiaryGroup(state, action.payload);
    case SET_BEN_PAYMENT_METHOD_DETAILS:
      return setBenPaymentMethodDetails(state, action.payload);
    case SET_BEN_COUNTRY_ID:
      return setBenCountryId(state, action.payload);
    case SET_OPEN_REQUEST_DIALOG:
      return setOpenRequestDialog(state, action.payload);  
    case SET_BEN_CURRENCY:
      return setBenCurrency(state, action.payload);
    case SET_RECIPIENT_STATUS:
      return setRecipientStatus(state, action.payload);
    case CLEAR_BEN_STATE:
      return clearState(state);
    case CLEAR_RECEIVING_METHOD:
      return { ...state, receivingMethod: {} };
    case authActionTypes.CLEAR_STATE:
      return clearState(state);
    case FETCH_BENEFICIARY:
      return fetchBeneficiary(state, action.payload);
    case UPDATE_HAS_ONLY_PRIORITY:
      return updateHasOnlyPriority(state, action.payload);
    case UPDATE_BENEFICIARY_PAYMENT_INFOS_LIST:
      return updateBeneficiaryPaymentInfosList(state, action.payload);
    case ADD_ADDITIONAL_FIELDS_CHILD:
      return { ...state, additionalFieldChildren: [...state.additionalFieldChildren, action.payload] };
    case SET_ADD_NEW_RECIPIENT_FORM:
      return { ...state, addNewRecipientForm: action.payload };
    case SET_RECIPIENT_SUCCESS_DIALOG:
      return { ...state, recipientSuccessDialog: action.payload };
    case SET_RECIPIENT_CURRENT_FLOW:
      return { ...state, currentFlow: action.payload };   
    case SET_RECIPIENT_DEL_SUCCESS_DIALOG:
      return { ...state, deletedRecipient: action.payload };     
    default:
      return state;
  }
};

export default reducer;
