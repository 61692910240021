export const SET_BEN_COUNTRY_ID = 'SET_BEN_COUNTRY_ID';
export const SET_BEN_CURRENCY = 'SET_BEN_CURRENCY';
export const SET_RECEIVING_METHOD = 'SET_PAYMENT_METHOD';
export const SET_BEN_PAYMENT_METHOD = 'SET_BEN_PAYMENT_METHOD';
export const SET_BEN_PAYMENT_METHOD_OPTION = 'SET_BEN_PAYMENT_METHOD_OPTION'
export const SET_BENEFICIARY_GROUP = 'SET_BENEFICIARY_GROUP'
export const SET_BEN_PAYMENT_OPTION_GROUP = 'SET_BEN_PAYMENT_OPTION_GROUP'
export const SET_BEN_PAYMENT_METHOD_DETAILS = 'SET_BEN_PAYMENT_METHOD_DETAILS'
export const CLEAR_BEN_STATE = 'CLEAR_BEN_STATE';
export const CLEAR_RECEIVING_METHOD = 'CLEAR_RECEIVING_METHOD';
export const SET_OPEN_REQUEST_DIALOG = 'SET_OPEN_REQUEST_DIALOG';
export const FETCH_BENEFICIARY = 'FETCH_BENEFICIARY';
export const UPDATE_HAS_ONLY_PRIORITY = 'UPDATE_HAS_ONLY_PRIORITY';
export const UPDATE_BENEFICIARY_PAYMENT_INFOS_LIST = 'UPDATE_BENEFICIARY_PAYMENT_INFOS_LIST';
export const ADD_ADDITIONAL_FIELDS_CHILD = 'ADD_ADDITIONAL_FIELDS_CHILD';
export const SET_RECIPIENT_STATUS = 'SET_RECIPIENT_STATUS';
export const SET_ADD_NEW_RECIPIENT_FORM = 'SET_ADD_NEW_RECIPIENT_FORM'
export const SET_RECIPIENT_SUCCESS_DIALOG = 'SET_RECIPIENT_SUCCESS_DIALOG'
export const SET_RECIPIENT_CURRENT_FLOW = 'SET_RECIPIENT_CURRENT_FLOW'
export const SET_RECIPIENT_DEL_SUCCESS_DIALOG = 'SET_RECIPIENT_DEL_SUCCESS_DIALOG'