import { SET_ESCALATION_ISSUE_MSG, SET_ESCALATION_ISSUE_TYPES, SET_ESCALATION_SUB_ISSUE_TYPE, SET_ISSUED_TRANSACTION, SET_ISSUE_ATTACHMENT, CLEAR_ESCALATION_STATE , SET_ESCALATION_RESPONSE, SET_ESCALATION_CLOSED } from './actionTypes';

const initState = {
  issueTypeSelected: null,
  issueSubTypeSelected: null,
  transaction: null,
  attachment: null,
  message: null,
  escalationResponse: null,
  escalationClosed: null
};


const reducer = (state = initState, action) => {
  switch (action.type) {
    case SET_ESCALATION_ISSUE_TYPES:
      return {...state, issueTypeSelected: action.payload}
    case SET_ESCALATION_SUB_ISSUE_TYPE:
      return {...state, issueSubTypeSelected: action.payload}
    case SET_ISSUED_TRANSACTION:
      return {...state, transaction: action.payload}
    case SET_ISSUE_ATTACHMENT:
      return {...state, attachment: action.payload}
    case SET_ESCALATION_ISSUE_MSG:
      return {...state, message: action.payload} 
    case SET_ESCALATION_RESPONSE:
      return {...state, escalationResponse: action.payload}
    case SET_ESCALATION_CLOSED:
      return {...state, escalationClosed: action.payload}
    case CLEAR_ESCALATION_STATE:
      return initState       
    default:
      return state;
  }
};

export default reducer


