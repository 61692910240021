/* eslint-disable no-empty */

/**
 * @function setCookie
 * @description It will set cookie based on values provided.
 * @param {String} cname - cookie name to be set.
 * @param {String} cvalue - cookie value to be set.
 * @param {String} exdays - cookie expiration is days to be set.
 */
const setCookie = (cname, cvalue, exdays) => {
  let d = new Date();
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
  const expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

/**
 * @function deleteCookie
 * @description It will set cookie based on name provided.
 * @param {String} cname - cookie name to be set.
 */
 const deleteCookie = cname => {
  document.cookie = cname + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/';
}

/**
 * @function getCookie
 * @description It will check cookie based on name provided.
 * @param {String} cname - cookie name to search.
 */
const getCookie = (cname) => {
  const name = cname + "=";
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

/**
 * @function saveClientIpCookie
 * @description It will check client_ip address cookie exist, if no then create one.
 * @param {Object} element - Object to be checked.
 */
const saveClientIpCookie = async () => {
  try {
    const cookieName = 'client_ip';
    const cookieNumberOfExpireDays = 30;
    const publicIp = require('public-ip');
    const clientIpCookieValue = getCookie(cookieName)
    const currentClientIp = await publicIp.v4();
    if(clientIpCookieValue !== currentClientIp) setCookie(cookieName, currentClientIp, cookieNumberOfExpireDays);
    //Clear the memory by removing unwanted package
    const name = require.resolve('public-ip');
    delete require.cache[name];
  } catch (err) {

   }
  return true;
}

const getClientCookieAcceptance = async () => {
  let clientCookieAcceptance = null;
  try {
    const cookieName = 'cookie_acceptance';
    clientCookieAcceptance = getCookie(cookieName)
  } catch (err) {

   }
  return clientCookieAcceptance;
}

const saveClientCookieAcceptance = async () => {
  const cookieName = 'cookie_acceptance';
  try {    
    const cookieNumberOfExpireDays = 365;
    const clientCookieAcceptance = getCookie(cookieName);
    if(!clientCookieAcceptance) setCookie(cookieName, true, cookieNumberOfExpireDays);
  } catch (err) {

   }
  return getCookie(cookieName);
}

const setClientHasAccountCookie = async () => {
  try {
    setCookie('has_account', true, 365);
  } catch (err) { 

  }
}

const saveSelectedCountry= async(name, value)=>{
  try {
    setCookie(name, value, 365);
  } catch (err) { 

  }
}

const getSelectedCountry = async () => {
  let clientCookieAcceptance = null;
  try {
    const cookieArray = document.cookie.split(';');
    const selectedCountryCookie = cookieArray.find(cookie => cookie.trim().startsWith('selectedCountryId='));
    return selectedCountryCookie.split('=')[1];
  } catch (err) {

   }
  return clientCookieAcceptance;
}

const getSelectedCurrency= async () => {
  let clientCookieAcceptance = null;
  try {
    const cookieArray = document.cookie.split(';');
    const selectedCountryCookie = cookieArray.find(cookie => cookie.trim().startsWith('selectedCurrencyCode='));
    return selectedCountryCookie.split('=')[1];
  } catch (err) {

   }
  return clientCookieAcceptance;
}

const getClientHasAccountCookie = async () => {
  try {
    const cookie = await getCookie('has_account');
    return cookie;
  } catch (err) {

   }
}

const setClientPersonalDialogCookie = async () => {
  try {
    setCookie('had_checked_dialog', true, 365);
  } catch (err) { 

  }
}

const setClientBusinessDialogCookie = async () => {
  try {
    setCookie('had_checked_business_dialog', true, 365);
  } catch (err) { 

  }
}

const getClientBusinessDialogCookie = async () => {
  try {
    const cookie = await getCookie('had_checked_business_dialog');
    return cookie;
  } catch (err) {

   }
}

const setRedirectSignupCookie = async (page) => {
  try {
    let redirect = page
    if(page === '/') redirect = '/index'
    setCookie('set_redirect_signup', redirect, 365);
  } catch (err) { 

  }
}

const getRedirectSignupCookie = async () => {
  try {
    const cookie = await getCookie('set_redirect_signup');
    return cookie;
  } catch (err) {

   }
}

const getPostAffiliateProCookie = async () => {
  try {
    const cookie = await getCookie('PAPVisitorId');
    return cookie;
  } catch (err) {

   }
}

module.exports = {
  saveClientIpCookie: saveClientIpCookie,
  getClientCookieAcceptance: getClientCookieAcceptance,
  saveClientCookieAcceptance: saveClientCookieAcceptance,
  getClientCookieInfo: getCookie,
  setClientCookieInfo: setCookie,
  deleteClientCookieInfo: deleteCookie,
  setClientHasAccountCookie,
  getClientHasAccountCookie,
  setClientPersonalDialogCookie,
  setClientBusinessDialogCookie,
  getClientBusinessDialogCookie,
  setRedirectSignupCookie,
  getRedirectSignupCookie,
  saveSelectedCountry,
  getSelectedCountry,
  getSelectedCurrency,
  getPostAffiliateProCookie
}