import {
    SET_CANCELLED_TRANSACTION,
    CLEAR_STATE
} from './actionTypes';

const initialState = {
    selected: null
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_CANCELLED_TRANSACTION:
            return { ...state, selected: action.payload };
        case CLEAR_STATE:
            return initialState
        default:
            return state;
    }
};

export default reducer;