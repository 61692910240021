import {
  SET_FLINKS_INFO,
  CLEAN_FLINKS_INFO,
  STATUS_INTEGRATION_STATUS,
  SET_REDEPOSIT,
  UNSET_REDEPOSIT,
} from "./actionTypes";

import customerActionTypes from '../customer/actionTypes'
import { updateObject } from '../../shared/utility';

const initialState = {
  loginId: null,
  institution: null,
  url: null,
  flinksIntegrationStatus: null,
  redepositStatus: {
    dialog: false,
    title: '',
    message: ''
  },
};

const setFlinksInfo = (state, { flinksInfo }) => updateObject(
  state,
  flinksInfo,
);

const setStatusIntegrationStatus = (state, { flinksIntegrationStatus }) => updateObject(
  state,
  { flinksIntegrationStatus }
);

const clearFlinksInfo = (state) => updateObject(state, {
  loginId: null,
  institution: null,
  url: null,
  flinksIntegrationStatus: null,
  redepositDialog: false
});

const setRedepositStatus = (state) => updateObject(state, {
  redepositDialog: {
    dialog: true,
    title: "You're almost there!",
    message: `
    We sent two micro-deposits to your bank account in order to verify it. They should appear within 1-3 business days. Once you receive these deposits, go to your Payment Preference Settings and enter those amounts to complete verification. 
    `
  }
})

const unsetRedepositStatus = (state) => updateObject(state, {
  redepositDialog: {
    dialog: false,
    message: ''
  }
})

const flinksReducers = (state = initialState, action) => {
  switch (action.type) {
    case SET_FLINKS_INFO:
      return setFlinksInfo(state, action.payload);
    case CLEAN_FLINKS_INFO:
      return clearFlinksInfo(state);
    case customerActionTypes.CLEAR_STATE:
      return clearFlinksInfo(state);
    case STATUS_INTEGRATION_STATUS:
      return setStatusIntegrationStatus(state, action.payload);
    case SET_REDEPOSIT:
      return setRedepositStatus(state);
    case UNSET_REDEPOSIT:
      return unsetRedepositStatus(state);
    default:
      return state;
  }
};

export default flinksReducers;