export const SET_WALLET_DEPOSIT_TRANSACTION_UNIQUE_ID = 'SET_WALLET_DEPOSIT_TRANSACTION_UNIQUE_ID'
export const SET_WALLET_DEPOSIT_AMOUNT = 'SET_WALLET_DEPOSIT_AMOUNT'
export const SET_WALLET_DEPOSIT_PAYMENT_TYPE = 'SET_WALLET_DEPOSIT_PAYMENT_TYPE'
export const SET_WALLET_DEPOSIT_EFT_INFO = 'SET_WALLET_DEPOSIT_EFT_INFO'
export const SET_WALLET_DEPOSIT_TRANSACTION_PROCESSING_INFO = 'SET_WALLET_DEPOSIT_TRANSACTION_PROCESSING_INFO'
export const CLEAR_WALLET_DEPOSIT_TRAN_STATE = 'CLEAR_WALLET_DEPOSIT_TRAN_STATE'
export const CLEAR_WALLET_DEPOSIT_PAYMENT_OPTION = 'CLEAR_WALLET_DEPOSIT_PAYMENT_OPTION'
export const CLEAR_WALLET_DEPOSIT_STATE = 'CLEAR_WALLET_DEPOSIT_STATE'
export const SET_WALLET_DEPOSIT_FLINKS_LOGS_ID = 'SET_WALLET_DEPOSIT'
export const SET_WALLET_DEPOSIT_FLINKS_LOGIN_ID = 'SET_WALLET_DEPOSIT_FLINKS_LOGIN_ID'
