import browserLogger from '../../../browser-logger';
import client from "./client";
import { store } from '../../../../redux/store';
import * as Sentry from '@sentry/nextjs';

export const apiMainQuery = (
    query,
    variables,
    fetchPolicy = 'network-only'
) => {
    return new Promise(resolve => {
        let objToBeReturned = { success: true, errorMessage: null, data: null };
        client
            .query({
                variables,
                query,
                fetchPolicy
            })
            .then(({ data }) => {
                objToBeReturned.data = data;
                resolve(objToBeReturned);
            })
            .catch(err => {
                const { customer } = store && store.getState() || {};
                browserLogger.error(
                    err.message,
                    {
                        error: err,
                        function: 'graphqlQuery',
                        cus_unique_id: customer && customer.cus_unique_id
                    },
                );
                Sentry.captureException(err, (scope) => {
                    scope.clear();
                    scope.setContext("additionalData", {
                      cus_unique_id: customer?.cus_unique_id,
                      query: query && query?.definitions && query?.definitions?.length > 0 && query?.definitions[0]['name'] && query?.definitions[0]['name']?.value
                    });
                    return scope;
                });
                objToBeReturned = { ...objToBeReturned, success: false, errorMessage: err.message };
                resolve(objToBeReturned);
            });
    });
};