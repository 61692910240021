export const SET_TRANSACTION_UNIQUE_ID = 'SET_TRANSACTION_UNIQUE_ID';
export const SET_TRANSFER_PURPOSE = 'SET_TRANSFER_PURPOSE';
export const SET_TRANSFER_AMOUNT = 'SET_TRANSFER_AMOUNT';
export const SET_RECEIVING_AMOUNT = 'SET_RECEIVING_AMOUNT';
export const SET_TRANSFER_RATE = 'SET_TRANSFER_RATE';
export const SET_TRANSFER_RATE_SPECIAL = 'SET_TRANSFER_RATE_SPECIAL';
export const SET_TRANSFER_COMMISSION = 'SET_TRANSFER_COMMISSION';
export const SET_DESTINATION_COUNTRY_CURRENCY_CODE = 'SET_DESTINATION_COUNTRY_CURRENCY_CODE';
export const SET_PAYMENT_TYPE = 'SET_PAYMENT_TYPE';
export const SET_SETTLEMENT_TYPE = 'SET_SETTLEMENT_TYPE';
export const SET_FUNDING_TIME = 'SET_FUNDING_TIME';
export const SET_SPECIAL_RATE_TYPE = 'SET_SPECIAL_RATE_TYPE';
export const SET_CARD_INFO = 'SET_CARD_INFO';
export const SET_EFT_INFO = 'SET_EFT_INFO';
export const SET_TRANSACTION_PROCESSING_INFO = 'SET_TRANSACTION_PROCESSING_INFO';
export const SET_REWARDS_AMOUNT = 'SET_REWARDS_AMOUNT';
export const SET_ADD_RECIPIENT_FLOW = 'SET_ADD_RECIPIENT_FLOW';
export const SET_WALLET_BALANCE_APPLIED = 'SET_WALLET_BALANCE_APPLIED';
export const CLEAR_TRAN_STATE = 'CLEAR_TRAN_STATE';
export const CLEAR_PAYMENT_OPTION = 'CLEAR_PAYMENT_OPTION';
export const CLEAR_STATE = 'CLEAR_STATE';
export const SET_INSTANT_SPEED = 'SET_INSTANT_SPEED';
export const SET_TRANSFER_RATE_ID = 'SET_TRANSFER_RATE_ID';
export const SET_FLINKS_LOGS_ID = 'SET_FLINKS_LOGS_ID';
export const SET_FLINKS_LOGIN_ID = 'SET_FLINKS_LOGIN_ID';
export const SET_MONERIS_CHALLANGE_PARAMS = 'SET_MONERIS_CHALLANGE_PARAMS';
export const SET_TRACK_TRANSACTION = 'SET_TRACK_TRANSACTION';
export const SET_LOADING = 'SET_LOADING';
export const SET_ERROR = 'SET_ERROR';
export const SET_TRACK_TOKEN = 'SET_TRACK_TOKEN';
export const SET_TRACK_URL = 'SET_TRACK_URL';
export const SET_CARD_TYPE = 'SET_CARD_TYPE';
export const SET_CUSTOM_FILTER = 'SET_CUSTOM_FILTER';
