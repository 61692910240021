/* eslint-disable no-undef */
import Script from 'next/script';
import React, { useEffect } from 'react';
import { ACTIONS } from './actions';
import * as fbq from './fpixel';
import { useRouter } from 'next/router';

const isProd = process.env.REACT_APP_ENV === 'prod' || process.env.REACT_APP_ENV === 'staging';

export const facebookScript = () => {
    const router = useRouter();
    useEffect(() => fbq.pageview(), [router?.asPath]);
    return (<>
        {/* Global Site Code Pixel - Facebook Pixel */}
        <Script
            id="fb-pixel"
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
            __html: `
                !function(f,b,e,v,n,t,s)
                {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                n.queue=[];t=b.createElement(e);t.async=!0;
                t.src=v;s=b.getElementsByTagName(e)[0];
                s.parentNode.insertBefore(t,s)}(window, document,'script',
                'https://${process.env.REACT_APP_ENV === 'prod' ? 'connect.facebook.net' : process.env.FACEBOOK_PROXY}/en_US/fbevents.js');
                fbq('init', ${process.env.FB_PIXEL_ID});
            `,
            }}
        />
    </>)
}

const FB_PIXEL_EVENTS = [
    ACTIONS.LOGIN,
    ACTIONS.SIGNUP_INITIATED,
    ACTIONS.SIGNUP_COMPLETED,
    ACTIONS.PROMO_CODE_APPLIED,

    // Send Money flow
    ACTIONS.SEND_MONEY_INITIATED,
    ACTIONS.SEND_MONEY_RECIPIENT_DETAILS_CONFIRMED,
    ACTIONS.SEND_MONEY_PERSONA_COMPLETED,
    ACTIONS.SEND_MONEY_TRANSACTION_COMPLETED,

    // Currency Exchange flow
    ACTIONS.CURRENCY_EXCHANGE_INITIATED,
    ACTIONS.CURRENCY_EXCHANGE_PERSONA_COMPLETED,
    ACTIONS.CURRENCY_EXCHANGE_OVERVIEW_CONFIRMED,

    // DTO flow
    ACTIONS.MOBILE_TOP_UP_TRANSACTION_COMPLETED,
    ACTIONS.GIFT_CARD_TRANSACTION_COMPLETED,
    ACTIONS.BILL_PAYMENT_TRANSACTION_COMPLETED,
    ACTIONS.ESIM_PAYMENT_TRANSACTION_COMPLETED,
];

export const trackFBEvent = (action, payload, meta = {}) => {
    if (isProd && window) {
        const eventID = crypto.randomUUID();
        switch (action) {
            case ACTIONS.SIGNUP_COMPLETED:
                const fbTrack = { ...payload?.personalInformation, email: payload?.personalInformation?.cus_email };
                // const fbTrack = { email: payload.personalInformation.cus_email };
                fbq.event(ACTIONS.SIGNUP_COMPLETED, fbTrack, {eventID: eventID});
                break;
            case ACTIONS.LOGIN:
                if (payload) {
                    const fbTrack = { email: payload.cus_email };
                    fbq.event('ACTIONS.LOGIN', fbTrack, {eventID: eventID});
                }
                break;
        }
        if (FB_PIXEL_EVENTS.includes(action)) {
            const _action = meta.accountType === 'business' ? 'BA_' + action : action;
            fbq.event(_action, payload, {eventID: eventID});
        }
    }
};