import React, { useEffect, useState } from 'react'
import Alert from '../alert/Alert';

let openSnackbarFn;

type SnackBarProps = {
  message?: string;
  variant?: string;
}

const SnackBar: React.FC<SnackBarProps> = ({ message, variant: type }) => {

  const [open, setOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState(message);
  const [variant, setVariant] = useState(type);
  const [duration, setDuration] = useState(3000);

  const openSnackbar = ({ message, variant, duration }) => {
    setOpen(true);
    setAlertMessage(message);
    setVariant(variant);
    setDuration(duration);
  };

  const closeSnackbar = () => setOpen(false)

  useEffect(() => {
    openSnackbarFn = openSnackbar;
  }, []);

  useEffect(() => {
    if (open) {
      window.addEventListener('click', closeSnackbar);
    }
    return () => {
      window.removeEventListener('click', closeSnackbar);
    };
  }, [open, closeSnackbar]);

  if (!open) return null;
  return (
    <Alert
      absolutePosition
      type='alert'
      message={alertMessage}
      id={`snackbar-${alertMessage}`}
      variant={variant}
      hideOnDuration={duration}
      showCloseIcon={false}
    />
  )
}

export function openSnackbar({ message, variant, duration }) {
  openSnackbarFn({ message, variant, duration });
}

export const snackbar = {
  error: (message: string, duration: number) => openSnackbarFn && openSnackbarFn({ message, variant: 'negative', duration }),
  success: (message: string, duration: number) => openSnackbarFn && openSnackbarFn({ message, variant: 'success', duration }),
  info: (message: string, duration: number) => openSnackbarFn && openSnackbarFn({ message, variant: 'info', duration }),
  warning: (message: string, duration: number) => openSnackbarFn && openSnackbarFn({ message, variant: 'warning', duration }),
};

export default SnackBar