import { SET_GAME_EXISTS, SET_GAMES, SET_GAME_STEPS, SET_LOADING, SET_ERROR } from './actionTypes';

const initialState = {
  loading: false,
  doGameExists: false,
  games: [],
  gameSteps: [],
  selectedGame: null,
  selectedStep: null,
  answers: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_GAME_EXISTS:
      return {
        ...state,
        loading: false,
        doGameExists: action.payload,
      };

    case SET_GAMES:
      return {
        ...state,
        loading: false,
        games: action.payload,
      };

    case SET_GAME_STEPS:
      return {
        ...state,
        loading: false,
        gameSteps: action.payload,
      };

    case SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };

    case SET_ERROR:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
