import {
  SET_COUNTRY_GIFT,
  SET_RECIPIENT_EMAIL,
  SET_OPERATOR_GIFT,
  SET_PRODUCT_DETAIL_GIFT,
  SET_LIST_OF_PRODUCTS_GIFT,
  SET_REQ_FIELDS_GIFT,
  SET_REQ_FIELD_INFO_GIFT,
  SET_CUSTOM_AMNT_GIFT,
  SET_CUSTOM_MSG_GIFT,
  CLEAR_PRODUCTS_INFO_GIFT,
  CLEAR_OPERATOR_INFO_GIFT,
  CLEAR_ENTIRE_STATE_GIFT,
  SET_DEFAULT_COUNTRY
} from './actionTypes';

const initialState = {
  serviceId: 2,
  countryDetails: null,
  recipientEmail: null,
  operator:null,
  selectedProduct:null,
  products:null,
  requiredFields:[],
  reqFieldInfo:null,
  customAmount:null,
  customMessage:null,
  defaultCountry:null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_COUNTRY_GIFT:
      return { ...state, countryDetails: action.payload };
    case SET_RECIPIENT_EMAIL:
      return { ...state, recipientEmail: action.payload };
    case SET_OPERATOR_GIFT:
      return { ...state, operator: action.payload }; 
    case SET_PRODUCT_DETAIL_GIFT:
      return {...state,selectedProduct:action.payload} 
    case SET_LIST_OF_PRODUCTS_GIFT:
      return {...state,products:action.payload}  
    case SET_REQ_FIELDS_GIFT:
      return {...state,requiredFields:action.payload}    
    case SET_REQ_FIELD_INFO_GIFT:
      return {...state,reqFieldInfo:action.payload}
    case SET_CUSTOM_AMNT_GIFT:
      return {...state,customAmount:action.payload}        
    case SET_CUSTOM_MSG_GIFT:
      return {...state,customMessage:action.payload}
    case SET_DEFAULT_COUNTRY:
      return { ...state,defaultCountry:action.payload }  
    case CLEAR_OPERATOR_INFO_GIFT:
      return {...state,operator:null} 
    case CLEAR_PRODUCTS_INFO_GIFT:
      return {...state,products:null,requiredFields:[],selectedProduct:null,reqFieldInfo:null,customAmount:null,customMessage:null} 
    case CLEAR_ENTIRE_STATE_GIFT:
      return {...initialState}     
    default:
      return state;
  }
};

export default reducer;