import {
  SET_QUESTIONS,
  SET_QUESTION,
  ADD_ANSWER,
  SET_ERROR,
  SET_LOADING,
  SET_QUESTIONNAIRE,
  SET_ANSWERS,
  SET_TOKEN,
} from './actionTypes';

const initialState = {
  loading: false,
  token: null,
  questions: [],
  selectedQuestion: null,
  answers: [],
  customer: null,
  beneficiary: null,
  errorMessage: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };

    case SET_QUESTIONS:
      return {
        ...state,
        questions: action.payload,
        loading: false,
      };

    case SET_ANSWERS:
      return {
        ...state,
        answers: action.payload,
      };

    case SET_QUESTIONNAIRE:
      return {
        ...state,
        customer: action.payload.customer,
        beneficiary: action.payload.beneficiary,
        questions: action.payload.questions,
        loading: false,
      };

    case SET_QUESTION:
      return {
        ...state,
        selectedQuestion: action.payload,
      };

    case ADD_ANSWER:
      return {
        ...state,
        answers: [...state.answers, action.payload],
      };

    case SET_ERROR:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };

    case SET_TOKEN:
      return {
        ...state,
        token: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
