// import { useEffect, useState } from "react";

import moengage_events from './moengage-event';

export const MoEngageCardProperty = () => {
    return {
        enable: true, 
        placeholder: "#cardIcon", // CSS selector of inbox icon
        backgroundColor: "#F6FBFC", // any valid CSS color format
        overLayColor: "rgba(0, 0, 0, 0.8)",
        fontFaces: [{
          family: "Inter",
        }],
        cardDismiss: {
          color: "#db2828", // any valid CSS color format
          enable: false // boolean value, which enable the dismiss option.
        },
        optionButtonColor: "#C4C4C4", // any valid CSS color format
        dateTimeColor: "#8E8E8E", // any valid CSS color format
        unclickedCardIndicatorColor: "blue", // any valid CSS color format
        pinIcon: "https://app-cdn.moengage.com/sdk/pin-icon.svg", // absolute path to the icon image.
        refreshIcon: "https://app-cdn.moengage.com/sdk/refresh-icon.svg", // absolute path to the icon image.
        navigationBar: {
          backgroundColor: "#00237C", // any valid CSS color format
          text: "Notifications", // string. eg, Notifications
          color: "#fff", // any valid CSS color format
          fontSize: "16px", // any valid CSS size format
          fontFamily: "", // any font family which is added to the website
        },
        closeButton: {
          webIcon: "https://app-cdn.moengage.com/sdk/cross-icon.svg",
          mWebIcon: "https://app-cdn.moengage.com/sdk/cross-icon.svg",
        },
        tab: {
          active: {
            color: "#06A6B7",
            underlineColor: "#06A6B7",
            backgroundColor: "transparent"
          },
          inactiveTabFontColor: "#7C7C7C",
          fontSize: "14px", // any valid CSS size format
          fontFamily: "", // any font family which is added to the website
          backgroundColor: "#fff", // any valid CSS color format
        },
        webFloating: {
          enable: false, // false by default
          icon: "https://app-cdn.moengage.com/sdk/bell-icon.svg", // absolute path to the icon image. by default, our icon will be used.
          postion: "0px 10px 40px 0", // need all 4 offset in proper CSS format in the order of top, right, bottom, left.
          countBackgroundColor: "#FF5A5F",
          countColor: "#fff",
          iconBackgroundColor: "#D9DFED",
          fontFamily: "Audiowide"
        },
        mWebFloating: {
          enable: false, // false by default
          icon: "https://app-cdn.moengage.com/sdk/bell-icon.svg", // absolute path to the icon image. by default, our icon will be used.
          postion: "0px 10px 40px 0", // need all 4 offset in proper CSS format in the order of top, right, bottom, left.
          countBackgroundColor: "#FF5A5F",
          countColor: "#fff",
          iconBackgroundColor: "#D9DFED",
          fontFamily: "Audiowide"
        },
        card: {
          headerFontSize: "16px",
          descriptionFontSize: "14px",
          ctaFontSize: "12px",
          fontFamily: "inherit",
          horizontalRowColor: "#D9DFED"
        },
        noDataContent: {
          img: "https://app-cdn.moengage.com/sdk/cards-no-result.svg",
          text: "No notifications to show, check again later.",
        }
      }}

//       export const getMoEngage = async () => {
//         if (typeof window !== 'undefined') {
//             import('@moengage/web-sdk').then((Moe) => {
//                 console.log(Moe.default)
//                 return Moe.default
//             })
//           }
//         }
      

let MoeInstance = null; // Global variable to store MoEngage instance

export const initializeMoEngage = async () => {
  try {
    if (typeof window !== 'undefined' && !MoeInstance) {
      const { default: Moengage } = await import('@moengage/web-sdk');
      MoeInstance = Moengage;
  
      Moengage.initialize({
        app_id: process.env.MOENGAGE_APP_ID, // Ensure using NEXT_PUBLIC_
        debug_logs: process.env.REACT_APP_ENV === 'prod' ? 0 : 1, // For Test -> 1 && Live -> 0
        cluster: 'DC_4',
        cards: MoEngageCardProperty(),
        swPath: '/serviceworker.js',
      });
  
      console.log('✅ MoEngage initialized successfully');
  
      // Register the service worker only after MoEngage is initialized
      if ('serviceWorker' in navigator) {
        const registration = await navigator.serviceWorker.register('/serviceworker.js', { scope: '/'});
        console.log('Service Worker registered with Moengage:', registration);
        Moengage.call_web_push();
      }
  
    }
  } catch (error) {
    console.log(error)
  }

};

export const getMoEngage = async () => {
  if (!MoeInstance) {
    console.warn("⚠️ MoEngage is not initialized yet. Initializing now...");
    await initializeMoEngage(); // Ensure MoEngage is initialized
  }
  return MoeInstance;
};

export const MoEngageTrackEvent = async (eventName, payload) => {
    const MoEngage = await getMoEngage();
    MoEngage.track_event(eventName, payload);
}

export const getCardsEvent = async () => {
     if(MoeInstance && window && window?.Moengage) {
      const CardInstance = window?.Moengage?.cards;
        return CardInstance;
     } else {
      window.reload();
     }
}


export const extractCampaignData = (rawData) => {
  if(!rawData?.length) return;

  return rawData.map(item => {
    const campaignInfo = {
      campaignName: item?.metaData?.moeCampaignName || '',
      campaignType: item?.metaData?.moeCampaignType || '',
      cardInfo: {},
      userActivity: item?.userActivity || {},
      displayControls: item?.displayControls || {}
    };

    // Extract cardInfo (widgets and actions)
    if (item?.templateData?.containers && item?.templateData?.containers?.length > 0) {
      const container = item?.templateData?.containers[0];
      if(window?.Moengage) { // To track impressions
        window?.Moengage?.cards?.cardShown(item?.metaData?.moeCardId)
      }
      campaignInfo.cardInfo.cardID = item?.metaData?.moeCardId
      
      if (container?.widgets) {
        container?.widgets?.forEach(widget => {
          if (widget?.type === 'image') {
            campaignInfo.cardInfo.imageUrl = widget?.content;
          } else if (widget?.type === 'text') {
            if (!campaignInfo?.cardInfo?.header) { // First text will be header
              campaignInfo.cardInfo.header = widget?.content;
            } else {
              campaignInfo.cardInfo.text = widget?.content;
            }
          }
        });
      }
      
      if (container?.actions && container?.actions?.length > 0) {
        const action = container?.actions[0];
        campaignInfo.cardInfo.actionUrl = action?.value || '';
        campaignInfo.cardInfo = { ...campaignInfo.cardInfo, ...action?.kvPairs  }; // kvPairs holds card type & style props
      }
    }

    return campaignInfo;
  });
}