import actionTypes from './actionTypes'

const initState = {
    rc_rewards_amount: null,
}

const companyReducers = (state = initState, action) => {
    switch (action.type) {
        case actionTypes.RC_REWARDS_SUCCESS:
            return { ...state, ...action.payload }
        default: return state
    }
}

export default companyReducers;