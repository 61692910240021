import {
    SET_CE_RECEIVING_AMOUNT,
    SET_CE_RECEIVING_CURRENCY_INFO,
    SET_CE_RECEIVING_CURRENCY_RATE_DETAILS,
    SET_CE_SENDING_AMOUNT,
    SET_CE_SENDING_CURRENCY_INFO,
    SET_CE_SENDING_CURRENCY_RATE_DETAILS,
    SET_CE_COMPLIANCE,
    CLEAR_CE_STATE,
    SET_CE_TRANSFER_PURPOSE,
    SET_CE_CUS_EFT_LIST,
    SET_CE_WALLET_BALANCE,
    SET_CE_TRANSFER_AMOUNT,
    SET_CE_PAYMENT_METHOD,
    SET_CE_PAYMENT_EFT_INFO,
    SET_CE_PAYMENT_CONNECTION_TYPE,
    SET_CE_PAYMENT_BANKS,
    SET_CE_PAYMENT_FLINKS_INFO,
    SET_CE_DEPOSIT_EFT_INFO,
    SET_CE_DEPOSIT_CONNECTION_TYPE,
    SET_CE_DEPOSIT_METHOD,
    SET_CE_DEPOSIT_CUS_EFT_LIST,
    SET_CE_DEPOSIT_FLINKS_INFO,
    CLEAR_CE_DEPOSIT_FLINKS_INFO,
    CLEAR_CE_PAYMENT_FLINKS_INFO,
    SET_CE_RATE_DETAILS,
    SET_CE_NEW_MANUAL_CONNECTION,
    SET_CE_DEPOSIT_DATE,
    SET_CE_ADDITIONAL_INFO
} from "./actionTypes";

const initialState = {
    compliance: null,
    sendingCurrencyAmount: null,
    receivingCurrencyAmount: null,
    sendingCurrency: {
        country_id: 40,
        currency_code: 'CAD',
        iso2: 'CA'
    },
    receivingCurrency: {
        country_id: 236,
        currency_code: 'USD',
        iso2: 'US'
    },
    rateDetails: null,
    selectedPurpose: null,
    cusEftList: [],
    walletBalance: null,
    transferAmount: null,
    paymentMethod: null,
    paymentEFTInfo: null,
    paymentConnectionType: null,
    paymentOnlineBanks: [],
    paymentFlinksInfo: null,
    depositEFTInfo: null,
    depositConnectionType: null,
    depositMethod: null,
    depositCusEftList: [],
    depositFlinksInfo: null,
    is_new_connection: false,
    deposit_date: null,
    additional_info: null
}

const currencyExchangeReducers = (state = initialState, action) => {
    switch (action.type) {
        case SET_CE_RECEIVING_AMOUNT:
            return { ...state, receivingCurrencyAmount: action.payload };
        case SET_CE_RECEIVING_CURRENCY_INFO:
            return { ...state, receivingCurrency: action.payload };
        case SET_CE_RECEIVING_CURRENCY_RATE_DETAILS:
            return { ...state, receivingRateDetails: action.payload };
        case SET_CE_SENDING_AMOUNT:
            return { ...state, sendingCurrencyAmount: action.payload };
        case SET_CE_SENDING_CURRENCY_INFO:
            return { ...state, sendingCurrency: action.payload };
        case SET_CE_SENDING_CURRENCY_RATE_DETAILS:
            return { ...state, sendingRateDetails: action.payload };
        case SET_CE_COMPLIANCE:
            return { ...state, compliance: action.payload };
        case SET_CE_TRANSFER_PURPOSE:
            return { ...state, selectedPurpose: action.payload };
        case SET_CE_CUS_EFT_LIST:
            return { ...state, cusEftList: action.payload };
        case SET_CE_WALLET_BALANCE:
            return { ...state, walletBalance: action.payload };
        case SET_CE_TRANSFER_AMOUNT:
            return { ...state, transferAmount: action.payload };
        case SET_CE_PAYMENT_METHOD:
            return { ...state, paymentMethod: action.payload };
        case SET_CE_PAYMENT_EFT_INFO:
            return { ...state, paymentEFTInfo: action.payload };
        case SET_CE_PAYMENT_CONNECTION_TYPE:
            return { ...state, paymentConnectionType: action.payload };
        case SET_CE_PAYMENT_BANKS:
            return { ...state, paymentOnlineBanks: action.payload };
        case SET_CE_PAYMENT_FLINKS_INFO:
            return { ...state, paymentFlinksInfo: action.payload };
        case SET_CE_DEPOSIT_EFT_INFO:
            return { ...state, depositEFTInfo: action.payload };
        case SET_CE_DEPOSIT_CONNECTION_TYPE:
            return { ...state, depositConnectionType: action.payload };
        case SET_CE_DEPOSIT_METHOD:
            return { ...state, depositMethod: action.payload };
        case SET_CE_DEPOSIT_CUS_EFT_LIST:
            return { ...state, depositCusEftList: action.payload };
        case SET_CE_DEPOSIT_FLINKS_INFO:
            return { ...state, depositFlinksInfo: action.payload };
        case SET_CE_RATE_DETAILS:
            return { ...state, rateDetails: action.payload };
        case SET_CE_NEW_MANUAL_CONNECTION:
            return { ...state, is_new_connection: action.payload };
        case SET_CE_DEPOSIT_DATE:
            return { ...state, deposit_date: action.payload };
        case SET_CE_ADDITIONAL_INFO:
            return { ...state, additional_info: action.payload };
        case CLEAR_CE_DEPOSIT_FLINKS_INFO:
            return { ...state, depositFlinksInfo: null };
        case CLEAR_CE_PAYMENT_FLINKS_INFO:
            return { ...state, paymentFlinksInfo: null };
        case CLEAR_CE_STATE:
            return initialState;
        default:
            return state;
    }
}

export default currencyExchangeReducers;