import {
  SET_COUNTRY_ID,
  SET_COUNTRY_CODE,
  SET_COUNTRY_CALLING_CODE,
  SET_RECIPIENT_PHONE_NUMBER,
  SET_SELECTED_OPERATOR,
  SET_PRODUCTS,
  SET_SELECTED_PRODUCT,
  CLEAR_MOBILE_RC_STATE_DT,
  SET_AVAILABLE_OPERATORS,
  SET_PRODUCT_AMOUNT,
  SET_REQUIRED_FIELDS,
  SET_REQ_FIELD_INFOS,
  SET_ACTIVE_TAB,
  SET_NOT_IN_RANGE_MSG,
  SET_CONVERSION_LOADING,
  SET_DEFAULT_COUNTRY_MOB
} from './actionTypes';

const initialState = {
  serviceId: 1,
  countryId: null,
  countryCode: null,
  countryCallingCode: null,
  recipientPhoneNumber: null,
  selectedOperator: null,
  products: null,
  selectedProduct: null,
  availableOperators: null,
  requiredFields: [],
  reqFieldInfos: null,
  productAmount: null,
  activeTab: 0,
  notInRangeMessage:'',
  conversionLoading:false,
  defaultCountry: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_COUNTRY_ID:
      return { ...state, countryId: action.payload };
    case SET_COUNTRY_CODE:
      return { ...state, countryCode: action.payload };
    case SET_COUNTRY_CALLING_CODE:
      return { ...state, countryCallingCode: action.payload };
    case SET_RECIPIENT_PHONE_NUMBER:
      return { ...state, recipientPhoneNumber: action.payload };
    case SET_SELECTED_OPERATOR:
      return { ...state, selectedOperator: action.payload };
    case SET_PRODUCTS:
      return { ...state, products: action.payload };
    case SET_SELECTED_PRODUCT:
      return { ...state, selectedProduct: action.payload };
    case SET_AVAILABLE_OPERATORS:
      return { ...state, availableOperators: action.payload };
    case SET_PRODUCT_AMOUNT:
        return { ...state, productAmount: action.payload };
    case SET_REQUIRED_FIELDS:
      return { ...state, requiredFields: action.payload };
    case SET_REQ_FIELD_INFOS:
        return { ...state, reqFieldInfos: action.payload };
    case SET_ACTIVE_TAB:
        return { ...state, activeTab: action.payload };
    case SET_NOT_IN_RANGE_MSG:
        return { ...state, notInRangeMessage: action.payload }  
    case SET_CONVERSION_LOADING:
        return { ...state, conversionLoading: action.payload } 
    case SET_DEFAULT_COUNTRY_MOB: 
        return { ...state, defaultCountry: action.payload }         
    case CLEAR_MOBILE_RC_STATE_DT:
      return initialState  
    default:
      return state;
  }
};

export default reducer;