import {
    COMPLIANCE,
    ADD_NEW_RECIPIENT,
    ADD_NEW_RECIPIENT_FORM,
    FIRST_TRANSFER,
    SET_MT_RECIPIENT,
    SET_MT_RECEIVING_METHOD,
    SET_MT_IS_INSTANT_SPEED,
    SET_MT_SENDING_AMOUNT,
    SET_MT_RECEIVING_AMOUNT,
    CLEAR_MT_STATE,
    SET_MT_RATE_DETAILS,
    SET_MT_PURPOSE_SELECTED,
    SET_MT_AVAIL_PAY_METHODS,
    SET_MT_SELECTED_PAY_METHOD,
    SET_MT_WALLET_BAL,
    SET_MT_TRANS_STATE,
    SET_MT_ONLINE_BANKS,
    SET_MT_PREFERRED_BANK,
    SET_MT_CUS_CARDS,
    SET_MT_SELECTED_CARD,
    SET_MT_CUS_EFT_LIST,
    SET_MT_CUS_SELECTED_EFT,
    SET_MT_FLINKS_DETAIL,
    SET_MT_CLEAR_FLINKS_STATE,
    SET_MT_TRANS_UNIQ_ID,
    SET_MT_CHALLENGE_PARAMS,
    SET_TABAPAY_RESPONSE,
    SET_MT_TRANS_INFO,
    SET_MT_REDIRECT_ROUTE,
    SET_MT_SPEED_OPTIONS,
    SET_MT_SPEED_DECIDED,
    SET_MT_INSTANT_RATE,
    SET_MT_VERIFICATION_STATE,
    SET_PERSONAL_INFO_FORM,
    CLEAR_MT_BEN_STATE,
    SET_MT_DEBIT_API_ERROR,
    SET_OPEN_REQUEST_DIALOG,
    SET_MT_STEPS_AVAIL,
    SET_FIRST_RECIPIENT,
    SET_EDIT_CARD_INFO,
} from './actionTypes';

const flinksState = {
    loginId: null,
    institution: null,
    url: null,
    flinksIntegrationStatus: null,
    requestId: null,
    bank: null
}

const initialState = {
    compliance: null,
    addNewRecipient: false,
    addNewRecipientForm: {},
    firstTransfer: false,
    recipient: null,
    receivingMethod: null,
    sendingAmountState: null,
    receivingAmountState: null,
    is_instant_speed: false,
    instant_rate: null,
    rateDetails: null,
    available_pay_methods: [],
    selected_pay_method: null,
    avail_wallet_balance: null,
    finalTransferFee: null,
    trans_status: null,
    online_banks: [],
    preferredBank: null,
    cusCards: [],
    selectedCard: null,
    eftList: [],
    selectedEft: null,
    flinks: flinksState,
    transaction_unique_id: null,
    challenge_params_state: {},
    transaction_info: {},
    redirect_route: null,
    speed_options: [],
    selected_speed: null,
    verification_state: [],
    personalInfoForm: null,
    debit_api_error: null,
    request_dialog: false,
    stepsAvailable: [],
    firstRecipient: false,
    editCardInfo: null
};

const moneyTransferReducers = (state = initialState, action) => {
    switch (action.type) {
        case COMPLIANCE:
            return { ...state, compliance: action.payload };
        case ADD_NEW_RECIPIENT:
            return { ...state, addNewRecipient: action.payload };
        case ADD_NEW_RECIPIENT_FORM:
            return { ...state, addNewRecipientForm: action.payload };
        case FIRST_TRANSFER:
            return { ...state, firstTransfer: action.payload };
        case SET_MT_RECIPIENT:
            return { ...state, recipient: action.payload };
        case SET_MT_RECEIVING_METHOD:
            return { ...state, receivingMethod: action.payload };
        case SET_MT_SENDING_AMOUNT:
            return { ...state, sendingAmountState: action.payload }
        case SET_MT_RECEIVING_AMOUNT:
            return { ...state, receivingAmountState: action.payload }
        case SET_MT_IS_INSTANT_SPEED:
            return { ...state, is_instant_speed: action.payload }
        case SET_MT_RATE_DETAILS:
            return { ...state, rateDetails: action.payload }
        case SET_MT_PURPOSE_SELECTED:
            return { ...state, transferPurpose: action.payload }
        case SET_MT_AVAIL_PAY_METHODS:
            return { ...state, available_pay_methods: action.payload }
        case SET_MT_SELECTED_PAY_METHOD:
            return { ...state, selected_pay_method: action.payload };
        case SET_MT_WALLET_BAL:
            return { ...state, avail_wallet_balance: action.payload }
        case SET_MT_TRANS_STATE:
            return { ...state, trans_status: action.payload }
        case SET_MT_ONLINE_BANKS:
            return { ...state, online_banks: action.payload }
        case SET_MT_PREFERRED_BANK:
            return { ...state, preferredBank: action.payload }
        case SET_MT_CUS_CARDS:
            return { ...state, cusCards: action.payload }
        case SET_MT_SELECTED_CARD:
            return { ...state, selectedCard: action.payload };
        case SET_MT_CUS_EFT_LIST:
            return { ...state, eftList: action.payload };
        case SET_MT_CUS_SELECTED_EFT:
            return { ...state, selectedEft: action.payload };
        case SET_MT_FLINKS_DETAIL:
            return { ...state, flinks: action.payload };
        case SET_MT_TRANS_UNIQ_ID:
            return { ...state, transaction_unique_id: action.payload }
        case SET_MT_CLEAR_FLINKS_STATE:
            return { ...state, flinks: flinksState }
        case SET_MT_CHALLENGE_PARAMS:
            return { ...state, challenge_params_state: action.payload }
        case SET_TABAPAY_RESPONSE:
            return { ...state, tabapay_response: action.payload }
        case SET_MT_TRANS_INFO:
            return { ...state, transaction_info: action.payload };
        case SET_MT_REDIRECT_ROUTE:
            return { ...state, redirect_route: action.payload };
        case SET_MT_SPEED_OPTIONS:
            return { ...state, speed_options: action.payload };
        case SET_MT_SPEED_DECIDED:
            return { ...state, selected_speed: action.payload };
        case SET_MT_VERIFICATION_STATE:
            return { ...state, verification_state: action.payload };
        case SET_MT_INSTANT_RATE:
            return { ...state, instant_rate: action.payload };
        case SET_PERSONAL_INFO_FORM:
            return { ...state, personalInfoForm: action.payload };
        case SET_MT_STEPS_AVAIL:
            return { ...state, stepsAvailable: action.payload }    
        case CLEAR_MT_STATE:
            return initialState;
        case CLEAR_MT_BEN_STATE:
            return { ...state, recipient: null, receivingMethod: null }    
        case SET_MT_DEBIT_API_ERROR:
            return { ...state, debit_api_error: action.payload }
        case SET_OPEN_REQUEST_DIALOG:
            return { ...state, request_dialog: action.payload }
        case SET_FIRST_RECIPIENT:
            return { ...state, firstRecipient: action.payload }
        case SET_EDIT_CARD_INFO:
            return { ...state, editCardInfo: action.payload }    
        default:
            return state;
    }
}

export default moneyTransferReducers;