export const COMPLIANCE = 'COMPLIANCE';
// Indicates user is adding a beneficiary (first transfer or not)
export const ADD_NEW_RECIPIENT = 'ADD_NEW_RECIPIENT';
export const ADD_NEW_RECIPIENT_FORM = 'ADD_NEW_RECIPIENT_FORM';
// Indicates the user has no beneficiaries and transaction should start with add beneficiary
export const FIRST_TRANSFER = 'FIRST_TRANSFER';
export const SET_MT_RECIPIENT = 'SET_MT_RECIPIENT';
export const SET_MT_RECEIVING_METHOD = 'SET_MT_RECEIVING_METHOD';
export const CLEAR_MT_STATE = 'CLEAR_MT_STATE';
export const SET_MT_IS_INSTANT_SPEED = 'SET_MT_IS_INSTANT_SPEED';
export const SET_MT_SENDING_AMOUNT = 'SET_MT_SENDING_AMOUNT';
export const SET_MT_RECEIVING_AMOUNT = 'SET_MT_RECEIVING_AMOUNT';
export const SET_MT_RATE_DETAILS = 'SET_MT_RATE_DETAILS';
export const SET_MT_PURPOSE_SELECTED = 'SET_MT_PURPOSE_SELECTED'
export const SET_MT_AVAIL_PAY_METHODS = 'SET_MT_AVAIL_PAY_METHODS'
export const SET_MT_SELECTED_PAY_METHOD = 'SET_MT_SELECTED_PAY_METHOD';
export const SET_MT_WALLET_BAL = 'SET_MT_WALLET_BAL';
export const SET_MT_TRANS_STATE = 'SET_MT_TRANS_STATE';
export const SET_MT_ONLINE_BANKS = 'SET_MT_ONLINE_BANKS';
export const SET_MT_PREFERRED_BANK = 'SET_MT_PREFERRED_BANK';
export const SET_MT_CUS_CARDS = 'SET_MT_CUS_CARDS';
export const SET_MT_SELECTED_CARD = 'SET_MT_SELECTED_CARD';
export const SET_MT_CUS_EFT_LIST = 'SET_MT_CUS_EFT_LIST'
export const SET_MT_CUS_SELECTED_EFT = 'SET_MT_CUS_SELECTED_EFT';
export const SET_MT_FLINKS_DETAIL = 'SET_MT_FLINKS_DETAIL'
export const SET_MT_CLEAR_FLINKS_STATE = 'SET_MT_CLEAR_FLINKS_STATE';
export const SET_MT_TRANS_UNIQ_ID = 'SET_MT_TRANS_UNIQ_ID';
export const SET_MT_CHALLENGE_PARAMS = 'SET_MT_CHALLENGE_PARAMS';
export const SET_TABAPAY_RESPONSE = 'SET_TABAPAY_RESPONSE';
export const SET_MT_TRANS_INFO = 'SET_MT_TRANS_INFO';
export const SET_MT_REDIRECT_ROUTE = 'SET_MT_REDIRECT_ROUTE'
export const SET_MT_SPEED_OPTIONS = "SET_MT_SPEED_OPTIONS"
export const SET_MT_SPEED_DECIDED = "SET_MT_SPEED_DECIDED"
export const SET_MT_INSTANT_RATE = "SET_MT_INSTANT_RATE"
export const SET_MT_VERIFICATION_STATE = "SET_MT_VERIFICATION_STATE"
export const SET_PERSONAL_INFO_FORM = "SET_PERSONAL_INFO_FORM";
export const CLEAR_MT_BEN_STATE = 'CLEAR_MT_BEN_STATE'
export const SET_MT_DEBIT_API_ERROR = 'SET_MT_DEBIT_API_ERROR'
export const SET_MT_STEPS_AVAIL = 'SET_MT_STEPS_AVAIL'
export const SET_OPEN_REQUEST_DIALOG = 'SET_OPEN_REQUEST_DIALOG'
export const SET_FIRST_RECIPIENT = 'SET_FIRST_RECIPIENT'
export const SET_EDIT_CARD_INFO = 'SET_EDIT_CARD_INFO'