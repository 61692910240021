import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#1160F7',
      dark: '#37517A',
      light: '#6B708F',
    },
    secondary: {
      main: '#2EBC31',
    },
    warning: {
      main: '#FAC948',
    },
    error: {
      main: '#FF4B55',
    },
    success: {
      main: '#2EBC31',
    },
    background: {
      default: '#ffffff'
    },
    grey: {
      500: '#CACCD5',
      main: '#CACCD5',
    },
  },
  fontFamily: '',
  typography: {
    fontFamily: 'Inter',
    fontSize: 16,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    button: {
      textTransform: 'none',
      fontSize: '16px',
    },
    body: {
      fontSize: '16px',
      color: '#626680',
      lineHeight: '1.7'
    },
    h1: {
      fontSize: '25px',
      color: '#37517A',
      fontWeight: 'medium',
    },
    h2: {
      fontSize: '18px',
      color: '#37517A',
    },
    h3: {
      fontSize: '16px',
      color: '#37517A',
      fontWeight: '400',
    },
    h4: {
      fontSize: '14px',
      color: '#37517A',
      fontWeight: '400',
    },
    subtitle1: {
      fontSize: '12px',
      color: '#6B708F',
      fontWeight: '400',
    },
    subtitle2: {
      fontSize: '14px',
      color: '#6B708F',
      fontWeight: '400',
    },
    menuItem: {
      fontSize: '14px',
      color: '#37517A',
      fontWeight: '500',
    },
    dialogHeading: {
      fontSize: '20px',
      color: '#37517A',
      fontWeight: 'medium',
    },
    radioButtonBoxValue: {
      fontSize: '16px',
      color: '#37517A',
      fontWeight: 'regular',
    },
    radioButtonBoxPrice: {
      fontSize: '16px',
      color: '#6B708F',
    },
    radioButtonBoxFree: {
      fontSize: '16px',
      color: '#2EBC31',
    },
    formFieldLabel: {
      fontSize: '12px',
      color: '#6B708F',
    },
    formFieldPlaceholder: {
      fontSize: '16px',
      color: '#CACCD5',
    },
    formFieldInput: {
      fontSize: '16px',
      color: '#37517A',
    },
    dataColumnHeading: {
      fontSize: '16px',
      color: '#37517A',
      fontWeight: 'regular',
    },
    dataLight: {
      '@media (min-width: 801px)': {
        fontSize: '16px',
      },
      '@media (max-width: 800px)': {
        fontSize: '14px',
      },
      color: '#6B708F',
    },
    dataDark: {
      '@media (min-width: 801px)': {
        fontSize: '16px',
      },
      '@media (max-width: 800px)': {
        fontSize: '14px'
      },
      color: '#37517A',
    },
    notificationsBody: {
      fontSize: '14px',
      color: '#6B708F',
      fontWeight: 'light',
    },
    notificationsButton: {
      fontSize: '14px',
      fontWeight: 'regular',
    },
  },
  overrides: {
    MuiButton: {
      root: {
        fontWeight: "regular",
        fontSize: '14px'
      },
      contained: {
        boxShadow: 'none',
      },
      outlinedPrimary: {
        border: '1px solid #1160F7',
        borderRadius: '3px',
        backgound: 'transparent',
        color: '#1160F7',
        fontSize: '16px',
        fontWeight: 'regular',
      },
      outlinedSecondary: {
        border: '1px solid #CACCD5',
        borderRadius: '3px',
        backgound: 'transparent',
        color: '#6B708F',
        fontSize: '16px',
        fontWeight: 'regular',
        '&:hover': {
          border: '1px solid #CACCD5',
        },
      },
      containedSecondary: {
        borderRadius: '3px',
        backgoundColor: '#2EBC31',
        color: '#fff',
        fontSize: '16px',
        fontWeight: 'regular',
      },

    },
    MuiPaper: {
      rounded: {
        borderRadius: '10px',
      },
      elevation4: {
        boxShadow: '0px 2px 4px -6px rgba(0,0,0,0.2),0px 4px 5px -10px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)',
      },
    },
    MuiSkeleton: {
      root: {
        backgroundColor: 'rgba(0, 0, 0, 0.1)',
      },
    },
    MuiInputBase: {
      root: {
        height: "44px",
        "&.Mui-disabled": {
          backgroundColor: "#F8F8F8",
        },
      },
      input: {
        fontSize: '16px',
        color: '#37517A',
        "&.Mui-disabled": {
          color: "#CACCD5",
        }
      },
      multiline: {
        height: "inherit"
      }

    },
    MuiInputLabel: {
      root: {
        fontSize: '16px',
        color: "#6B708F",
        top: "-4px !important"
      },
      shrink: {
        fontSize: "14px",
        color: "#6B708F",
        top: "0px !important"
      }
    },
    MuiMenu: {
      paper: {
        border: '1px solid #CACCD5',
        boxShadow: '0px 4px 12px 0px #0000001A',
        borderRadius: 5,
        overflowY: 'auto'
      },
    },
    MuiListItem: {
      root: {
        backgroundColor: '#fff !important',
      }
    },
    MuiSelect: {
      selectMenu: {
        whiteSpace: 'pre-wrap',
      }
    },
    MuiMenuItem: {
      root: {
        fontSize: '16px',
        lineHeight: '24px',
        color: '#31517A',
        paddingLeft: '16px',
        paddingRight: '16px',
        paddingTop: '8px',
        paddingBottom: '8px',
        whiteSpace: 'unset',
        wordBreak: 'break-word',
        width: '100%',
        "&:hover": {
          background: '#F1F6FF'
        },
        "&$selected": {
          "&:hover": {
            backgroundColor: '#F1F6FF !important'
          }
        }
      }
    },
    MuiCssBaseline: {
      '@global': {
        p: {
          margin: 0,
        },
        h1: {
          margin: 0,
        },
        h2: {
          margin: 0,
        },
        h3: {
          margin: 0,
        },
        section: {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        },
        iframe: {
          '@media (max-width: 500px)': {
            width: '100%'
          }
        },
        '.loginTitle': {
            color: '#FFFFFF',
            fontWeight: 700,
            fontSize: '40px',
            lineHeight: '60px',
            position: 'absolute',
            top: '50%',
            left: '40%',
            transform: 'translate(-40%, -50%)',
            fontFamily: 'Inter'
        },
        '.loginText': {
          fontSize: '18px',
          fontWeight: 400,
          lineHeight: '27px',
          paddingTop: '16px'
        },
        '.textSubtitle': {
          paddingBottom: '16px',
          fontFamily: 'Inter',
          fontWeight: 500,
          color: '#31517A',
          fontSize: '22px',
          lineHeight: '32px',
          '@media (max-width: 500px)': {
            fontSize: '18px',
            lineHeight: '26px'
          }
        },
        '.countryName': {
          '@media (min-width: 801px)': {
            lineHeight: '120px',
            fontSize: '100px',
          },
          '@media (max-width: 800px)': {
            fontSize: '48px',
            lineHeight: '70px'
          }
        },
        '.countryNameSmall': {
          '@media (min-width: 801px)': {
            lineHeight: '72px',
            fontSize: '48px',
          },
          '@media (max-width: 800px)': {
            fontSize: '32px',
            lineHeight: '48px'
          }
        },
        '.greenHightlight': {
          backgroundColor:  'rgba(30, 199, 126, 0.2)'
        },
        '.heroTitle': {
          '@media (min-width: 801px)': {
            lineHeight: '90px',
            fontSize: '64px',
          },
          '@media (max-width: 800px)': {
            fontSize: '32px',
            lineHeight: '48px'
          }
        },
        '.heroTitleBig': {
          '@media (min-width: 801px)': {
            fontSize: '56px',
            lineHeight: '90px'
          },
          '@media (max-width: 800px)': {
            fontSize: '32px',
            lineHeight: '48px'
          }
        },
        '.heroTitleDense': {
          '@media (min-width: 801px)': {
            fontSize: '48px',
            lineHeight: '72px'
          },
          '@media (max-width: 800px)': {
            fontSize: '32px',
            lineHeight: '48px'
          }
        },
        '.listItem': {
          fontSize: '16px',
          lineHeight: '24px',
          color: '#31517A'
        },
        '.checkIcon': {
          width: '24px',
          height: '24px',
          fontSize: '16px'
        },
        '.emptyStatusTitle': {
          color: '#37517A',
          fontWeight: 500,
          fontSize: '26px',
          lineHeight: '34px',
    
        },
        '.flowTitle': {
          color: '#37517A',
          fontWeight: 500,
          '@media (min-width: 801px)': {
            fontSize: '24px',
            lineHeight: '32px',
          },
          '@media (max-width: 800px)': {
            fontSize: '22px',
            lineHeight: '28px',
          }
        },
        '.flowLabel': {
          color: '#626680',
          fontSize: '14px',
          lineHeight: '20px',
          marginBottom: '5px'
        },
        '.flowButton': {
          height: '48px',
        },
        '.flowBox': {
          marginTop: '32px',
          marginBottom: '48px'
        },
        '.text': {
          color: '#626680',
          fontSize: '16px',
          lineHeight: '27px'
        },
        '.link': {
          "& a, a:visited": {
            textDecoration: 'none',
            color: "#1160F7",
          }
        },
        '.default-small-text-style': {
          fontSize: '16px',
          lineHeight: '24px',
          color: '#626680'
        },
        '.default-medium-text-style': {
          color: '#31517A',
          fontSize: '20px !important',
          fontWeight: '500 !important',
          lineHeight: '31.2px !important'
        },
        '.default-text-style': {
          color: '#31517A',
          fontSize: '24px !important',
          fontWeight: '500 !important',
          lineHeight: '31.2px !important'
        },
        '.compliance-field-btn': {
          width: '300px'
        },
        '.divider-style': {
          marginTop: '16px',
          marginBottom: '32px'
        },
        '.text-field-label': {
          font: '14px',
          color: '#37517A'
        },    
        '.message': {
          textAlign: 'center',
          paddingBottom: '5px',
          paddingTop: '5px',
          marginBottom: 0,
          marginTop: 0,
          font: '2.0em',
          color: '#37517A',
          fontWeight: 500
        },
        '.subMessage': {
          color: '#6B708F',
          fontWeight: 'normal',
          fontSize: '1.0em'
        },
        '.drawer': {
          width: '50%',
          background: `url('/signup_banner.png') center bottom no-repeat`,
          backgroundSize: 'cover'
        },
        '.drawerContent': {
          background: 'linear-gradient(180deg, rgba(17, 96, 247, 0.85098) 0%, rgba(0, 47, 137, 0.768627) 100%)',
          width: '100%',
          height: '100%'
        },
        '.dockedLeft': {
          borderRight: 0,
          width: '50%'
        },
        '.herocontainer': {
          width: '100%',
          position: 'relative',
          display: 'inline-block'
        },
        '.herocontainer:before': {
          position: 'absolute',
          top: 0,
          left: 0,
          height: '100%',
          width: '100%'
        },
        '.verticalcenter': {
          margin: 0,
          position: 'absolute',
          top: '50%',
          '-ms-transform': 'translateY(-50%)',
          transform: 'translateY(-50%)',
        },
        '.verticalcenterrelative': {
          margin: 0,
          position: 'relative',
          top: '50%',
          '-ms-transform': 'translateY(-50%)',
          transform: 'translateY(-50%)'
        },
        '.fieldLabel': {
          fontSize: '14px',
          lineHeight: '20px',
          fontWeight: 400,
          color: '#6B708F'
        },
        '.field__label-required': {
          "&::after": {
            content: '"*"'
          },
          color: '#fe0000',
          marginLeft: '2px',
          fontSize: '13px'
        },
        '.custom-divider': {
          height: '1.5px',
          backgroundColor: '#E9E9E9',
          marginTop: '1.25em',
          marginBottom: '0.875em'
        },
        '.MuiInputBase-input.Mui-disabled': {
          color: '#797979 !important'
        },
        '.flinksconnect': {
          '@media (max-width: 768px)': {
            width: '100%'
          }
        },
        '.grecaptcha-badge': {
          display: 'none'
        }
      },
    },
    MuiContainer: {
      root: {
        marginLeft: '0',
      },
    },
    MuiTableRow: {
      root: {
        borderStyle: 'hidden',
      },
    },
    MuiAutocomplete: {
      input: {
        padding: "2px 3px 0px 3px !important"
      }
    },
    MuiTab: {
      textColorPrimary: {
        color: "#37517A",
        fontSize: "14px",
        fontWeight: "400",
      }
    }
  }
});

export default theme;
