import {
    SET_PAYMENT_TYPE,
    SET_SETTLEMENT_TYPE,
    CLEAR_PAYMENT_STATE,
} from './actionTypes';

const initialState = {
    payment_type: '',
    settlement_time: '',
};
const paymentStatusReducers = (state = initialState, action) => {
    switch (action.type) {
        case SET_PAYMENT_TYPE:
            return { ...state, payment_type: action.payload };
        case SET_SETTLEMENT_TYPE:
            return { ...state, settlement_time: action.payload };
        case CLEAR_PAYMENT_STATE:
            return { ...state, payment_type: '', settlement_time: '' };
        default:
            return state;
    }
}

export default paymentStatusReducers;
            