import { bool, number, string } from 'prop-types';
import React from 'react';

const ErrorAlertIcon = ({
  width,
  height,
  padding,
  opacity,
}) => {

  return (
    <svg xmlns="https://www.w3.org/2000/svg" width={width} height={height} style={{ margin: '0 10px 0 0' }} padding={padding} opacity={opacity} viewBox="0 0 22.095 22.095">
      <g id="Group_3280" data-name="Group 3280" transform="translate(-275 -372)">
        <path id="Path_1346" data-name="Path 1346" d="M11.047,0A11.047,11.047,0,1,1,0,11.047,11.047,11.047,0,0,1,11.047,0Z" transform="translate(275 372)" fill="rgba(255,75,85,0.42)" />
        <g id="warning" transform="translate(278.529 375.529)">
          <g id="Group_527" data-name="Group 527" transform="translate(0 0)">
            <path id="Path_1323" data-name="Path 1323" d="M7.518,0a7.518,7.518,0,1,0,7.518,7.518A7.526,7.526,0,0,0,7.518,0Zm0,0" fill="#ff4b55" />
            <path id="Path_1324" data-name="Path 1324" d="M235.921,363.294a.626.626,0,1,1-.626-.626A.626.626,0,0,1,235.921,363.294Zm0,0" transform="translate(-227.776 -352.017)" fill="#eceff1" />
            <path id="Path_1325" data-name="Path 1325" d="M235.921,112.307a.626.626,0,0,1-1.253,0v-5.012a.626.626,0,0,1,1.253,0Zm0,0" transform="translate(-227.776 -103.535)" fill="#fafafa" />
          </g>
        </g>
      </g>
    </svg>
  )
}

ErrorAlertIcon.propTypes = {
  width: number,
  height: number,
  warning: bool,
  padding: string,
  opacity: string,
};

ErrorAlertIcon.defaultProps = {
  width: 22,
  height: 22,
  padding: '0',
  opacity: '1',
};

export default ErrorAlertIcon;