import { bool, number, string } from 'prop-types';
import React from 'react';

const InfoAlertIcon = ({
  width,
  height,
  padding,
  opacity,
}) => {

  return (
    <svg xmlns="https://www.w3.org/2000/svg" width={width} height={height} style={{ margin: '0 10px 0 0' }} padding={padding} opacity={opacity} viewBox="0 0 22 22">
      <g id="Group_3889" data-name="Group 3889" transform="translate(-8629 6655)">
        <g id="Group_3887" data-name="Group 3887" transform="translate(8629 -6655)">
          <circle id="Ellipse_390" data-name="Ellipse 390" cx="11" cy="11" r="11" fill="#cfd1d7" />
          <g id="warning" transform="translate(3.206 3.206)">
            <g id="Group_527" data-name="Group 527" transform="translate(0 0)">
              <path id="Path_1323" data-name="Path 1323" d="M7.794,0a7.794,7.794,0,1,0,7.794,7.794A7.8,7.8,0,0,0,7.794,0Zm0,0" fill="#a2a4b6" />
              <path id="Path_1324" data-name="Path 1324" d="M235.967,363.317a.649.649,0,1,1-.649-.649A.649.649,0,0,1,235.967,363.317Zm0,0" transform="translate(-227.523 -351.626)" fill="#a2a4b6" />
              <path id="Path_1325" data-name="Path 1325" d="M235.967,112.514a.649.649,0,0,1-1.3,0v-5.2a.649.649,0,0,1,1.3,0Zm0,0" transform="translate(-227.523 -103.42)" fill="#a2a4b6" />
            </g>
          </g>
        </g>
        <g id="Group_3888" data-name="Group 3888" transform="translate(0 -0.453)">
          <path id="Path_2876" data-name="Path 2876" d="M235.967,363.317a.649.649,0,1,1-.649-.649A.649.649,0,0,1,235.967,363.317Zm0,0" transform="translate(8874.967 -6284.58) rotate(180)" fill="#eceff1" />
          <path id="Path_2877" data-name="Path 2877" d="M235.967,112.514a.649.649,0,0,1-1.3,0v-5.2a.649.649,0,0,1,1.3,0Zm0,0" transform="translate(8874.967 -6532.786) rotate(180)" fill="#fafafa" />
        </g>
      </g>
    </svg>
  )
}

InfoAlertIcon.propTypes = {
  width: number,
  height: number,
  warning: bool,
  padding: string,
  opacity: string,
};

InfoAlertIcon.defaultProps = {
  width: 22,
  height: 22,
  padding: '0',
  opacity: '1',
};

export default InfoAlertIcon;